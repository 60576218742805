<template>
    <div class="d-flex">
        <div class="pr-3 flex-1">
            <div v-if="trajet.trajet?.kilometreReelCharge" class="mb-2">
                <h3 class="mt-1 text-theme" style="font-weight: 600; font-size: 12px; line-height:1;">
                    <i class="fa-solid fa-road mr-1"></i> Trajet
                </h3>
                <table class="table table-compact m-0">
                    <tbody>
                    <tr>
                        <th>Distance réelle</th>
                        <td class="text-right">{{ trajet.trajet?.kilometreReelCharge }} km</td>
                        <td class="width-1px">
                            <button v-if="''+trajet.trajet?.kilometreReelCharge === ''+trajet.kilometre" class="btn btn-xs btn-success" title="Reporter" disabled>
                                <i class="fa-solid fa-check"></i>
                            </button>
                            <button v-else @click="$emit('changes', {kilometre: trajet.trajet?.kilometreReelCharge})" class="btn btn-xs btn-secondary" title="Reporter"><i class="fa-regular fa-clone"></i></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex">
                <div :class="{'pr-3': hasViaMichelinBtn}" class="flex-1 d-flex align-items-center justify-content-center" v-if="loading"><div class="spinner-border" role="status"></div></div>
                <div class="d-flex align-items-center text-center" v-else-if="error">{{ error }}</div>
                <template v-else>
                    <div class="flex-1" v-for="(distance, libelle) in data">
                        <h3 class="mt-1 text-theme" style="font-weight: 600; font-size: 12px; line-height:1;">
                            <i class="fa-solid mr-1" :class="'Distancier' === libelle ? 'fa-ruler' : 'fa-map'"></i> {{ libelle }}
                        </h3>
                        <table class="table table-compact m-0">
                            <tbody>
                            <tr>
                                <th>Distance</th>
                                <td class="text-right">{{ distance.kilometre }} km</td>
                                <td class="width-1px">
                                    <button v-if="''+distance.kilometre === ''+trajet.kilometre" class="btn btn-xs btn-success" title="Reporter" disabled>
                                        <i class="fa-solid fa-check"></i>
                                    </button>
                                    <button v-else @click="$emit('changes', {kilometre: distance.kilometre, kilometreCalcule: distance.kilometre})" class="btn btn-xs btn-secondary" title="Reporter"><i class="fa-regular fa-clone"></i></button>
                                </td>
                            </tr>
                            <tr>
                                <th>Péage</th>
                                <td class="text-right">
                                <span v-if="null !== distance.peageQuantite || null !== distance.peageMontant">
                                    {{ distance.peageQuantite }}
                                    <i v-if="null !== distance.peageQuantite && null !== distance.peageMontant" class="fa-solid fa-right-long"></i>
                                    {{ Euro(distance.peageMontant) }}
                                </span>
                                    <span v-else class="text-muted">-</span>
                                </td>
                                <td class="width-1px">
                                    <button v-if="distance.peageQuantite === trajet.supplementPeage.quantite && Euro(distance.peageMontant, false) === trajet.supplementPeage.montant" class="btn btn-xs btn-success" title="Reporter" disabled>
                                        <i class="fa-solid fa-check"></i>
                                    </button>
                                    <button v-else @click="$emit('changes', {supplementPeage: {quantite: distance.peageQuantite, montant: Euro(distance.peageMontant, false)}})" class="btn btn-xs btn-secondary" title="Reporter"><i class="fa-regular fa-clone"></i></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </div>
        </div>
        <div v-if="hasViaMichelinBtn" class="pl-3 align-items-center text-center border-left d-flex">
            <a href="#" @click="openViaMichelin">
                <i class="fa-solid fa-up-right-from-square fa-2x"></i><br>Via Michelin
            </a>
        </div>
    </div>
</template>

<script setup>
import Euro from "@/Vue/Filters/Euro";
import {computed, onBeforeMount, ref, toRefs} from "vue";
import Request from "@/App/Request";
import Router from "@/App/Router";

const emit = defineEmits(['close']);

const props = defineProps({
    trajet: Object,
});

const {trajet} = toRefs(props);

const loading = ref(false);
const distancierData = ref(null);
const ptvData = ref(null);
const viaMichelinData = ref(null);
const error = ref(null);

const data = computed(() => {
    let data = {};

    if(distancierData.value) {
        data['Distancier'] = distancierData.value;
    }

    if(viaMichelinData.value) {
        data['ViaMichelin'] = viaMichelinData.value;
    }

    if(ptvData.value) {
        data['PTV'] = ptvData.value;
    }

    return data;
});

const hasViaMichelinBtn = computed(() => !loading.value);

const openViaMichelin = () => {
    const mapping = {
        'Depart': 'PriseEnCharge',
        'Arrivee': 'Destination',
    };

    const params = {};

    for(let type in mapping) {
        const field = mapping[type];

        let adresse = (trajet.value['adresse'+field] ?? '').replace('\n', ' ').replace('\r','').trim();
        let codePostal = (trajet.value['codePostal'+field] ?? '').trim();
        let ville = (trajet.value['ville'+field] ?? '').trim();

        if (adresse && (codePostal || ville)) {
            adresse += ', ';
        }

        params['libelle'+type] = adresse+(codePostal+' '+ville).trim();
        params['latitude'+type] = trajet.value['latitudeDegre'+field] ?? '';
        params['longitude'+type] = trajet.value['longitudeDegre'+field] ?? '';
    }

    const url = Router.generate('viamichelin.itineraire', params);
    const win = window.open(url, '_blank', 'noreferrer');
    if(win) win.focus();

    emit('close');
}

onBeforeMount(() => {
    if(trajet.value.codeInseePriseEnCharge === null || trajet.value.codeInseePriseEnCharge === '' || trajet.value.codeInseeDestination === null || trajet.value.codeInseeDestination === '') {
        error.value = 'Sélectionnez un lieu de prise en charge et de destination pour faire appel au distancier';
        return;
    }

    loading.value = true;

    Request.postJson(Router.generate('distancier.ajax'), {
        geocodage_distancier: {
            full: true,
            structure: App.Shared.getStructure(),
            departInsee: trajet.value.codeInseePriseEnCharge,
            departLatitude: trajet.value.latitudeDegrePriseEnCharge,
            departLongitude: trajet.value.longitudeDegrePriseEnCharge,
            arriveeInsee: trajet.value.codeInseeDestination,
            arriveeLatitude: trajet.value.latitudeDegreDestination,
            arriveeLongitude: trajet.value.longitudeDegreDestination,
        }
    }).then((data) => {
        loading.value = false;

        distancierData.value = data.distancier ? data.distancier.pecDest : null;
        viaMichelinData.value = data.via_michelin ? data.via_michelin.pecDest : null;
        ptvData.value = data.ptv ? data.ptv.pecDest : null;

        if (!distancierData.value && !viaMichelinData.value && !ptvData.value) {
            error.value = 'Distance non présente dans le distancier';
        }
    });
});
</script>
