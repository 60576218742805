'use strict';

import Bloodhound from "bloodhound-js";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import Moment from "moment/moment";
import Euro from "@/Vue/Filters/Euro";
import AnnulationTrajet from "@/Vue/Components/Facturation/AnnulationTrajet.vue";

App.Shared = class {
    static getStructureElement()
    {
        if(App.Layout.treeSelectCache.structure) {
            return $(App.Layout.treeSelectCache.structure[0])
        }

        return $('[data-field="structure"]');
    }

    static getStructure()
    {
        let $structure = App.Shared.getStructureElement();

        return $structure.val() || $structure.data('value') || '';
    }

    static initHoverCards() {
        $('.js-hover-card').popover({
            trigger: 'hover',
            placement: 'right',
            fallbackPlacement: [],
            html: true,
        }).on('show.bs.popover', (e) => {
            const $tip = $($(e.currentTarget).data('bs.popover').tip);
            $tip.css('min-width', '25%');
            $tip.css('margin-left', '0');
            const $content = $tip.find('.popover-body');
            $content.addClass('p-3');
        }).on('hide.bs.popover', () => {
            if ($('.popover:hover').length) {
                return false;
            }
        });

        $('body').on('mouseleave', '.popover', () => {
            $('.js-hover-card').popover('hide');
        });
    }

    static initPatient() {
        let $patientBeneficiaire = $('[data-field="patientBeneficiaire"]');

        $('[data-field="patientAssure"]').on('select2:select', (e) => {
            let data = e.params.data;

            let beneficiaireAssureId = ($patientBeneficiaire.data('custom') || {}).assure;

            if(beneficiaireAssureId && beneficiaireAssureId !== data.id) {
                $patientBeneficiaire.val('').change();
            }
        });
    }
    static initExoneration(idTypeExoneration, idNature, idExoneration, idCaisse, idTauxCaisse, idMutuelle, idTauxMutuelle, cbSelect, cbInput, factureVm) {
        const $nature = $('#' + idNature);
        const $exoneration = $('#' + idExoneration);
        const $caisse = idCaisse ? $('#' + idCaisse) : null;
        const $tauxCaisse = $('#' + idTauxCaisse);
        const $mutuelle = $('#' + idMutuelle);
        const $tauxMutuelle = $('#' + idTauxMutuelle);
        const $typeExonerationItem = $('#'+idTypeExoneration).next('.dropdown-menu').find('.js-type-exoneration');

        // TypeExoneration
        $typeExonerationItem.click(function(event) {
            let nature = $(this).data('nature');
            let exoneration = $(this).data('exoneration');

            $nature.val(nature);
            $exoneration.val(exoneration).change();

            if(cbSelect) {
                cbSelect($(this).data('id'));
            }

            event.preventDefault();
        });

        const getTauxCaisseDefaut = () => {
            if (App.module instanceof App.Facturation.Facture.Form) {
                const trajet = App.module.vm.trajetsArray[0];

                if (trajet && trajet._grilleDetail.abstract?.tauxCaisse !== null) {
                    return trajet._grilleDetail.abstract?.tauxCaisse;
                }
            }

            return App.Constants.ORGANISME_TAUX_CAISSE;
        };

        const handleNatureExonerationInput = () => {
            let nature = $nature.val();
            let exoneration = $exoneration.val();

            if(nature !== '' && exoneration !== '') {
                $typeExonerationItem.each(function () {
                    let tauxCaisse = $(this).data('tauxCaisse') || getTauxCaisseDefaut();

                    if (nature === ''+$(this).data('nature') && exoneration === ''+$(this).data('exoneration') && tauxCaisse !== '') {
                        $tauxCaisse.val(tauxCaisse).change();

                        if(cbSelect) {
                            cbSelect($(this).data('id'));
                        }

                        return false;
                    }
                });
            }

            if(cbInput) {
                cbInput(nature, $exoneration.val());
            }
        };

        $nature.on('input', handleNatureExonerationInput);
        $nature.on('change', handleNatureExonerationInput);
        $exoneration.on('input', handleNatureExonerationInput);
        $exoneration.on('change', handleNatureExonerationInput);

        // Caisse
        // Select
        if($caisse) {
            $caisse.on('select2:select', (e) => {
                const nature = $nature.val();
                const exoneration = $exoneration.val();
                let tauxCaisse = e.params.data.tauxCaisse || getTauxCaisseDefaut();

                if ('' !== nature && '' !== exoneration) {
                    const $typeExoneration = $typeExonerationItem.filter('[data-nature="' + nature + '"][data-exoneration="' + exoneration + '"]');

                    if ($typeExoneration.data('tauxCaisse')) {
                        tauxCaisse = $typeExoneration.data('tauxCaisse');
                    }
                }

                $tauxCaisse.val(tauxCaisse).change();
            });
            // Clear
            $caisse.change((e) => {
                if (!e.currentTarget.value) {
                    $tauxCaisse.val($tauxCaisse.prop('required') ? 0 : '').change();
                }
            });
        }

        // Taux caisse
        $tauxCaisse.change(() => {
            if ($tauxCaisse.val() === '') {
                $tauxCaisse.val(0);
            }

            if($mutuelle.val()) {
                $tauxMutuelle.val(100 - parseInt($tauxCaisse.val())).change();
            } else {
                $tauxMutuelle.val($tauxMutuelle.prop('required') ? 0 : '').change();
            }
        });

        // Mutuelle
        // Select
        $mutuelle.on('select2:select', (e) => {
            let tauxMutuelle = e.params.data.tauxMutuelle || (100 - getTauxCaisseDefaut());

            if (100 === tauxMutuelle) {
                $tauxCaisse.val(0).change();
            } else {
                const tauxMutuelleMax = e.params.data.tauxMutuelle || 100;
                if ($tauxCaisse.val()) {
                    tauxMutuelle = Math.min(tauxMutuelleMax, 100 - $tauxCaisse.val());
                }

                $tauxMutuelle.val(tauxMutuelle).change();
            }
        });
        // Clear
        $mutuelle.change((e) => {
            if(!e.currentTarget.value) {
                $tauxMutuelle.val($tauxMutuelle.prop('required') ? 0 : '').change();
            }
        });
        $tauxMutuelle.change((e) => {
            if(!e.currentTarget.value) {
                $tauxMutuelle.val($tauxMutuelle.prop('required') ? 0 : '');
            }
        });
    }

    static initPrescripteur(ajaxPrescripteur, prescripteurLink, prescripteurForm, placeholderPrescripteurLibre, placeholderPrescripteur) {
        const $prescripteurAdresseBtn  = $('.js-trajet-adresse-btn[data-source="prescripteur"]');
        const $prescripteurHidden  = $('#' + prescripteurLink);

        if(!$prescripteurHidden.val()) {
            $prescripteurAdresseBtn.addClass('disabled');
        }

        const $prescripteurCard = $('#prescripteurCard');
        const $refreshPrescripteurBtn = $('#refreshPrescripteurBtn');

        $prescripteurCard.popover({
            trigger: 'hover',
            placement: 'left',
            html: true,
        }).on('show.bs.popover', function () {
            const $tip = $($(this).data('bs.popover').tip);
            $tip.css('min-width', '25%');
            $tip.find('.popover-body').addClass('p-3');
        });

        $('#' + prescripteurForm).typeahead(
            {
                minLength: 2,
                highlight: false,
                hint: false,
                tabAutocomplete: false,
            },
            {
                source: new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: ajaxPrescripteur,
                        prepare: (query, settings) => {
                            settings.url = this.getStructure() ? settings.url.replace('_QUERY_', encodeURIComponent(query)).replace('_ID_STRUCTURE_', this.getStructure()) : null;
                            return settings;
                        },
                        cache: false
                    }
                }),
                limit: 400,
                templates: {
                    suggestion: App.Utils.getAutocompleteTemplate('prescripteur', true),
                    pending: () => '<div class="tt-suggestion text-muted">Recherche en cours…</div>',
                    notFound: '<div></div>',
                },
                displayKey: 'text'
            }
        ).on('typeahead:select', (e, data) => {
            let $input = $(e.currentTarget);

            $input.addClass('border-theme');
            $input.data('structure', data.structure);
            $input.data('localisation', data.localisation);
            $prescripteurAdresseBtn.removeClass('disabled');

            $prescripteurHidden.val(data.id).change();

            $prescripteurCard.attr('data-content', data.card);
            $prescripteurCard.find('.js-card-btn-empty').removeClass('d-flex').addClass('d-none');
            $prescripteurCard.find('.js-card-btn').attr('href', data.cardLink).removeClass('disabled').removeClass('d-none').addClass('d-flex');
            $refreshPrescripteurBtn.show();

            $('#facture_am').val('').change();
        }).on('input', (e) => {
            let $input = $(e.currentTarget);

            $input.removeClass('border-theme');
            $input.data('structure', null);
            $input.data('localisation', null);
            $prescripteurAdresseBtn.addClass('disabled');

            $prescripteurHidden.val('');

            $prescripteurCard.attr('data-content', $input.val() !== '' ? placeholderPrescripteurLibre : placeholderPrescripteur);
            $prescripteurCard.find('.js-card-btn').attr('href', '#').addClass('disabled').removeClass('d-flex').addClass('d-none')
            $prescripteurCard.find('.js-card-btn-empty').removeClass('d-none').addClass('d-flex');
            $refreshPrescripteurBtn.hide();

            $('#facture_am').val('').change();
        });
    }

    static initTypeFacture(prescripteurForm) {
        const ID_FACTURE_LIBRE = '14010089-da82-4190-9d5b-e7ecc295a8fa';
        const ID_PAYEUR_PATIENT = 'ab194f00-23fd-4123-8a26-a6801ee6add5';
        const ID_PAYEUR_PRESCRIPTEUR = '48ada9e0-1e41-45c4-a974-edc57412a781';

        $('[data-field="typeFacture"] input').change((e) => {
            const $prescripteurInput  = $('#' + prescripteurForm);
            const $input = $(e.currentTarget);
            const $typePayeur = $('[data-field="typePayeur"]');

            if($input.val() === ID_FACTURE_LIBRE) {
                const value = $prescripteurInput.val() ? ID_PAYEUR_PRESCRIPTEUR : ID_PAYEUR_PATIENT
                if($typePayeur.is('select')) {
                    if(!$typePayeur.val()) {
                        $typePayeur.val(value).change();
                    }
                } else {
                    if(!$typePayeur.find('input:checked').length) {
                        $typePayeur.find('input[value="' + value + '"]').prop('checked', true).change().parent().addClass('active');
                    }
                }
                $('#typePayeur').show();
            } else {
                $('#typePayeur').hide();
                if($typePayeur.is('select')) {
                    $typePayeur.val(null).change();
                } else {
                    $typePayeur.children().removeClass('active').find('input:checked').prop('checked', false).change();
                }
            }
        });

    }

    static initAppelant(url) {
        let $appelant = $('[data-field="appelant"]');
        let $numeroAppel = $('[data-field="numeroAppel"]');

        $appelant.typeahead({
                minLength: 2,
                highlight: true,
                hint: false,
                tabAutocomplete: false,
            },
            {
                source: new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: url,
                        prepare: (query, settings) => {
                            settings.url = App.Shared.getStructure() ? settings.url.replace('_QUERY_', encodeURIComponent(query)).replace('_ID_STRUCTURE_', App.Shared.getStructure()) : null;
                            return settings;
                        },
                    }
                }),
                limit: 400,
                templates: {
                    suggestion: function (context, options) {
                        let html = '<div>';
                        html += '<div><b>' + context.libelle + '</b></div>';
                        return html + '</div>';
                    }
                },
                displayKey: 'libelle'
            }).on('typeahead:select', (e, data) => {
                if(data.telephone && !$numeroAppel.val()) {
                    $numeroAppel.val(data.telephone);
                }
            })
        ;
    }

    static _computeTarification(categorie) {
        switch (true) {
            case categorie.estAmbu:
                return 1;
            case categorie.estVsl:
                return 2;
            case categorie.estTaxi:
                return 3;
        }

        return 0;
    }

    static updateTrajetTarification($embeds) {
        const $categorie = $('[data-field="categorie"]');
        const categorieData = $categorie.data('custom') || {};
        const categorieTarification = $categorie.val() ? App.Shared._computeTarification(categorieData) : 0;

        $embeds.each(function() {
            const $vehicule =  $(this).find('[data-field="vehicule"]');
            const vehiculeData = $vehicule.data('custom') || {};
            const vehiculeTarification = $vehicule.val() ? App.Shared._computeTarification(vehiculeData.categorie) : null;
            const tarification = vehiculeTarification !== null && !(1 === vehiculeTarification && 2 === categorieTarification) ? vehiculeTarification : categorieTarification;

            if(null !== tarification) {
                const $tarification =  $(this).find('.js-tarification');
                $tarification.find('.btn.active').removeClass('active');
                $tarification.find('input[value="'+tarification+'"]').prop('checked', true).change().parent().addClass('active');
            }
        })

        App.Shared.updateTempsSurPlace($embeds);
    }

    static updateTempsSurPlace($embeds, adresseType, organismeData) {
        const $categorie = $('[data-field="categorie"]');
        const categorieData = $categorie.data('custom') || {};

        $embeds.each(function() {
            const $vehicule = $(this).find('[data-field="vehicule"]');
            const vehiculeData = $vehicule.data('custom') || {};
            const estAmbu = $vehicule.val() ? vehiculeData.categorie.estAmbu : categorieData.estAmbu;
            let tempsSurPlace = App.Shared.getStructureElement().data('tempsSurPlace'+(organismeData ? 'Organisme' : '')+'ParDefaut'+(estAmbu ? 'Ambu' : 'Autre'));

            if (organismeData) {
                const tempsSurPlaceOrganisme = estAmbu ? organismeData.ambu : organismeData.autre;
                if (null !== tempsSurPlaceOrganisme) {
                    tempsSurPlace = tempsSurPlaceOrganisme;
                }
            }

            if (adresseType) {
                $(this).find('[data-field="tempsSurPlace'+adresseType+'"]').val(tempsSurPlace).change();
            } else {
                $(this).find('[data-field="tempsSurPlacePriseEnCharge"]').val(tempsSurPlace);
                $(this).find('[data-field="tempsSurPlaceDestination"]').val(tempsSurPlace).change();
            }
        });
    }

    static updateTaxiTarif() {
        const taxiParisien = this.getStructureElement().data('taxiParisien');

        $('[data-field="taxiTarif"]').each((i, el) => {
            const $taxiTarif = $(el);
            const value = $taxiTarif.val();
            $taxiTarif.html(App.Shared._taxiTarifChoices);

            if (taxiParisien) {
                $taxiTarif.find(':not([value=""], [data-tarif-parisien])').remove();
            } else {
                $taxiTarif.find(':not([value=""], [data-tarif-provincial])').remove();
            }

            if (value) {
                $taxiTarif.val(value).val($taxiTarif.val()).change();
            }
        });
    }

    static updateCategoriePreset() {
        const categoriePresetData = this.getStructureElement().data('categorie-preset');
        const categoriePresetIds = categoriePresetData ? categoriePresetData.split(',') : [];
        const $transportCategoriePreset = $('[data-field="categoriePreset"]');

        const $group = $transportCategoriePreset.parents('.form-group').first();

        if (categoriePresetIds.length) {
            $group.show();
            $transportCategoriePreset.children().hide();
            categoriePresetIds.forEach((categorieId) => {
                $transportCategoriePreset.find('input[value="'+categorieId+'"]').parent().show();
            });
        } else {
            $group.hide();
        }
    }

    static updateTopSuggestions($base, forceUpdateTops = false) {
        const dureeCalculeeDebutPriseEnCharge = $base.find('[data-field="dureeCalculeeDebutPriseEnCharge"]').val();
        const dureeCalculeePriseEnChargeDestination = $base.find('[data-field="dureeCalculeePriseEnChargeDestination"]').val();
        const dureeCalculeeDestinationFin = $base.find('[data-field="dureeCalculeeDestinationFin"]').val();

        const tempsSurPlacePriseEnCharge = $base.find('[data-field="tempsSurPlacePriseEnCharge"]').val()*1 || 0;
        const tempsSurPlaceDestination = $base.find('[data-field="tempsSurPlaceDestination"]').val()*1 || 0;

        const modeRendezVous = $base.find('.js-mode-rdv-input:checked').val();
        const $topDebut = $base.find('[data-field="topDebut"]');
        const $topPriseEnCharge = $base.find('[data-field="topPriseEnChargeArrivee"]');
        const $topDestination = $base.find('[data-field="topDestinationArrivee"]');
        const $topFin = $base.find('[data-field="topFin"]');

        const getSuggestionBtn = ($top) => $top.parent().find('.js-top-suggestion-btn');

        let updateTops = forceUpdateTops;

        if (forceUpdateTops === null) {
            updateTops = true;

            for (const $top of '1' === modeRendezVous ? [$topDebut, $topDestination, $topFin] : [$topDebut, $topPriseEnCharge, $topFin]) {
                const $topBtn = getSuggestionBtn($top);

                if ($topBtn.data('value') !== $top.val()) {
                    updateTops = false;
                    break;
                }
            }
        }

        const setSuggestion = ($top, duree, $topReference) => {
            let suggestion = null;

            if (duree !== null && !$top.data('topReel')) {
                const topReference = Moment($topReference.val(), 'HH:mm');
                if (topReference.isValid()) {
                    suggestion = topReference.add(duree, 'minutes').format('HH:mm');
                }
            }

            if (suggestion) {
                if (updateTops) {
                    $top.val(suggestion).change();
                }

                const isValue = $top.val() === suggestion;
                getSuggestionBtn($top).data('value', suggestion).text(suggestion).prop('disabled', isValue).removeClass('btn-light').addClass('btn-secondary');
            } else {
                getSuggestionBtn($top).data('value', null).text('-').prop('disabled', true).addClass('btn-light').removeClass('btn-secondary');
            }
        }

        if ('1' === modeRendezVous) {
            // Mode PEC
            setSuggestion($topPriseEnCharge, null);
            setSuggestion($topDestination, dureeCalculeePriseEnChargeDestination !== null ? dureeCalculeePriseEnChargeDestination*1 + tempsSurPlaceDestination : null, $topPriseEnCharge);
        } else {
            // Mode DEST
            setSuggestion($topPriseEnCharge, dureeCalculeePriseEnChargeDestination !== null ? dureeCalculeePriseEnChargeDestination*-1 - tempsSurPlaceDestination : null, $topDestination);
            setSuggestion($topDestination, null);
        }

        setSuggestion($topDebut, dureeCalculeeDebutPriseEnCharge !== null ? dureeCalculeeDebutPriseEnCharge *-1 - tempsSurPlacePriseEnCharge : null, $topPriseEnCharge);
        setSuggestion($topFin, dureeCalculeeDestinationFin !== null ? dureeCalculeeDestinationFin*1 : null, $topDestination);
    }

    static initTrajetTops() {
        $('.js-mode-rdv-input').change((e) => {
            const $el = $(e.currentTarget);
            const $base = $el.parents('.js-trajet-embed');
            const $topRendezVous = $el.parents('.form-group').find('.js-top-input');

            const activeClass = 'btn-info disabled';
            const inactiveClass = 'btn-outline-secondary border-bottom-0';

            $el.parent().addClass(activeClass).removeClass(inactiveClass);

            $topRendezVous.prop('required', true);
            if (!$topRendezVous.val()) {
                $topRendezVous.focus();
            }

            $base.find('.js-mode-rdv-input').not($el).parent().removeClass(activeClass).addClass(inactiveClass).parents('.form-group').find('.js-top-input').prop('required', false);

            App.Shared.updateTopSuggestions($base);

            $base.find('[data-field="rendezVousTime"]').val($topRendezVous.val()).change();
        })

        $('.js-top-input').change((e) => {
            const $el = $(e.currentTarget);
            const $base = $el.parents('.js-trajet-embed');

            const field = $el.data('field');
            const modeRendezVous = $base.find('.js-mode-rdv-input:checked').val();
            const estTopRdv = ('topPriseEnChargeArrivee' === field && '1' === modeRendezVous) || ('topDestinationArrivee' === field && '2' === modeRendezVous);

            App.Shared.updateTopSuggestions($base, estTopRdv);

            if ('topPriseEnChargeArrivee' === field || 'topDestinationArrivee' === field) {
                if (!$el.data('topReel')) {
                    if (estTopRdv) {
                        $base.find('[data-field="rendezVousTime"]').val($el.val()).change();
                    } else {
                        if ($el.val() && 1 === $base.find('.js-top-input').filter((i, input) => input.value).length) {
                            $el.parents('.form-group').find('.js-mode-rdv-input').prop('checked', true).change();
                        }
                    }
                }
            }

        })
        $('.js-top-suggestion-btn').click((e) => {
            const $el = $(e.currentTarget);
            const value = $el.data('value');

            if (value) {
                $el.parent().find('input[type="text"]').val(value).change();
            }
        });

        $('.js-trajet-embed').each(function() {
            App.Shared.updateTopSuggestions($(this));
        });
    }

    static initTrajetComplementToggle() {
        $('body').on('click', '.js-tarification-toggle-btn', () => {
            $('.js-tarification-toggle-btn').each((index, btn) => {
                let $btn = $(btn);
                let $i = $btn.find('i');

                if ($btn.attr('title') === $btn.data('title-open')) {
                    $btn.attr('title', $btn.data('title-close'));
                    $i.removeClass($btn.data('icon-open')).addClass($btn.data('icon-close'));
                } else {
                    $btn.attr('title', $btn.data('title-open'));
                    $i.removeClass($btn.data('icon-close')).addClass($btn.data('icon-open'));
                }
            });

            $('.js-caracteristique-tarification-supplement-content').toggle();
        });
    }

    static initTrajetTarification() {
        const $tarificationToggleBtn = $('.js-tarification-toggle-btn');

        if($('.js-caracteristique-tarification-supplement-content').is(':visible')) {
            $tarificationToggleBtn.attr('title', $tarificationToggleBtn.data('title-close'));
            $tarificationToggleBtn.find('i').removeClass($tarificationToggleBtn.data('icon-open')).addClass($tarificationToggleBtn.data('icon-close'));
        }

        $('body')
            .on('change', '[data-field="supplementPeageMontant"]', (event) => {
                const $montant = $(event.currentTarget);
                const $quantite = $montant.parent().find('[data-field="supplementPeageQuantite"]');

                if($montant.val() && !$quantite.val()) {
                    $quantite.val('1');
                }
            })
            .on('change', '[data-field="tempsSurPlacePriseEnCharge"], [data-field="tempsSurPlaceDestination"]', (e) => App.Shared.updateTopSuggestions($(e.currentTarget).closest('.js-trajet-embed'), null))
            .on('change', '[data-field="vehicule"]', (e) => App.Shared.updateTrajetTarification($(e.currentTarget).closest('.js-trajet-embed')))
            .on('change', '[data-field="categorie"], [data-field="estTpmr"]', () => App.Shared.updateTrajetTarification($('.js-trajet-embed').filter((i, el) => {
                const $toggleInput = $(el).parents('.panel').prev().find('input');

                return !$toggleInput.length || $toggleInput.prop('checked');
            })))
            .on('change', '.js-tarification input', (event) => {
                const $tarificationField = $(event.currentTarget);
                const $panel = $tarificationField.closest('.panel');
                const $tarificationLibreField = $panel.find('.js-tarification-libre-btn input')

                const tarificationAutre = parseInt($tarificationField.val()) === 0;

                $tarificationLibreField.prop('checked', tarificationAutre).prop('disabled', tarificationAutre).change().parent().toggleClass('active disabled', tarificationAutre);

            })
            .on('change', '.js-tarification-libre-btn input', (event) => {
                let $tarificationLibreField = $(event.currentTarget)
                let $panel = $tarificationLibreField.closest('.panel');
                let $tarificationField = $panel.find('.js-tarification input:checked')

                $('.js-tarification-ambu, .js-tarification-libre, .js-tarification-taxi, .js-tarification-tpmr, .js-tarification-vsl', $panel).hide();

                if($tarificationLibreField.is(':checked')) {
                    $panel.find('.js-tarification-libre').show();
                } else {
                    switch (parseInt($tarificationField.val())) {
                        case 1:
                            $panel.find('.js-tarification-ambu').show();
                            break;
                        case 2:
                            $panel.find('.js-tarification-vsl').show();
                            break;
                        case 3:
                            $panel.find('.js-tarification-taxi').show();
                            break;
                        case 4:
                            $panel.find('.js-tarification-tpmr').show();
                            break;
                    }
                }

                $('.js-tarification-optionnel').filter((i, el) => $(el).find('input').val() === '').each((i, el) => {
                    $(el).hide();
                    $('[data-toggle-supplement="'+$(el).data('supplement')+'"]').removeClass('disabled');
                });
            })
            .on('click', '[data-toggle-supplement]', (event) => {
                const $el = $(event.currentTarget);
                const supplement = $el.data('toggleSupplement');

                $el.addClass('disabled');
                $('[data-supplement="'+supplement+'"]').show();

                event.preventDefault();
            })
        ;

        App.Shared.initTrajetComplementToggle();
    }

    static handleTrajetTarificationGrilleDetail($container, grilleDetail) {
        $container.each((i, trajet) => {
            const $trajet = $(trajet);
            const grilleDetailTaxi = grilleDetail.taxi || {};
            const grilleDetailVsl = grilleDetail.vsl || {};

            const $tarificationField = $trajet.find('.js-tarification input:checked')
            const $tarificationLibreField = $trajet.find('.js-tarification-libre-btn input')
            const isTarificationTaxi = $tarificationField.val() === '3' && !$tarificationLibreField.is(':checked');
            const isTarificationVsl = $tarificationField.val() === '2' && !$tarificationLibreField.is(':checked');

            $trajet.find('.js-tarification-taxi-kilometre-approche').toggleClass('js-tarification-taxi', grilleDetailTaxi.modeTarifKilometriqueApproche).toggle(isTarificationTaxi && grilleDetailTaxi.modeTarifKilometriqueApproche);
            $trajet.find('.js-tarification-taxi-tarif-neige-verglas').toggleClass('js-tarification-taxi', grilleDetailTaxi.modeTarifNeigeVerglas).toggle(isTarificationTaxi && grilleDetailTaxi.modeTarifNeigeVerglas);
            $trajet.find('.js-tarification-supplement-tpmr')
                .toggleClass('js-tarification-taxi', grilleDetailTaxi.supplementTpmr)
                .toggleClass('js-tarification-vsl', grilleDetailVsl.supplementTpmr)
                .toggle((isTarificationTaxi && grilleDetailTaxi.supplementTpmr) || (isTarificationVsl && grilleDetailVsl.supplementTpmr))
            ;

            const $taxiForfait = $trajet.find('[data-field="taxiForfait"]');
            let taxiForfaitValue = $taxiForfait.val();
            let taxiForfaitValueCleared = taxiForfaitValue !== null && taxiForfaitValue !== '';
            $taxiForfait.html($taxiForfait.children().first());
            for (const id in grilleDetailTaxi.forfaitCollection) {
                const selected = id === taxiForfaitValue;
                $taxiForfait.append(new Option(grilleDetailTaxi.forfaitCollection[id], id, selected, selected));
                if (selected) {
                    taxiForfaitValueCleared = false;
                }
            }
            if (taxiForfaitValueCleared) {
                $taxiForfait.change();
            }
        });
    }

    static initDistancierBtn() {
        $('.js-distancier-btn').each(function() {
            $(this).popover({
                placement: 'top',
                content: '<div class="d-flex">' +
                    '<div class="d-flex flex-1" id="distancierPopoverContent'+$(this).attr('id')+'">' +
                    '<div class="p-3 flex-1 d-flex align-items-center justify-content-center"><div class="spinner-border" role="status"></div></div>' +
                    '</div>' +
                    '<div id="distancierPopoverViaMichelin'+$(this).attr('id')+'" class="pl-3 d-none align-items-center text-center border-left"><a href="#" id="distancierPopoverViaMichelinBtn'+$(this).attr('id')+'"><i class="fa-solid fa-up-right-from-square fa-2x"></i><br>Via Michelin</a></div>' +
                    '</div>',
                sanitize: false,
                html: true,
            }).on('show.bs.popover', function() {
                const $tip = $($(this).data('bs.popover').tip);
                $tip.addClass('distancier-popover');
            }).on('shown.bs.popover', function(e) {
                const $content = $('#distancierPopoverContent'+$(this).attr('id'));
                const $viaMichelin = $('#distancierPopoverViaMichelin'+$(this).attr('id'));

                const departInsee = $('#'+$(this).data('distancierDepartCodeInsee')).val();
                const departLatitude = $('#'+$(this).data('distancierDepartLatitude')).val();
                const departLongitude = $('#'+$(this).data('distancierDepartLongitude')).val();
                const arriveeInsee = $('#'+$(this).data('distancierArriveeCodeInsee')).val();
                const arriveeLatitude = $('#'+$(this).data('distancierArriveeLatitude')).val();
                const arriveeLongitude = $('#'+$(this).data('distancierArriveeLongitude')).val();

                if(departInsee !== '' && arriveeInsee !== '') {
                    const rendezVousDate = $(this).data('distancierRendezVousDate') ? $('#'+$(this).data('distancierRendezVousDate')).val() : null;
                    const rendezVousTime = $(this).data('distancierRendezVousTime') ? $('#'+$(this).data('distancierRendezVousTime')).val() : null;
                    const rendezVousModeDest = $(this).data('distancierRendezVousMode') ? $('#'+$(this).data('distancierRendezVousMode')+'_1').prop('checked') : false;

                    const params = {
                        full: true,
                        structure: App.Shared.getStructure(),
                        departInsee, departLatitude, departLongitude,
                        arriveeInsee, arriveeLatitude, arriveeLongitude,
                    };

                    if (rendezVousDate && rendezVousTime) {
                        params[rendezVousModeDest ? 'arriveeDate' : 'departDate'] = rendezVousDate + ' '+rendezVousTime;
                    }

                    $.ajax({
                        url: Router.generate('distancier.ajax'),
                        method: 'POST',
                        data: {geocodage_distancier: params},
                        cache: false
                    }).done((data) => {
                        let hasData = false;
                        $content.html('');

                        const sources = {'distancier': 'Distancier', 'via_michelin': 'ViaMichelin', 'loxane': 'Loxane', 'ptv': 'PTV'};

                        for(const source in sources) {
                            const sourceData = data[source];
                            const itineraire = sourceData ? sourceData.pecDest : null;

                            if (itineraire) {
                                hasData = true;

                                const $kilometre = $('#' + $(this).data('distancierTargetKilometre'));
                                const $kilometreCalcule = $('#' + $(this).data('distancierTargetKilometreCalcule'));
                                const $peageQuantite = $('#' + $(this).data('distancierTargetPeageQuantite'));
                                const $peageMontant = $('#' + $(this).data('distancierTargetPeageMontant'));

                                const $table = $('<table class="table m-0"></table>');

                                // Distance
                                const $ligneDistance = $('<tr></tr>');
                                $ligneDistance.append('<th>Distance</th>');
                                if (null !== itineraire.kilometre) {
                                    $ligneDistance.append('<td>' + itineraire.kilometre + ' km</td>');
                                } else {
                                    $ligneDistance.append('<td>-</td>');
                                }

                                const $reporterDistanceBtn = $('<button class="js-btn-distancier-reporter-kilometre btn btn-xs" title="Reporter"></button>');
                                if ($kilometre.val() === '' + itineraire.kilometre) {
                                    $reporterDistanceBtn.prop('disabled', true);
                                    $reporterDistanceBtn.addClass('btn-success');
                                    $reporterDistanceBtn.append('<i class="fa-solid fa-check"></i>');
                                } else {
                                    $reporterDistanceBtn.addClass('btn-secondary');
                                    $reporterDistanceBtn.append('<i class="fa-regular fa-clone"></i>');
                                }
                                $reporterDistanceBtn.click(() => {
                                    $('.js-btn-distancier-reporter-kilometre').prop('disabled', false).removeClass('btn-success').addClass('btn-secondary');

                                    $kilometre.val(itineraire.kilometre).change();
                                    $kilometreCalcule.val(itineraire.kilometre).change();
                                    $reporterDistanceBtn.prop('disabled', true);
                                    $reporterDistanceBtn.removeClass('btn-secondary');
                                    $reporterDistanceBtn.addClass('btn-success');
                                    $reporterDistanceBtn.html('<i class="fa-solid fa-check"></i>');
                                });


                                $ligneDistance.append($('<td></td>').append($reporterDistanceBtn));
                                $table.append($ligneDistance);

                                // Péage
                                const $lignePeage = $('<tr></tr>');
                                $lignePeage.append('<th>Péage</th>');
                                if (null !== itineraire.peageQuantite || null !== itineraire.peageMontant) {
                                    $lignePeage.append('<td>' + (itineraire.peageQuantite !== null ? itineraire.peageQuantite : '') + (null !== itineraire.peageQuantite && null !== itineraire.peageMontant ? ' <i class="fa-solid fa-right-long"></i> ' : '') + (itineraire.peageMontant !== null ? Euro(itineraire.peageMontant) : '') + '</td>');
                                } else {
                                    $lignePeage.append('<td>-</td>');
                                }

                                const $reporterPeageBtn = $('<button class="js-btn-distancier-reporter-peage btn btn-xs" title="Reporter"></button>');

                                if ($peageQuantite.val() === (itineraire.peageQuantite !== null ? itineraire.peageQuantite.toString() : '') && $peageMontant.val() === (itineraire.peageMontant !== null ? itineraire.peageMontant : '')) {
                                    $reporterPeageBtn.prop('disabled', true);
                                    $reporterPeageBtn.addClass('btn-success');
                                    $reporterPeageBtn.append('<i class="fa-solid fa-check"></i>');
                                } else {
                                    $reporterPeageBtn.addClass('btn-secondary');
                                    $reporterPeageBtn.append('<i class="fa-regular fa-clone"></i>');
                                }
                                $reporterPeageBtn.click(() => {
                                    $('.js-btn-distancier-reporter-peage').prop('disabled', false).removeClass('btn-success').addClass('btn-secondary');

                                    $peageQuantite.val(itineraire.peageQuantite)
                                    $peageMontant.val(itineraire.peageMontant).change();
                                    $reporterPeageBtn.prop('disabled', true);
                                    $reporterPeageBtn.removeClass('btn-secondary');
                                    $reporterPeageBtn.addClass('btn-success');
                                    $reporterPeageBtn.html('<i class="fa-solid fa-check"></i>');
                                });

                                $lignePeage.append($('<td></td>').append($reporterPeageBtn));
                                $table.append($lignePeage);

                                const $block = $('<div class="flex-1"></div>');

                                $block.append('<h3 class="mt-1" style="font-weight: 600; font-size: 12px; line-height:1;"><i class="ti-ruler"></i> '+sources[source]+'</h3>');
                                $block.append($table);

                                $content.append($block);
                            } else if ('via_michelin' === source) {
                                $viaMichelin.addClass('d-flex').removeClass('d-none');
                                $content.addClass('pr-3');
                            }
                        }

                        if(!hasData) {
                            $content.html('<div class="d-flex align-items-center text-center">Distance non présente dans le distancier</div>');
                            $viaMichelin.addClass('d-flex').removeClass('d-none');
                            $content.addClass('pr-3');
                        }

                        $(this).popover('update');
                    });
                } else {
                    $content.html('<div class="d-flex align-items-center text-center">Sélectionnez un lieu de prise en charge et de destination pour faire appel au distancier</div>');
                    $viaMichelin.addClass('d-flex').removeClass('d-none');
                    $content.addClass('pr-3');
                }

                $(this).popover('update');

                $('#distancierPopoverViaMichelinBtn'+$(this).attr('id')).click(() => {
                    const params = {};
                    for(let type of ['Depart', 'Arrivee']) {
                        let adresse = $('#'+$(this).data('distancier'+type+'Adresse')).val().replace('\n', ' ').replace('\r','').trim();
                        let codePostal = $('#'+$(this).data('distancier'+type+'CodePostal')).val().trim();
                        let ville = $('#'+$(this).data('distancier'+type+'Ville')).val().trim();

                        if (adresse && (codePostal || ville)) {
                            adresse += ', ';
                        }

                        params['libelle'+type] = adresse+(codePostal+' '+ville).trim();
                        params['latitude'+type] = $('#'+$(this).data('distancier'+type+'Latitude')).val();
                        params['longitude'+type] = $('#'+$(this).data('distancier'+type+'Longitude')).val();
                    }

                    const url = Router.generate('viamichelin.itineraire', params);
                    const win = window.open(url, '_blank', 'noreferrer');
                    if(win) win.focus();

                    $(this).popover('hide');

                    return false;
                });
            });
        })

        $('body')
            .on('click', function (e) {
                if (!$(e.target).is('.js-distancier-btn') && $(e.target).parents('.js-distancier-btn').length === 0 && $(e.target).parent().length && $(e.target).parents('.popover.show').length === 0) {
                    $('.js-distancier-btn').popover('hide');
                }
            })
        ;
    }

    static initTrajetAdresseBtn() {
        let fields = ['adresse', 'complementAdresse', 'codePostal', 'ville', 'digicode', 'etage', 'codeInsee', 'latitudeDegre', 'longitudeDegre', 'precision'];
        let $body = $('body');

        $body.on('click', '.js-trajet-adresse-btn', (e) => {
            let $el = $(e.currentTarget);
            let source = $el.data('source');
            let target = $el.data('target');

            if('tag' === source) {
                let data = App.Shared.Serie.etiquettes.tags[$el.text()];

                if(data) {
                    let type = $el.closest('.js-trajet-adresse-presets').data('adresseType');
                    let $container = $el.closest('.form-body').find('[data-adresse-type="'+type+'"]');

                    for(let field in data) {
                        let $field = $container.find('[data-field="'+field+'"]');
                        let value = data[field];

                        if($field.hasClass('tt-input')) {
                            $field.typeahead('val', value);
                        } else {
                            $field.val(value);
                        }

                        App.Utils.flashField($field);
                    }

                    $container.find('[data-field="precision"]').change();
                    App.Layout.triggerDistancier($container.find('[data-field="codeInsee"]').attr('id'));
                }
            }
            else if('eraser' === source) {
                for(const field of fields) {
                    let $field = $('#' + target.replace('adresse', field));
                    if($field.hasClass('tt-input')) {
                        $field.typeahead('val', '');
                    } else {
                        $field.val('');
                    }

                    App.Utils.flashField($field);

                    if ('precision' === field) {
                        $field.change();
                    }
                }
            }
            else if('switch' === source) {
                let split = target.split(','),
                    target1 = split[0],
                    target2 = split[1];

                for(let i in fields) {
                    let $field1 = $('#' + target1.replace('adresse', fields[i])),
                        $field2 = $('#' + target2.replace('adresse', fields[i])),
                        val1 = $field1.val(),
                        val2 = $field2.val();

                    if($field1.hasClass('tt-input')) {
                        $field1.typeahead('val', val2);
                        $field2.typeahead('val', val1);
                    } else {
                        $field1.val(val2);
                        $field2.val(val1);
                    }

                    App.Utils.flashField($field1);
                    App.Utils.flashField($field2);
                }

                $('#'+target1.replace('adresse', 'precision')).change();
                $('#'+target2.replace('adresse', 'precision')).change();
                App.Layout.triggerDistancier(target2.replace('adresse', 'codeInsee'));
            }
            else if('prescripteur' === source || 'patient' === source) {
                let localisation;

                if('prescripteur' === source) {
                    localisation = $('[data-field="prescripteurForm"]').data('localisation');
                }
                else if ('patient' === source) {
                    let $benficiaire = $('[data-field="patientBeneficiaire"]');
                    let $assure = $('[data-field="patientAssure"]');
                    let $patient = $('[data-field="patient"]');

                    if($benficiaire.val()) {
                        localisation = ($benficiaire.data('custom') || {}).localisation;
                    } else if ($assure.val()) {
                        localisation = ($assure.data('custom') || {}).localisation;
                    } else if ($patient.val()) {
                        localisation = ($patient.data('custom') || {}).localisation;
                    }
                }

                if(localisation) {
                    for(let i in fields) {
                        let $field = $('#' + target.replace('adresse', fields[i]));
                        if($field.hasClass('tt-input')) {
                            $field.typeahead('val', localisation[fields[i]]);
                        } else {
                            $field.val(localisation[fields[i]]);
                        }

                        App.Utils.flashField($field);
                    }

                    $('#'+target.replace('adresse', 'precision')).change();
                    App.Layout.triggerDistancier(target.replace('adresse', 'codeInsee'));
                }
            }

            $body.change();
        });
    }

    static initTransportStructure(structureName, prescripteurForm, prescripteurLink, cb) {
        let bureau = App.Shared.getStructure();
        let structure = App.Shared.getStructureElement().data('structure');

        const $taxiTarif = $('[data-field="taxiTarif"]').first();
        $taxiTarif.find('option').removeAttr('data-select2-id');
        App.Shared._taxiTarifChoices = $taxiTarif.html();
        this.updateTaxiTarif();

        $('body')
            .on('change', '[data-field="vehicule"]', () => {
                let $vehicule = $('[data-field="vehicule"] option:not([value=""]):selected').last().parent();

                if ($vehicule.length) {
                    let $field = $(App.Layout.treeSelectCache['structure'][0].replace(':checked', '[value="'+$vehicule.data('custom').structure+'"]'));
                    $field.prop('checked', true).change();
                }
            })
            .on('change', 'input[name="' + structureName + '"]', (e) => {
                let $el = $(e.currentTarget);
                let id = $el.val();

                this.updateCategoriePreset();
                this.updateTaxiTarif();

                // Equipage, véhicule, personnel
                let clearedFields = [];
                for(let field of ['vehicule', 'personnel1', 'personnel2']) {
                    $('[data-field="'+field+'"]').each((index, el) => {
                        let $field = $(el);
                        let structures = $field.data('custom').structures || [];

                        if($field.val() && !structures.includes(id)) {
                            $field.val('').trigger('change');
                            clearedFields.push($field);
                        }
                    });
                }

                for(let $field of clearedFields) {
                    $field.trigger('select2:unselecting');
                }

                if(id !== bureau) {
                    $('[data-field="serie"]').val('').change();

                    bureau = id;
                }

                if($el.data('structure') !== structure) {
                    if(cb) {
                        cb();
                    }

                    // Contrat
                    $('[data-field="contrat"]').val('').change();

                    // Motif
                    const $motif = $('[data-field="motif"]');
                    if(($motif.data('custom') || {}).structure) {
                        $motif.val('').change();
                    }

                    // Caisse / Mutuelle
                    for(let field of ['caisse', 'mutuelle']) {
                        $('[data-field="'+field+'"]').each((index, el2) => {
                            if($(el2).data('structure')) {
                                $(el2).val('').change();
                            }
                        });
                    }

                    // Prescripteur
                    let $prescripteurForm = $('#' + prescripteurForm);
                    if($prescripteurForm.data('structure')) {
                        $('#' + prescripteurLink).val('');
                        $prescripteurForm.removeClass('border-theme');
                    }

                    structure = $el.data('structure');
                }
            })
        ;
    }

    static initContextMenu(params) {
        let items = {};
        let $form = null;
        let $formAction = null;
        let $formAnnulationMotif = null;
        let $formAnnulationCommentaire = null;

        $.contextMenu({
            selector: '#trajets tr',
            build: ($trigger, event) => {
                let id = $trigger.data('id');
                if (!id) return false;

                items = {};

                const $cell = $(event.target).closest('td');
                let filterField = $cell.data('filterField');
                if(filterField) {
                    const filterValue = $cell.data('filterValue');

                    let fieldNames = [filterField];
                    let name = 'Filtrer « ' + filterValue + ' »';
                    if('date' === filterField) {
                        name = 'Filtrer au ' + filterValue;
                        fieldNames = ['debut', 'fin'];
                    }
                    else if('s' === filterField) {
                        name = 'Rechercher « ' + filterValue + ' »';
                    }

                    if($('[data-field="'+fieldNames[0]+'"]').length) {
                        items.search = {
                            name, icon: 'fa-solid fa-magnifying-glass', callback: () => {
                                for (const fieldName of fieldNames) {
                                    const $field = $('[data-field="' + fieldName + '"]');

                                    if ($field.hasClass('js-select2-autocomplete')) {
                                        const filterId = $cell.data('filterId');

                                        let option = new Option(filterValue, filterId, false, false);
                                        $field.html(option);
                                    } else {
                                        $field.val(filterValue);
                                    }
                                    $field.change();
                                }

                                $('#form').submit();
                            }
                        };

                        items.filterSeparator = '';
                    }
                }

                if(!$trigger.hasClass('ui-selected')) {
                    $('.js-selectable-select input:checked').prop('checked', false).change();
                    $trigger.find('.js-selectable-select input:not([disabled])').prop('checked', true).change();
                }

                let $formSingle = $('#formSingleTrajet')[0];
                let $formBatch = $('#formBatchTrajet')[0];

                if ($formSingle) {
                    $form = $formSingle;
                    $formAction = $('#formTrajetSingleAction');
                    $formAnnulationMotif = $('#formTrajetSingleAnnulationMotif');
                    $formAnnulationCommentaire = $('#formTrajetSingleAnnulationCommentaire');

                    showContextMenuSingle($trigger);
                } else if ($formBatch) {
                    $form = $formBatch;
                    $formAction = $('#formBatchTrajetAction');
                    $formAnnulationMotif = $('#formBatchTrajetAnnulationMotif');
                    $formAnnulationCommentaire = $('#formBatchTrajetAnnulationCommentaire');

                    showContextMenuBatch();
                }

                return {
                    items: items
                };
            },
            events: {
                hide: () => {
                    $('#trajets tr.focus').removeClass('focus');
                }
            }
        });

        function showContextMenuSingle(e) {
            let id = e.data('id');
            let idTransport = e.data('transportId');

            let roleSerieNew = e.data('roleSerieNew');
            let roleSerieEdit = e.data('roleSerieEdit');
            let roleNew = e.data('roleNew');

            let $formId = $('#formSingleTrajetId');
            $formId.val(id);

            let roleView = e.data('roleView');
            let roleEdit = e.data('roleEdit');
            if (roleView) {
                showViewItem(idTransport);
            }
            if (roleEdit) {
                showEditItem(idTransport);
            }

            let estAnnule = e.data('estAnnule');
            const motifsAnnulation = e.data('motifsAnnulation');
            showAnnuleItem(estAnnule, motifsAnnulation);

            let nePasFacturer = e.data('nePasFacturer');
            showNePasFacturerItem(nePasFacturer);

            let estControle = e.data('estControle');
            showControleItem(estControle);

            let canPrecedent = e.data('canPrecedent');
            let canSuivant = e.data('canSuivant');

            if (roleEdit && App.Constants.APPLICATION === 'regulation') {
                items.sep3 = '';
                showTrajetEtatItems(canPrecedent, canSuivant);
            }

            let idSerie = e.data('serie');
            let idSerieDefined = (idSerie !== '' && idSerie !== undefined);
            if (!idSerieDefined && roleSerieNew) {
                items.sep4 = '';
                showNewSerieItem(idTransport)
            } else if (idSerieDefined && roleSerieEdit) {
                items.sep4 = '';
                showEditSerieItem(idSerie)
            }

            if (roleNew) {
                items.sep5 = '';
                showDuplicateItem(idTransport)
            }
        }

        function showContextMenuBatch() {
            const selectedItems = $('#trajets .ui-selected');
            const count = selectedItems.length;
            const firstElement = selectedItems.first();

            if (count > 1) {
                items.trajets = {
                    name: count + ' trajets sélectionnés',
                    disabled: true,
                    icon: 'fa-solid fa-cubes'
                };

                items.sep1 = '';
            } else {
                const idTransport = firstElement.data('transportId');
                const type = firstElement.data('type');
                const roleView = firstElement.data('roleView');
                const roleEdit = firstElement.data('roleEdit');
                const roleNew = firstElement.data('roleNew');

                if (roleView) {
                    showViewItem(idTransport, type);
                }
                if (roleEdit) {
                    showEditItem(idTransport);
                }
                if (roleNew) {
                    showDuplicateItem(idTransport)
                }
            }

            let canFacturer = (selectedItems.filter(item => { return selectedItems[item].dataset['facturable'] === '' })).length === 0;
            showFacturerItems(canFacturer);

            showNePasFacturerItem(null);
            showControleItem(null);

            let motifsAnnulation = firstElement.data('motifsAnnulation');

            if (count > 1) {
                selectedItems.each((i, el) => {
                    const motifsAnnulation2 = $(el).data('motifsAnnulation');

                    if (JSON.stringify(motifsAnnulation2) !== JSON.stringify(motifsAnnulation)) {
                        motifsAnnulation = null;
                        return false;
                    }
                });
            }

            showAnnuleItem(null, motifsAnnulation);
        }

        function showViewItem(idTransport, type = null) {
            items.view = {
                name: Translator.trans('action.voir'),
                icon: 'fa-solid fa-eye',
                callback: (item, options, e) => handleMenuLink(Router.generate('shared.transport.view', {
                    app: App.Constants.APPLICATION,
                    id: idTransport,
                    trajet: type,
                }), e)
            };
        }
        function showEditItem(idTransport) {
            items.edit = {
                name: Translator.trans('action.modifier'),
                icon: 'fa-solid fa-pencil',
                callback: (item, options, e) => handleMenuLink(Router.generate('shared.transport.edit', {
                    app: App.Constants.APPLICATION,
                    id: idTransport,
                }), e)
            };
        }
        function showAnnuleItem(estAnnule, motifsAnnulation) {

            if (items.edit || items.view || null === estAnnule) {
                items.sepAnnule = '';
            }

            if (estAnnule || null === estAnnule) {
                items.reactiver = {
                    name: Translator.trans('action.reactiver'),
                    icon: 'fa-regular fa-circle-check',
                    callback: () => {
                        $formAction.val('non_annule');
                        $form.submit();
                    }
                };
            }

            if (!estAnnule) {
                items.annuler = {
                    name: Translator.trans('action.annuler'),
                    icon: 'fa-regular fa-circle-xmark',
                    disabled: !motifsAnnulation,
                };

                if (motifsAnnulation) {
                    if (motifsAnnulation.motifs.length) {
                        items.annuler.items = [];
                        if (!motifsAnnulation.requis) {
                            items.annuler.items.push({
                                name: 'Sans motif',
                                callback: () => {
                                    $formAction.val('annule');
                                    $form.submit();
                                },
                            });
                            items.annuler.items.push('');
                        }
                        for (const motif of motifsAnnulation.motifs) {
                            items.annuler.items.push({
                                name: motif.libelle,
                                callback: motif.modeCommentaire === 0 ? () => {
                                    $formAction.val('annule');
                                    $formAnnulationMotif.val(motif.id);
                                    $form.submit();
                                } : () => {
                                    App.Utils.openVueSwal(AnnulationTrajet, {
                                        motif,
                                        onData: (data) => {
                                            $formAnnulationMotif.val(motif.id);
                                            $formAnnulationCommentaire.val(data.commentaire);
                                            $formAction.val('annule');
                                            $form.submit();
                                        }
                                    }, '600px');
                                },
                            });
                        }
                    }
                    else if (!motifsAnnulation.requis) {
                        items.annuler.callback = () => {
                            $formAction.val('annule');
                            $form.submit();
                        };
                    }
                }
            }
        }
        function showControleItem(estControle) {
            if (estControle === null) {
                items.sepControle = '';
                items.controle = {
                    name: Translator.trans('action.marquer-controle'),
                    icon: 'fa-regular fa-square-check',
                    callback: () => {
                        $formAction.val('controle');
                        $form.submit();
                    }
                };
                items.non_controle = {
                    name: Translator.trans('action.marquer-non-controle'),
                    icon: 'fa-regular fa-square',
                    callback: () => {
                        $formAction.val('non_controle');
                        $form.submit();
                    }
                };
            } else {
                items.controle = {
                    name: estControle ? Translator.trans('action.marquer-non-controle') : Translator.trans('action.marquer-controle'),
                    icon: estControle ? 'fa-regular fa-square' : 'fa-regular fa-square-check',
                    callback: () => {
                        $formAction.val('controle');
                        $form.submit();
                    }
                };
            }
        }
        function showNePasFacturerItem(nePasFacturer) {
            if (nePasFacturer === null) {
                items.sep2 = '';
                items.aFacturer = {
                    name: Translator.trans('action.a-facturer'),
                    icon: 'fa-solid fa-check',
                    callback: () => {
                        $formAction.val('a_facturer');
                        $form.submit();
                    }
                };
                items.nePasFacturer = {
                    name: Translator.trans('action.ne-pas-facturer'),
                    icon: 'fa-solid fa-xmark',
                    callback: () => {
                        $formAction.val('ne_pas_facturer');
                        $form.submit();
                    }
                };
            } else {
                items.nePasFacturer = {
                    name: nePasFacturer ? Translator.trans('action.autoriser-facturation') : Translator.trans('action.ne-pas-facturer'),
                    icon: nePasFacturer ? 'fa-solid fa-check' : 'fa-solid fa-xmark',
                    callback: () => {
                        $formAction.val('ne_pas_facturer');
                        $form.submit();
                    }
                };
            }

        }
        function showFacturerItems(canFacturer) {
            if (items.view || items.edit)  {
                items.sep1 = '';
            }

            if (canFacturer) {
                if (params && params.vm) {
                    items.ajouterTrajetPanier = {
                        name: 'Ajouter au panier', icon: 'fa-solid fa-plus', callback: () => {
                            let $selectedItems = $('#trajets .ui-selected');
                            $selectedItems.each((i, item) => {
                                const $item = $(item);
                                if ($item.data('facturable')) {
                                    params.vm.add($item.data('id'), $item.data('tarification'), $item.data('societe'));
                                }
                            })
                        }
                    };
                }

                items.facturerTrajetStandard = {
                    name: Translator.trans('libelle.facturer-standard'),
                    icon: 'fa-regular fa-file-invoice',
                    callback: function () {
                        $formAction.val('facturer_trajet_standard');
                        $form.submit();
                    }
                };

                items.facturerTrajetLibre = {
                    name: Translator.trans('libelle.facturer-libre'),
                    icon: 'fa-regular fa-file-invoice',
                    callback: function () {
                        $formAction.val('facturer_trajet_libre');
                        $form.submit();
                    }
                };

                items.sepFacturer = '';

                items.facturerTransport = {
                    name: Translator.trans('libelle.aller-retour'), icon: 'fa-solid fa-euro-sign', items: []
                }

                if (params && params.vm) {
                    items.facturerTransport.items.push({
                        name: 'Ajouter au panier', icon: 'fa-solid fa-plus', callback: () => {
                            let $selectedItems = $('#trajets .ui-selected');
                            $selectedItems.each((i, item) => {
                                const $item = $(item);
                                if ($item.data('facturable')) {
                                    params.vm.add($item.data('id'), $item.data('tarification'), $item.data('societe'));
                                    if ($item.data('opposeId')) {
                                        params.vm.add($item.data('opposeId'), $item.data('opposeTarification'), $item.data('opposeSociete'));
                                    }

                                }
                            })
                        }
                    })
                }

                items.facturerTransport.items.push(
                    {
                        name: Translator.trans('libelle.facturer-standard'),
                        icon: 'fa-regular fa-file-invoice',
                        callback: function () {
                            $formAction.val('facturer_transport_standard');
                            $form.submit();
                        }
                    },
                    {
                        name: Translator.trans('libelle.facturer-libre'),
                        icon: 'fa-regular fa-file-invoice',
                        callback: function () {
                            $formAction.val('facturer_transport_libre');
                            $form.submit();
                        }
                    }
                );
            } else {
                items.nonFacturable = {
                    name: Translator.trans('action.non-facturable'),
                    icon: 'fa-solid fa-euro-sign',
                    disabled: true,
                };
            }
        }
        function showTrajetEtatItems(canPrecedent, canSuivant) {
            items.etatPrecedent = {
                name: Translator.trans('action.etat-precedent'),
                icon: 'fa-solid fa-backward-step',
                disabled: !canPrecedent,
                callback: () => {
                    $formAction.val('etat_precedent');
                    $form.submit();
                }
            };
            items.etatSuivant = {
                name: Translator.trans('action.etat-suivant'),
                icon: 'fa-solid fa-forward-step',
                disabled: !canSuivant,
                callback: () => {
                    $formAction.val('etat_suivant');
                    $form.submit();
                }
            };
            items.terminerMission = {
                name: Translator.trans('action.terminer-mission'),
                icon: 'fa-solid fa-forward-fast',
                disabled: !canSuivant,
                callback: () => {
                    $formAction.val('terminer');
                    $form.submit();
                }
            };
        }
        function showNewSerieItem(idTransport) {
            items.newSerie = {
                name: Translator.trans('action.creer-serie'),
                icon: 'fa-regular fa-calendar-plus',
                callback: (item, options, e) => handleMenuLink(Router.generate('shared.serie.new', {
                    app: App.Constants.APPLICATION,
                    base: idTransport
                }), e)
            };
        }
        function showEditSerieItem(idSerie) {
            items.editSerie = {
                name: Translator.trans('action.modifier-serie'),
                icon: 'fa-regular fa-calendar-pen',
                callback: (item, options, e) => handleMenuLink(Router.generate('shared.serie.edit', {
                    app: App.Constants.APPLICATION,
                    id: idSerie
                }), e)
            };
        }
        function showDuplicateItem(idTransport) {
            items.duplicate = {
                name: Translator.trans('action.dupliquer'),
                icon: 'fa-regular fa-clone',
                callback: (item, options, e) => handleMenuLink(
                    Router.generate('shared.transport.duplicate', {app: App.Constants.APPLICATION, base: idTransport}), e
                )
            };
        }

        function handleMenuLink(url, e) {
            if (e.which === 1 && !e.ctrlKey) {
                window.location.href = url;
            }
            else if (e.which === 2 || (e.which === 1 && e.ctrlKey)) {
                let win = window.open(url, '_blank');
                if (win) win.focus();
            }
        }
    }
};
