<template>
    <slot name="activeFeature" :feature="feature" :image="image" v-if="feature" :key="getFeatureKey(feature)"></slot>
    <slot></slot>
</template>

<script setup>
import {inject, onMounted, ref, toRefs, watch, computed, onUnmounted} from "vue";

const map = inject('map')

const emit = defineEmits(['update:activeFeature'])
const props = defineProps({
    source: String,
    visible: {default: true, type: Boolean},
    selectable: {default: false, type: Boolean},
    oldFilterStyle: Boolean,
    filter: Object,
    activeFeature: Object,
});
const {source, visible, selectable, oldFilterStyle, filter: filterInput, activeFeature} = toRefs(props);

const feature = ref(activeFeature.value);
const image = ref();

// todo move to map
const getIcon = (id) => {
    if (!id) return null;

    const icon = map.value.getImage(id);

    const width = icon.data.width;
    const height = icon.data.height;
    const imageData = new ImageData(new Uint8ClampedArray(icon.data.data), width, height);

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;

    const context = canvas.getContext('2d');
    context.putImageData(imageData, 0, 0);

    return canvas.toDataURL();
}

const layers = computed(() => map.value.getStyle().layers.filter(layer => layer.source === source.value));

const updateVisibility = () => {
    for (const layer of layers.value) {
        map.value.setLayoutProperty(layer.id, 'visibility', visible.value ? 'visible' : 'none');
    }
};

watch (visible, () => updateVisibility(visible.value), {immediate: true});

const onMapClick = (e) => {
    if (!e.defaultPrevented) {
        feature.value = null;
    }
};
const layerHandlers = {
    click: {},
    mouseenter: {},
    mouseleave: {},
};

const getFeatureKey = (feature2) => feature2.properties.id ?? feature2.properties.name;

onMounted( () => {
    map.value.on('styledata', updateVisibility);

    if (selectable.value) {
        for (const layer of layers.value.filter(layer => true === selectable.value || selectable.value.includes(layer['source-layer']))) {
            const clickHandler = (e) => {
                const coordinates = e.features[0].geometry.coordinates.slice();

                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                if (feature.value && getFeatureKey(feature.value) === getFeatureKey(e.features[0])) {
                    feature.value = null;
                } else {
                    feature.value = e.features[0];
                    image.value = getIcon(feature.value.properties.icon ? feature.value.properties.icon + '_pin' : feature.value.layer.layout['icon-image']);
                }

                e.preventDefault();
            };

            map.value.on('click', layer.id, clickHandler);
            layerHandlers.click[layer.id] = clickHandler;

            const mouseEnterHandler = () => {
                map.value.getCanvas().style.cursor = 'pointer';
            };
            map.value.on('mouseenter', layer.id, mouseEnterHandler);
            layerHandlers.mouseenter[layer.id] = mouseEnterHandler;

            const mouseleaveHandler = () => {
                map.value.getCanvas().style.cursor = '';
            };
            map.value.on('mouseleave', layer.id, mouseleaveHandler);
            layerHandlers.mouseleave[layer.id] = mouseleaveHandler;

        }

        map.value.on('click', onMapClick);
    }
});

onUnmounted(() => {
    map.value.off('styledata', updateVisibility);
    map.value.off('click', onMapClick);
    for (const type in layerHandlers) {
        for (const layer in layerHandlers[type]) {
            map.value.off(type, layer, layerHandlers[type][layer]);
        }
    }
});

watch(feature, (newFeature, oldFeature) => {
    if (oldFeature !== newFeature) {
        if (oldFeature) {
            const filter = map.value.getFilter(oldFeature.layer.id);
            map.value.setFilter(oldFeature.layer.id, filter[1]);
        }
        if (newFeature) {
            const filter = map.value.getFilter(newFeature.layer.id);
            const field = newFeature.properties.id ? 'id' : 'name';
            map.value.setFilter(newFeature.layer.id, ['all', filter, ['!=', oldFilterStyle.value ? field : ['get', field], getFeatureKey(newFeature)]]);
        }
    }

    if (!feature.value) {
        image.value = null;
    }

    emit('update:activeFeature', feature.value);
});

watch(activeFeature, () => feature.value = activeFeature.value);

const filter = computed(() => {
    const newFilter = {...filterInput.value ?? {}};

    if (feature.value) {
        const field = feature.value.properties.id ? 'id' : 'name';
        const featureFilter = ['!=', oldFilterStyle.value ? field : ['get', field], getFeatureKey(feature.value)];
        const layer = feature.value.layer.id;

        if (layer in newFilter) {
            newFilter[layer] = ['all', newFilter[layer], featureFilter];
        } else {
            newFilter[layer] = featureFilter;
        }
    }

    return newFilter;
});

watch (filter, (newFilter, oldFilter) => {
    if (oldFilter) {
        for(const layer in oldFilter) {
            const layerFilter = map.value.getFilter(layer);
            map.value.setFilter(layer, layerFilter[1]);
        }
    }

    if (newFilter) {
        for(const layer in newFilter) {
            const layerFilter = map.value.getFilter(layer);
            map.value.setFilter(layer, ['all', layerFilter, newFilter[layer]]);
        }
    }
}, {immediate: true});

// unmount
</script>