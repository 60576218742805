<template>
    <Base :height="height" :hasMap="hasMap" :data="data" :error="error" @refresh="refresh">
        <Layer @select-vehicule="emit('select-vehicule', $event)"></Layer>
    </Base>
</template>

<script setup>
import {computed, onMounted, provide, ref, toRefs, watch} from "vue";
import Layer from "@/Vue/Components/Trajet/Map/Layer.vue";
import Base from "@/Vue/Components/Trajet/Map/Base.vue";
import Router from '@/App/Router';
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const emit = defineEmits(['select-vehicule']);
const props = defineProps(['height', 'structures', 'id']);

const {height, structures, id} = toRefs(props);

const hasMap = computed(() => data.value && (data.value.routesGeoJSON !== null || data.value.appareils.length));

const positions = ref([]);
const data = ref(null);
const error = ref(false);

const refresh = () => {
    data.value = null;
    error.value = false;
    getJson(Router.generate('regulation.trajet.map', {id: id.value})).then((res) => {
        data.value = res;
    }, () => {
        error.value = true;
    });
};

provide('positions', positions);
provide('structures', structures);
provide('data', data);

watch(id, () => refresh(), {immediate: true});

onMounted(() => {
    if(structures.value) {
        App.webSocket.subscribe('shared/position', (uri, data) => {
            positions.value = data.positions;
        });
        App.webSocket.publish('shared/position', {
            structureCollection: structures.value,
        });
    }
})
</script>
