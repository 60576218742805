<template>
    <template v-if="trajet">
        <div class="form-group" v-if="form">
            <div class="input-group" style="height: 30px;">
                <div class="input-sm d-none"></div>
                <div class="input-group-prepend">
                    <span class="input-group-text" :title="Translator.trans('libelle.geolocalisationCertifiee')">Géoloc.</span>
                </div>
                <div class="border d-flex align-items-center px-2">
                    <span class="badge" :title="niveauCertificationGeolocalisationTitle(trajet._niveauCertificationGeolocalisation)" :class="badgeClass">Niveau {{ trajet._niveauCertificationGeolocalisation }}</span>
                    <a href="#" class="badge badge-light text-nowrap" v-if="trajet._kilometreCertifie" title="Reporter la distance certifiée" @click.prevent="$emit('changes', {kilometre: trajet._kilometreCertifie})"><i class="fa-solid fa-fw fa-medal"></i> {{ trajet._kilometreCertifie }} km</a>
                    <a href="#" class="badge badge-light text-nowrap" v-else-if="trajet.kilometreCalcule" title="Reporter la distance calculée" @click.prevent="$emit('changes', {kilometre: trajet.kilometreCalcule})"><i class="fa-solid fa-fw fa-map"></i> {{ trajet.kilometreCalcule }} km</a>
                </div>
            </div>
        </div>
        <div v-else class="d-flex mt-2 flex-wrap justify-content-center">
            <span class="badge badge-secondary" :title="Translator.trans('libelle.geolocalisationCertifiee')">Géoloc.</span>
            <span class="badge" :title="niveauCertificationGeolocalisationTitle(trajet._niveauCertificationGeolocalisation)" :class="badgeClass">Niveau {{ trajet._niveauCertificationGeolocalisation }}</span>
            <span class="badge badge-secondary text-nowrap" v-if="trajet._kilometreCertifie" title="Distance certifiée"><i class="fa-solid fa-fw fa-medal"></i> {{ trajet._kilometreCertifie }} km</span>
            <span class="badge badge-secondary text-nowrap" v-else-if="trajet.kilometreCalcule" title="Distance calculée"><i class="fa-solid fa-fw fa-map"></i> {{ trajet.kilometreCalcule }} km</span>
        </div>
    </template>
</template>

<script setup>
import Translator from "@/App/Translator";
import {computed, toRefs} from "vue";

const props = defineProps({
    trajet: Object,
    origin: String,
    standard: Object,
    form: Boolean,
});

const {trajet, form} = toRefs(props);

const niveauCertificationGeolocalisationTitle = (niveau) => {
    switch (niveau) {
        case 1: return 'Données GPS manquantes ou insuffisantes';
        case 2: return 'Véhicule facturé différent du véhicule géolocalisé';
        case 3: return 'Assuré facturé différent de l\'assuré initial';
        case 4: return 'Personnel facturé différent du personnel initial';
        case 5: return 'Certifié';
    }

    return '';
};

const badgeClass = computed(() => {
    if (trajet.value._estTarificationStandardMajoree && trajet.value._niveauCertificationGeolocalisation >= 3) {
        return 'badge-success';
    }

    return 'badge-info';
});

</script>
