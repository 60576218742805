'use strict';

import {createApp} from "vue";
import NotificationMenu from '@/Vue/Components/Notification/Menu';

App.Notifications = class {
    static init() {
        const notifications = document.getElementById('notifications');

        if (notifications) {
            createApp(NotificationMenu, {
                initData: JSON.parse(notifications.dataset.data),
            }).use(App.Pinia).mount(notifications);
        }
    }
}
