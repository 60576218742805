'use strict';

import Router from "@/App/Router";
import {createApp} from "vue";
import CpsMenu from "@/Vue/Components/Cps/Menu.vue";

App.Cps = class {
    static init() {
        const cpsMenu = document.getElementById('cpsMenu');

        if (cpsMenu) {
            createApp(CpsMenu, {
                cpsData: JSON.parse(cpsMenu.dataset.data),
            }).use(App.Pinia).mount(cpsMenu);
        }
    }
}

App.Cps.Link = class {
    constructor(params) {
        $('#confirmBtn').click(() => {
            $('#confirm').hide();
            $('#pending').show();
            $('#error').hide();

            $.ajax({
                type: 'POST',
                url: Router.generate('cps.link.confirm', {serial: params.serial, token: params.token}),
            }).done((data) => {
                $('#pending').hide();

                if(data.success) {
                    $('#success').show();
                } else {
                    $('#error').show();
                    $('#errorText').text(data.error);
                    $('#confirm').show();
                }
            }).fail(() => {
                $('#error').show();
                $('#errorText').text('Veuillez réessayer');
            });
        })
    }
}

App.Cps.Add = class {
    constructor(params) {
        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                oses: {
                    win: {
                        label: 'Windows',
                        icon: 'fa-brands fa-windows text-info'
                    },
                    mac: {
                        label: 'MacOS',
                        icon: 'fa-brands fa-apple text-dark',
                    },
                    linux: {
                        label: 'Linux',
                        icon: 'fa-brands fa-linux text-warning'
                    }
                },
                clients: params.clients,
                baseUri: params.baseUri,
                hasCpsClient: null,
                installDone: null,
                selectedOs: null,
            }),
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));
            },
        }).mount('#app');
    }
}
