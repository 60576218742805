'use strict';

import {createApp} from 'vue'
import moment from "moment";
import Ladda from "ladda";
import swal from "sweetalert2";
import Cookies from "js-cookie";
import _ from 'lodash';
import Duree from "@/Vue/Filters/Duree";
import Translator from "@/App/Translator";
import {usePaieStore} from "@/Vue/Stores/Paie";
import {mapStores} from "pinia";
import {useRequest} from "@/Vue/Composables/Request";
import Request from "@/App/Request";
import Router from "@/App/Router";

App.Salaries.Paie = class {
    constructor(params) {
        moment.locale('fr');

        createApp({
            delimiters: ['[[', ']]'],
            setup: () => {
                const {getJson, postJson} = useRequest();

                return {getJson, postJson, Router};
            },
            data: () => ({
                initStructures: false,
                modeles: [],
                structures: [],
                personnelsFilter: [],
                personnels: [],
                activePersonnel: null,
                recapitulatifCollection: [],
                roles: {},
                editEnabled: false,
                saving: false,
                loadingPersonnel: null,
                lieux: {},
                unit: params.unite,
                exportType: 'pdf',
                exportMode2: 'recap',
                exportModeSelected: null,
                periode: {
                    debut: null,
                    fin: null,
                },
                exportBtnDisabled: false,
            }),
            watch: {
                editEnabled: function(edit) {
                    $('#form_structureCollection').toggleClass('disabled', edit);
                    $('#form_personnelCollection').toggleClass('disabled', edit);

                    if (edit) {
                        this.paieStore.enablePaieHistory();
                    }
                },
                structures() {
                    this.initStructures = true;
                    this.onFormChange();
                },
                personnelsFilter() {
                    this.onFormChange();
                },
                personnels: function(personnels) {
                    if (this.activePersonnel) {
                        this.activePersonnel = this.personnelsById[this.activePersonnel.id] ?? null;
                    }
                },
                periode: {
                    handler: function() {
                        this.reloadPersonnel();
                    },
                    deep: true,
                },
                unit: function(unite) {
                    this.getJson(params.uniteDureeUrl.replace('_UNITE_', unite));
                }
            },
            computed: {
                ...mapStores(usePaieStore),
                personnelsById() {
                    return this.personnels.reduce((res, personnel) => (res[personnel.id] = personnel, res), {});
                },
                exportModes: function() {
                    let modes = {all: 'Tous les personnels'};

                    if(this.activePersonnel) {
                        modes.active = this.activePersonnel.text + ' uniquement';
                    }
                    else if('active' === this.exportModeSelected) {
                        this.exportModeSelected = null;
                    }

                    return modes;
                },
                exportMode: {
                    get: function() {
                        if(null === this.exportModeSelected) {
                            return this.activePersonnel ? 'active' : 'all';
                        }

                        return this.exportModeSelected;
                    },
                    set: function(mode) {
                        this.exportModeSelected = mode;
                    }
                },
                emptyTypes: function() {
                    return !Object.keys(this.types).length;
                },
                types() {
                    return this.paieStore.types;
                },
                recapitulatifs: function() {
                    return _.sortBy(this.recapitulatifCollection, ['annee', 'semaine']);
                },
                totalRecapitulatifs: function() {
                    const totals = {};

                    for (const recapitulatif of this.recapitulatifs) {
                        for (const field of [
                            'tempsTravail',
                            'tempsTravailEffectif',
                            'tempsTravailEffectifNuit',
                            'heureBase',
                            'heureNormale',
                            'congeHeureNormaleReel',
                            'heureSupplementaire25',
                            'heureSupplementaire50',
                            'jourFerieTravaille',
                            'reposCompensateur',
                            'reposCompensateurConsomme',
                            'reposCompensateurNuit',
                            'reposCompensateurNuitConsomme',
                            'indemniteDepassementAmplitudeJournaliere75',
                            'indemniteDepassementAmplitudeJournaliere',
                            'indemniteDimancheJourFerie',
                            'indemniteCasseCroute',
                            'indemniteChambrePetitDejeuner',
                            'indemniteRepas',
                            'indemniteRepasUnique',
                            'indemniteReposJournalierChambreCasseCroute',
                            'indemniteSpeciale',
                            'indemniteSpecialePetitDejeuner',
                            'penibiliteNuit',
                        ]) {
                            if (!(field in totals)) {
                                totals[field] = null;
                            }

                            if (null !== recapitulatif[field]) {
                                totals[field] = (totals[field] || 0) + recapitulatif[field];
                            }
                        }

                        for (const field of [
                            'congeByHashCollection',
                            'indemnitePersonnaliseeByHashCollection',
                        ]) {
                            if (!(field in totals)) {
                                totals[field] = {};
                            }
                            for (const hash in recapitulatif[field]) {
                                totals[field][hash] = (totals[field][hash] || 0) + recapitulatif[field][hash];
                            }
                        }
                    }

                    return totals;
                },
                hasIDAJ75: function () {
                    for (let type of this.types) {
                        if (type.genereIndemniteDepassementAmplitudeJournaliere75) {
                            return true;
                        }
                    }

                    return false;
                },
                typesByYearAndWeek: function() {
                    let sortedTypes = this.types.sort((a,b) => {
                        return (a._date.isAfter(b._date)) ? 1 : ((b._date.isAfter(a._date)) ? -1 : 0);
                    });

                    let types = {};

                    for(let type of sortedTypes) {
                        let annee = type._date.format('YYYY');
                        let mois = type._date.format('MM');
                        let semaine = type._date.format('WW');

                        // To be really compliant with ISO 8601 (Y doesn't work).
                        if ('12' === mois && '01' === semaine) {
                            annee = parseInt(annee) + 1;
                        } else if ('01' === mois && '53' === semaine) {
                            annee = parseInt(annee) - 1;
                        }

                        if(!(annee in types)) {
                            types[annee] = {};
                        }
                        if(!(semaine in types[annee])) {
                            types[annee][semaine] = [];
                        }
                        types[annee][semaine].push(type);
                    }

                    return types;
                },
                debutInput: {
                    get: function() {
                        return this.periode.debut ? this.periode.debut.format('DD/MM/YYYY') : '';
                    },
                    set: function(value) {
                        this.dateInputSetter('debut', value)
                    }
                },
                finInput: {
                    get: function() {
                        return this.periode.fin ? this.periode.fin.format('DD/MM/YYYY') : '';
                    },
                    set: function(value) {
                        this.dateInputSetter('fin', value)
                    }
                },
                recapitulatifColumns: function() {
                    let congeCollection = {};
                    let indemniteCollection = {};

                    for(let recapitulatif of this.recapitulatifs) {
                        for(let element of recapitulatif.elementCollection) {
                            if(element.type === 0) {
                                congeCollection[element.hash] = [element.abreviation, element.libelle];
                            }
                            if(element.type === 1) {
                                indemniteCollection[element.hash] = [element.abreviation, element.libelle];
                            }
                        }
                    }

                    return { congeCollection, indemniteCollection };
                }
            },
            beforeMount: function() {
                this.periode = {
                    debut: moment(params.debut),
                    fin: moment(params.fin),
                };

                this.lieux = params.lieux;

                let treeSelectInputSelector = 'input[name="form[structureCollection][]"]';
                $('body').on('change', treeSelectInputSelector, (e) => {
                    let $checkedInputs = $(treeSelectInputSelector + ':checked');

                    this.structures = $.map($checkedInputs, function(el){return el.value; });
                }).one('treeselect.init', () => {
                    let $checkedInputs = $(treeSelectInputSelector + ':checked');
                    this.structures = $.map($checkedInputs, el =>  el.value);
                }).on('change', 'select#form_structureCollection', (e) => {
                    this.structures = $(e.currentTarget).val();
                }).on('change', '#form_personnelCollection', (event) => {
                    this.personnelsFilter = $(event.currentTarget).val();
                });

                this.personnelsFilter = $('#form_personnelCollection').val() || [];
            },
            mounted() {
                document.dispatchEvent(new Event('app:vuejs:mounted'));
            },
            methods: {
                Duree,
                onFormChange() {
                    if(!this.initStructures) {
                        return;
                    }

                    if (!this.structures.length && !this.personnelsFilter.length) {
                        this.modeles = [];
                        this.personnels = [];
                        this.paieStore.setTypesJson(JSON.stringify([]));
                        this.paieStore.emptyState();
                    }

                    this.reload(true);
                },
                jourSemaine(jour) {
                    return moment(jour, 'DD/MM/YYY').format('ddd');
                },
                doExport(event, printMode) {
                    if(!this.exportType || !this.exportMode || !this.exportMode2) {
                        return false;
                    }

                    let ladda = Ladda.create(event.currentTarget);
                    ladda.start();

                    this.exportBtnDisabled = true;

                    let formData = {
                        export_paie: {
                            personnel: 'active' === this.exportMode ? this.activePersonnel.id : null,
                            structureCollection: 'all' === this.exportMode ? this.structures : [],
                            type: printMode ? 'html' : this.exportType,
                            mode: this.exportMode2,
                            debut: this.debutInput,
                            fin: this.finInput,
                        }
                    };
                    let url = params.exportUrl + '?' + $.param(formData);

                    if(printMode) {
                        App.Utils.print(url).then(() => {
                            ladda.stop();
                            this.exportBtnDisabled = false;
                        });
                    } else {
                        App.Utils.download(url).then(() => {
                            this.exportBtnDisabled = false;
                            ladda.stop();
                        });
                    }
                },
                fixTypeDates(type) {
                    this.$nextTick(() => {
                        let baseDate = moment(type.debutPrevu.date, 'DD/MM/YYYY');

                        for(let field of ['Prevu', 'Reel']) {
                            let debutTime = moment(type['debut'+field].time, 'HH:mm');
                            let finTime = moment(type['fin'+field].time, 'HH:mm');

                            type['debut'+field].date = debutTime.isValid() ? baseDate.format('DD/MM/YYYY') : '';
                            type['fin'+field].date = finTime.isValid() ? baseDate.format('DD/MM/YYYY') : '';

                            if(debutTime.isValid() && finTime.isValid()) {
                                type['fin'+field].date = (finTime.isSameOrBefore(debutTime) ? baseDate.clone().add(1, 'day') : baseDate).format('DD/MM/YYYY');
                            }
                        }
                    });
                },
                addModele(modele) {
                    swal({
                        html: '<div class="planning"><h2><div class="event event-end d-inline-block ml-0 pl-3 pr-4" style="color: #' + modele.couleurTexte + ';background-color: #' + modele.couleur +';">' + modele.code + '</div> ' +modele.libelle+'</h2><div class="d-flex justify-content-center mt-5"><input type="text" class="d-none js-datetimepicker" data-today-button="false" data-format="DD/MM/YYYY" data-inline="true" data-min-date="' +this.debutInput+'" data-max-date="' +this.finInput+'" id="newTypeDate"></div>',
                        showCancelButton: true,
                        cancelButtonText: Translator.trans('action.annuler'),
                        onOpen: () => {
                            $(swal.getContent()).removeClass('swal2-content');
                            App.Layout.enableCustomInputs();
                            const $confirmButton = $(swal.getConfirmButton());
                            $('#newTypeDate').change(() => $confirmButton.prop('disabled', false));
                            $confirmButton.prop('disabled', true);
                        },
                    }).then((result) => {
                        if (result.value) {
                            let date = moment($('#newTypeDate').val(), 'DD/MM/YYYY');
                            let type = App.Salaries.createType(modele, this.activePersonnel.id, date);

                            App.Salaries.openTypeSwal(type, params.formTypeUrl.replace('_TYPE_', type.type), true, this.modeles, (data) => {
                                type = Object.assign(type, data);

                                this.paieStore.addType(type);
                                this.$nextTick(() => App.Layout.enableCustomInputs().enableSelect2());
                            });
                        }
                    });
                },
                refresh(event) {
                    if(!this.activePersonnel || !this.periode.debut || !this.periode.fin) {
                        return false;
                    }

                    let ladda = Ladda.create(event.currentTarget);
                    ladda.start();

                    this.postJson(params.personnelRecapitulatifUrl, {
                        paie_personnel: {
                            personnel: this.activePersonnel.id,
                            debut: this.debutInput,
                            fin: this.finInput,
                        }
                    }).then((data) => {
                        this.recapitulatifCollection = data.recapitulatifCollection || [];
                        ladda.stop();
                    }, (response) => {
                        ladda.stop();
                    });
                },
                removeType(type) {
                    this.paieStore.removeType(type._index);
                },
                edit() {
                    this.editEnabled = true;
                },
                cancel() {
                    this.editEnabled = false;
                    this.paieStore.emptyState();
                },
                save(event) {
                    this.saving = true;
                    this.done = [];

                    let ladda = Ladda.create(event.currentTarget);
                    ladda.start();

                    let data = [];
                    for(let type of this.types) {
                        data.push(App.Salaries.normalizeType(type));
                    }

                    Request.postJson(params.personnelUpdateUrl, {
                        paie_personnel: {
                            personnel: this.activePersonnel.id,
                            debut: this.debutInput,
                            fin: this.finInput,
                            data
                        }
                    }).then((data) => {
                        this.paieStore.setTypesJson(JSON.stringify(data.typeAbstractCollection || []));
                        this.recapitulatifCollection = data.recapitulatifCollection || [];
                        this.paieStore.emptyState();

                        ladda.stop();
                        this.saving = false;
                        this.editEnabled = false;
                    }, (response) => {
                        ladda.stop();
                        this.saving = false;
                    });
                },
                editType(type) {
                    App.Salaries.openTypeSwal(type, params.formTypeUrl.replace('_TYPE_', type.type), true, this.modeles, (data) => {
                        type = Object.assign(type, data);
                        this.$nextTick(() => App.Layout.enableCustomInputs().enableSelect2());
                    });
                },
                viewType(type) {
                    App.Salaries.openTypeSwal(type, params.viewTypeUrl.replace('_ID_', type.id), false, this.modeles);
                },
                dureeType(type) {
                    let debut = moment(type.debutReel.date ? type.debutReel.date+' '+type.debutReel.time : type.debutPrevu.date+' '+type.debutPrevu.time, 'DD/MM/YYYY HH:mm');
                    let fin = moment(type.finReel.date ? type.finReel.date+' '+type.finReel.time : type.finPrevu.date+' '+type.finPrevu.time, 'DD/MM/YYYY HH:mm');
                    let duree = debut.isValid() && fin.isValid() && debut.isBefore(fin) ? fin.diff(debut, 'minute') : null;

                    return Duree(duree, this.unit);
                },
                rowspanType(type) {
                    let rowspan = 1;

                    if(type.pauseCollection) {
                        rowspan += type.pauseCollection.length;
                    }
                    if(type.tacheCollection) {
                        rowspan += type.tacheCollection.length;
                    }

                    return rowspan;
                },
                typeChildren(type) {
                    return [].concat(type.pauseCollection || [], type.tacheCollection || []);
                },
                reload(withModeles) {
                    this.postJson(params.url, {
                        paie: {
                            structureCollection: this.structures,
                            personnelCollection: this.personnelsFilter,
                            withModeles
                        }
                    }).then((data) => {
                        this.personnels = data.personnels || [];
                        if(data.modeles) {
                            this.modeles = data.modeles;
                        }

                        if (this.personnelsFilter.length && this.personnels.length && !this.activePersonnel) {
                            this.reloadPersonnel(this.personnels[0]);
                        }
                    }, (response) => {});
                },
                reloadPersonnel(personnel = null) {
                    personnel = personnel ? personnel : this.activePersonnel;
                    if(!personnel || !this.periode.debut || !this.periode.fin) {
                        return false;
                    }

                    this.loadingPersonnel = personnel;

                    this.postJson(params.personnelUrl, {
                        paie_personnel: {
                            personnel: personnel.id,
                            debut: this.debutInput,
                            fin: this.finInput,
                        }
                    }).then((data) => {
                        this.paieStore.setTypesJson(JSON.stringify(data.typeAbstractCollection || []));
                        this.recapitulatifCollection = data.recapitulatifCollection  || [];
                        this.roles = data.roles  || {};
                        this.paieStore.emptyState();
                        this.loadingPersonnel = null;
                        this.activePersonnel = personnel;

                    }, (response) => {
                        this.loadingPersonnel = null;
                    });
                },
                dateInputSetter(field, value) {
                    this.periode[field] = null;
                    let date = this.periode['debut' === field ? 'fin' : 'debut'];
                    let date1 = moment(value, 'DD/MM/YYYY');
                    date1 = 'debut' === field ? date1.startOf('week') : date1.endOf('week');

                    if(date1.isValid() && date) {
                        let diff = date1.diff(date, 'month');
                        let date2 = Math.abs(diff) >= 2 ? date1.clone().add((diff < 0 ? 1 : -1) * 2, 'month') : date;

                        this.periode = {
                            debut: (date1.isBefore(date2) ? date1 : date2).startOf('week'),
                            fin: (date1.isBefore(date2) ? date2 : date1).endOf('week'),
                        };
                    }
                },
            },
        }).use(App.Pinia).mount('#app');
    }
};
