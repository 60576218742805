<template>
    <MiniMap ref="miniMap" :stylePicker="true" :zoom="mapInitialZoom" :bounds="mapFallbackBounds" @click="emit('click', $event)">
        <slot></slot>
    </MiniMap>
</template>

<script setup>
import MiniMap from "@/Vue/Components/MiniMap";
import {ref} from "vue";

const emit = defineEmits(['click']);
const miniMap = ref();

const mapInitialZoom = 16;
const mapFallbackBounds = [
    {lng: -15, lat: 42},
    {lng: 20, lat: 51},
];

const centerMap = (center) => {
    miniMap.value.map.map.flyTo({
        center,
        zoom: mapInitialZoom,
        curve: 0.5,
        speed: 2,
        maxDuration: 1500,
    });
}

defineExpose({centerMap});
</script>