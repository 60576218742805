<template>
    <Path v-if="polyline" :features="[polyline]"><slot></slot></Path>
</template>

<script setup>
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import {ref, toRefs, watch} from "vue";
import Router from "@/App/Router";
import _ from 'lodash';
import {useRequest} from "@/Vue/Composables/Request";

const emit = defineEmits(['loading']);

const props = defineProps({
    steps: Array,
});
const {steps} = toRefs(props);

const polyline = ref(null);

const {getJson} = useRequest();

watch(steps, (newSteps, oldSteps) => {
    if (_.isEqual(newSteps, oldSteps)) {
        return;
    }

    emit('loading', true);
    getJson(Router.generate('map.routing', {steps: steps.value})).then(data => {
        polyline.value = data ? {
            type: 'Feature',
            geometry: JSON.parse(data.polyline),
        } : null;
        emit('loading', false);
    }).catch(error => {
        console.log(error)
    });
}, {immediate: true})
</script>