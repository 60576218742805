<template>
    <div class="position-relative tailwind mb-4" @wheel.stop v-if="paths.length" style="height: 350px;">
        <MiniMap :bounds="bounds">
            <Path v-for="features in allFeatures" color="#3b82f6" :features="[features]"></Path>
        </MiniMap>
    </div>
</template>

<script setup>
import MiniMap from "@/Vue/Components/MiniMap";
import Path from '@/Vue/Components/RegulationV2/Map/Base/Path';
import {toRefs, computed} from "vue";
import bbox from "@turf/bbox";

const props = defineProps(['paths']);
const {paths} = toRefs(props);

const allFeatures = computed(() => paths.value.map(path => ({type: 'Feature', geometry: {
    type: 'LineString',
    coordinates: path,
}})));
const bounds = computed(() => bbox({
    type: 'FeatureCollection',
    features: allFeatures.value.flat(),
}));
</script>
