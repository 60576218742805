<template>
    <button type="button" @click="emit('click', $event)" :class="{'text-gray-300 pointer-events-none': disabled, 'text-blue-600': active, 'text-gray-600 dark:text-white/80': !active && !disabled}" class="py-1 px-2 text-center font-semibold !bg-opacity-70 hover:!bg-opacity-70 !bg-white dark:!bg-black dark:!bg-opacity-30 hover:!bg-gray-50 dark:hover:!bg-black/50 flex-auto relative transition">
        <slot></slot>
    </button>
</template>

<script setup>
defineProps({
    disabled: Boolean,
    active: Boolean,
});
const emit = defineEmits(['click']);
</script>