'use strict';

import {createApp} from "vue";
import Concurrency from '@/Vue/Components/Concurrency';

App.Concurrency = class {
    constructor(hash) {
        this.hash = hash;

        const concurrency = document.getElementById('concurrency');

        if (concurrency) {
            createApp(Concurrency, {
                hash,
            }).use(App.Pinia).mount(concurrency);
        }
    }
};
