'use strict';

import Moment from "moment";
import {createApp} from 'vue'
import Router from "@/App/Router";
import _ from 'lodash';
import Request from "@/App/Request";
import VueChartkick from "vue-chartkick";
import 'chartkick/chart.js'
import Index from '@/Vue/Components/Regulation/Trajet/Index.vue';
import TrajetMap from '@/Vue/Components/Trajet/Map';

App.Regulation.Trajet = class {
    static getEtatRessource(ressource, allTrajetCollectionByRessourceId) {
        if (ressource.pauseCourante) {
            return 'pause';
        }

        if (ressource._type === 'vehicule') {
            if (ressource.estIndisponible) {
                return 'indisponible';
            }
        }
        else if (ressource._type === 'personnel') {
            const now = Moment();
            const type = ressource.typeCourant;

            if (!type || !type.estTravailAbstract || type._debut.isAfter(now) || type._fin.isBefore(now)) {
                return 'indisponible';
            }
        }
        else if (ressource._type === 'equipage') {
            const now = Moment();

            if (ressource._debut.isAfter(now) || (ressource._fin && ressource._fin.isBefore(now))) {
                return 'indisponible';
            }
        }

        let etat = 'disponible';

        for (const trajet of allTrajetCollectionByRessourceId[ressource._type][ressource.id] || []) {
            if (!trajet.estAnnule) {
                if (trajet.estEnCours) {
                    return trajet.topDestinationArriveeReel ? 'fin-mission' : 'en-mission';
                }

                if (!trajet.estTermine) {
                    etat = 'affecte';
                }
            }
        }

        return etat;
    }

    static initMap() {
        $('.js-trajet-map').each((i, el) => {
            createApp(TrajetMap, JSON.parse(el.dataset.data)).use(App.Pinia).mount(el);
        });
    }
};

App.Regulation.Trajet.Synoptique = class {
    constructor(data) {
        createApp({
            delimiters: ['[[', ']]'],
            data: () => ({
                trajets: [],
                vehicules: [],
                personnels: [],
                equipages: [],
                colonnes: {
                    'disponible': {
                        variant: 'success',
                        title: 'Disponible',
                    },
                    'affecte+1h': {
                        variant: 'info',
                        title: 'Affecté +1h',
                    },
                    'affecte-1h': {
                        variant: 'info',
                        title: 'Affecté -1h',
                    },
                    'en-mission': {
                        variant: 'danger',
                        title: 'En mission',
                    },
                    'fin-mission': {
                        variant: 'warning',
                        title: 'En fin de mission',
                    },
                    'pause': {
                        variant: 'warning',
                        title: 'En pause',
                    },
                },
                categories: {
                    ambu: {
                        title: 'AMBU',
                        icon: 'im-ambulance',
                    },
                    vsl: {
                        title: 'VSL',
                        icon: 'im-car',
                    },
                    taxi: {
                        title: 'TAXI',
                        icon: 'im-car2',
                    },
                    autre: {
                        title: 'Autre',
                        icon: 'im-rocket',
                    },
                    equipages: {
                        title: 'Équipages',
                        icon: 'im-link'
                    },
                    personnels: {
                        title: 'Personnels',
                        icon: 'im-man',
                    }
                }
            }),
            mounted() {
                let partsToRefresh = [];
                const refreshThrottled = _.throttle(() => {
                    this.refresh(partsToRefresh);
                    partsToRefresh = [];
                }, 1000);

                App.webSocket.subscribe('regulation/transport/trajet', (uri, data) => {
                    if(data.action === 'refresh') {
                        partsToRefresh = _.union(partsToRefresh, data.parts);
                        refreshThrottled();
                    }
                });

                this.refresh();

                document.dispatchEvent(new Event('app:vuejs:mounted'));
            },
            methods: {
                refresh(parts = null) {
                    let requestData = {
                        trajet_filter: data.filter
                    };
                    requestData.trajet_filter.rendezVous = Moment().format('DD/MM/YYYY');

                    if(parts !== null) {
                        requestData.parts = parts;
                    }

                    Request.postJson(Router.generate('regulation.trajet.list.ajax'), requestData).then((data) => {
                        this.handleData(data);
                    }, () => {});
                },
                handleData(data) {
                    if(data.index) {
                        this.trajets = data.index.trajetCollection;
                    }

                    if(data.vehicules) {
                        this.handleRessourceData(data.vehicules, 'vehicule');
                    }
                    if(data.equipages) {
                        this.handleRessourceData(data.equipages, 'equipage');
                    }
                    if(data.personnels) {
                        this.handleRessourceData(data.personnels, 'personnel');
                    }
                },
                handleRessourceData(ressources, type) {
                    for(const ressource of ressources) {
                        ressource._type = type;
                        ressource._trajetCollection = this.allTrajetCollectionByRessourceId[ressource._type][ressource.id] || [];

                        if (type === 'equipage') {
                            ressource._debut = ressource.debut !== null ? Moment(ressource.debut, 'DD/MM/YYYY HH:mm') : null;
                            ressource._fin = ressource.fin !== null ? Moment(ressource.fin, 'DD/MM/YYYY HH:mm') : null;
                        }

                        if (type === 'equipage') {
                            ressource._debut = ressource.debut !== null ? Moment(ressource.debut, 'DD/MM/YYYY HH:mm') : null;
                            ressource._fin = ressource.fin !== null ? Moment(ressource.fin, 'DD/MM/YYYY HH:mm') : null;
                        }

                        const typeCourant = ressource.typeCourant;

                        if (typeCourant) {
                            const debut = typeCourant.debutReel || typeCourant.debutPrevu;
                            const fin = typeCourant.finReel || typeCourant.finPrevu;

                            typeCourant._debut = debut !== null ? Moment(debut.date+' '+debut.time, 'DD/MM/YYYY HH:mm') : null;
                            typeCourant._fin = fin !== null ? Moment(fin.date+' '+fin.time, 'DD/MM/YYYY HH:mm') : null;
                        }
                    }

                    this[type+'s'] = ressources;
                },
                getColonne(ressource) {
                    let colonne = this.getEtatRessource(ressource);

                    if(!['disponible', 'affecte', 'en-mission', 'fin-mission', 'pause'].includes(colonne)) {
                        return null;
                    }

                    if('affecte' === colonne) {
                        let minDate = null;

                        for(const trajet of ressource._trajetCollection) {
                            if(trajet.etat !== 3) {
                                const newMinDate = trajet._debutMissionPrevu;

                                if (newMinDate && (null === minDate || newMinDate.isBefore(minDate))) {
                                    minDate = newMinDate;
                                }
                            }
                        }

                        if(minDate && minDate.isBefore(Moment().add(1, 'hour'))) {
                            colonne = 'affecte-1h';
                        } else {
                            colonne = 'affecte+1h';
                        }
                    }

                    return colonne;
                },
                getEtatRessource(ressource) {
                    return App.Regulation.Trajet.getEtatRessource(ressource, this.allTrajetCollectionByRessourceId);
                },
            },
            computed: {
                allTrajetCollectionByRessourceId() {
                    const fieldsByType = {
                        personnel: ['personnel1', 'personnel2'],
                        vehicule: ['vehicule'],
                        equipage: ['equipage'],
                    };

                    return this.trajets.reduce((res, trajet) => {
                        for (const type in fieldsByType) {
                            if (!res[type]) {
                                res[type] = {};
                            }

                            for (const field of fieldsByType[type]) {
                                if (null !== trajet[field]) {
                                    if (!res[type][trajet[field]]) {
                                        res[type][trajet[field]] = [];
                                    }
                                    if (!res[type][trajet[field]].includes(trajet)) {
                                        res[type][trajet[field]].push(trajet);
                                    }
                                }
                            }
                        }

                        return res;
                    }, {
                        personnel: {},
                        vehicule: {},
                        equipage: {},
                    });
                },
                chartOptions() {
                    return {
                        plugins: {
                            tooltip: {
                                enabled: false,
                            },
                        },
                        scales: {
                            x: {
                                grid: {display: false},
                                ticks: {autoSkip: true, maxTicksLimit: 31, maxRotation: 0, fontColor: '#8d9ea7', fontSize: 18}
                            },
                            y: {
                                grid: {display: false},
                                ticks: {display: false}
                            },
                        },
                    };
                },
                chartData() {
                    const time = Moment('1996-08-22 00:00:00');
                    let chartData = [
                        {
                            data: [],
                        },
                    ];

                    for (let i = 0; i < 24; ++i) {
                        for (let j = 0; j < 4; ++j) {
                            time.hours(i).minutes(j*15);

                            const timeStr = time.format('HH:mm');
                            const nextTimeStr = time.clone().add(15, 'minutes').format('HH:mm');
                            let count = 0;

                            for (const trajet of this.trajets) {
                                const firstTop = trajet.topDebut || trajet.topPriseEnChargeArrivee;
                                const lastTop = trajet.topFin || trajet.topDestinationArrivee || trajet.topPriseEnChargeArrivee;
                                if (lastTop.time >= timeStr && firstTop.time < nextTimeStr) {
                                    ++count;
                                }
                            }

                            chartData[0].data.push([timeStr, count]);
                        }
                    }

                    time.hours(23).minutes(59);
                    chartData[0].data.push([time.format('HH:mm'), 0]);

                    return chartData;
                },
                vehiculesByType() {
                    return this.vehicules.reduce((res, item) => (res[item.icone.title] = item, res), {});
                },
                trajetsEnCoursCount() {
                    return this.trajets.filter((trajet) => {
                        return trajet.estEnCours;
                    }).length;
                },
                trajetsTerminesCount() {
                    return this.trajets.filter((trajet) => {
                        return trajet.estTermine;
                    }).length;
                },
                trajetsPlanifiesCount() {
                    return this.trajets.filter((trajet) => {
                        return !trajet.estEnCours && !trajet.estTermine && !trajet.estAnnule;
                    }).length;
                },
                compteurs() {
                    let compteurs = {};
                    for(const categorie in this.categories) {
                        compteurs[categorie] = {};

                        for(const colonne in this.colonnes) {
                            compteurs[categorie][colonne] = {count: 0, ressources: []};
                        }
                    }

                    for(const vehicule of this.vehicules) {
                        let categorie = 'autre';
                        if(vehicule.categorie.estAmbu) {
                            categorie = 'ambu';
                        }
                        else if(vehicule.categorie.estVsl) {
                            categorie = 'vsl';
                        }
                        else if(vehicule.categorie.estTaxi) {
                            categorie = 'taxi';
                        }

                        const col = this.getColonne(vehicule);

                        if(col) {
                            compteurs[categorie][col].count++;
                            compteurs[categorie][col].ressources.push(vehicule);
                        }
                    }

                    for(const field of ['personnels', 'equipages']) {
                        for(const ressource of this[field]) {
                            const col = this.getColonne(ressource);

                            if(col) {
                                compteurs[field][col].count++;
                                compteurs[field][col].ressources.push(ressource);
                            }
                        }
                    }

                    return compteurs;
                }
            },
        }).mount('#app');
    }
};

App.Regulation.Trajet.Index = class {
    constructor() {
        const el = document.getElementById('app');

        createApp(Index, JSON.parse(el.dataset.data)).use(VueChartkick).mount(el);
    }
};
