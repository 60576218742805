<template>
    <div>
        <div class="panel panel-compact" v-for="group in groups">
            <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
                <div>{{ group.title }}</div>
            </div>
            <div class="panel-wrapper">
                <div v-if="loading" class="p-4">
                    <div class="p-2">
                        <b-spinner class="mr-2"></b-spinner> Chargement…
                    </div>
                </div>
                <template v-else>
                    <div v-if="Object.keys(group.lotsBySociete).length">
                        <div v-for="(societeLots, index) in Object.values(group.lotsBySociete)">
                            <div v-if="structures.length > 1" :class="{'border-top': index !== 0}" class="pt-3 px-4 text-theme font-weight-normal font-size-16px mb-n2">{{ societeLots.structure.libelle }}</div>
                            <div class="row px-4 pt-4">
                                <div v-for="lot in societeLots.lots" class="col-xxl-2 col-xl-3 col-lg-4 col-6">
                                    <tableau-de-bord-widget-lot-box @click="openLot(lot, group.type)">
                                        <template v-slot:content>
                                            <tableau-de-bord-widget-lot-title :lot="group.type === 'suggestion' ? lot : lot.lotAbstract"></tableau-de-bord-widget-lot-title>
                                            <tableau-de-bord-widget-scor-suggestion-item v-if="group.type === 'suggestion'" :lot="lot"></tableau-de-bord-widget-scor-suggestion-item>
                                            <tableau-de-bord-widget-scor-lot-item v-else :lot="lot"></tableau-de-bord-widget-scor-lot-item>
                                        </template>
                                        <template v-slot:footer>
                                            <template v-if="group.type === 'suggestion'">
                                                <a href="#" class="btn btn-sm btn-secondary">
                                                    <i class="fa-regular fa-lightbulb mr-1"></i> {{ Translator.trans('libelle.suggestion') }}
                                                </a>
                                                <a href="#" class="btn btn-sm btn-success box-lot-footer-btn">
                                                    <i class="fa-solid fa-plus mr-1"></i> {{ Translator.trans('action.creer-lot') }}
                                                </a>
                                            </template>
                                            <template v-else-if="group.type === 'enAttente'">
                                                <a href="#" class="btn btn-sm btn-secondary">
                                                    <i class="fa-solid fa-hourglass mr-1"></i> <timer v-if="lot.envoi" :value="lot.envoi.date"></timer>
                                                </a>
                                                <a href="#" class="btn btn-sm btn-info box-lot-footer-btn">
                                                    <i class="fa-solid fa-paper-plane mr-1"></i> Transmis
                                                </a>
                                            </template>
                                            <template v-else-if="group.type === 'aTraiter'">
                                                <template v-if="lot.etat === 0">
                                                    <a href="#" class="btn btn-sm btn-secondary">
                                                        <i class="fa-solid fa-xmark mr-1"></i> {{ Translator.trans('libelle.non-transmis') }}
                                                    </a>
                                                    <a href="#" class="btn btn-sm btn-primary box-lot-footer-btn">
                                                        <i class="fa-solid fa-paper-plane mr-1"></i> {{ Translator.trans('action.envoyer') }}
                                                    </a>
                                                </template>
                                                <template v-else-if="lot.etat === 3">
                                                    <a href="#" class="btn btn-sm btn-warning">
                                                        <i class="fa-solid fa-triangle-exclamation mr-1"></i> {{ Translator.trans('libelle.a-reconstituer') }}
                                                    </a>
                                                    <a href="#" class="btn btn-sm btn-warning box-lot-footer-btn">
                                                        <i class="fa-solid fa-arrows-rotate mr-1"></i> {{ Translator.trans('action.reconstituer') }}
                                                    </a>
                                                </template>
                                                <template v-else-if="lot.etat === 4">
                                                    <a href="#" class="btn btn-sm btn-warning">
                                                        <i class="fa-solid fa-triangle-exclamation mr-1"></i> {{ Translator.trans('libelle.a-retransmettre') }}
                                                    </a>
                                                    <a href="#" class="btn btn-sm btn-warning box-lot-footer-btn">
                                                        <i class="fa-solid fa-paper-plane mr-1"></i> {{ Translator.trans('action.retransmettre') }}
                                                    </a>
                                                </template>
                                                <template v-else>
                                                    <a href="#" class="btn btn-sm btn-secondary">
                                                        <i class="fa-regular fa-file mr-1"></i> {{ Translator.trans('libelle.envoi-papier') }}
                                                    </a>
                                                    <a href="#" class="btn btn-sm btn-success box-lot-footer-btn">
                                                        <i class="fa-solid fa-check mr-1"></i> {{ Translator.trans('action.traiter') }}
                                                    </a>
                                                </template>
                                            </template>
                                        </template>
                                    </tableau-de-bord-widget-lot-box>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-muted p-4">Aucun élément</div>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRefs, watch, shallowRef} from "vue";
import _ from "lodash";
import Router from "@/App/Router";
import Request from "@/App/Request";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";
import LotPopup from '@/Vue/Components/TableauDeBord/Widget/Scor/Lot/Popup';
import SuggestionPopup from '@/Vue/Components/TableauDeBord/Widget/Scor/Suggestion/Popup';

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const loading = ref(false);
const suggestions = ref([]);
const lotsScor = ref([]);
const pieceViewer = ref({});
const lotMaxFilesize = ref(null);
const popupVm = shallowRef(null);
const updateUrl = ref(null);
const batchDeleteUrl = ref(null);

const groups = computed(() => {
    return {
        suggestionsPret: {
            type: 'suggestion',
            title: 'Prêt pour envoi',
            lotsBySociete: groupBySociete(suggestionsPret.value),
        },
        suggestionsAPreparer: {
            type: 'suggestion',
            title: 'À préparer',
            lotsBySociete: groupBySociete(suggestionsAPreparer.value),
        },
        aTraiter: {
            type: 'aTraiter',
            title: 'À traiter',
            lotsBySociete: groupBySociete(lotsScor.value.filter(lot => [0, 3, 4].includes(lot.etat) || lot.etatEnvoiPapier === false)),
        },
        enAttente: {
            type: 'enAttente',
            title: 'En attente de retour',
            lotsBySociete: groupBySociete(lotsScor.value.filter(lot => [1, 2].includes(lot.etat))),
        }
    };
});

const lots = computed(() => [...suggestions.value.map(lot => ({type: 'suggestion', lot}))]);
const lotsById = computed(() => lots.value.reduce((res, item) => (res[item.lot.id] = item, res), {}));
const suggestionsPret = computed(() => suggestions.value.filter(lot => lot.standardCollection.reduce((res, facture) => res && (facture.pretEnvoiScor || facture.etatScor !== 0), true)));
const suggestionsAPreparer = computed(() => suggestions.value.filter(lot => !suggestionsPret.value.includes(lot)));

const groupBySociete = (lots) => {
    return _.orderBy(lots.reduce((res, lot) => {
        const structure = lot.structure || lot.lotAbstract.structure;

        if(!(structure.id in res)) {
            res[structure.id] = {structure, lots: []}
        }

        res[structure.id].lots.push(lot);

        return res;
    }, {}), ['structure.libelleComplet']);
};

const openLot = (lot, type) => {
    const component = type === 'suggestion' ? SuggestionPopup : LotPopup;

    App.Utils.openVueSwal(component, {
        lot, type, pieceViewer: pieceViewer.value, lotMaxFilesize: lotMaxFilesize.value,
        onRefresh: () => refresh(true),
    }).then(vm => (popupVm.value = vm));
};

const refresh = (silent = false) => {
    if(!structures.value.length) {
        abort();
        suggestions.value = [];
        lotsScor.value = [];
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = !silent;
    Request.postJson(Router.generate('facturation.tableau-de-bord.scor'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((data) => {
        suggestions.value = data.suggestions;
        lotsScor.value = data.lotsScor;
        pieceViewer.value = data.pieceViewer;
        lotMaxFilesize.value = data.lotMaxFilesize;
        updateUrl.value = data.updateUrl;
        batchDeleteUrl.value = data.batchDeleteUrl;
        loading.value = false;

        if (popupVm.value) {
            const newLot = lotsById.value[popupVm.value.lot.id];

            if (newLot) {
                popupVm.value.lot = lotsById.value[popupVm.value.lot.id].lot;
            }
        }
    }, (err) => emit('error', err)).finally(() => {
        emit('refresh');
    });
};


watch(structures, () => refresh());
onMounted(() => refresh());

</script>
