<template>
    <div class="d-flex flex-column" style="height: calc(100vh - 119px);" ref="app">
        <div class="trajet-search" :class="{'active': searchMode}">
            <div class="input-group">
                <input type="text" ref="searchInput" class="form-control" placeholder="Rechercher..." v-model="searchQuery">
                <div class="input-group-append">
                    <div class="input-group-text">
                        <div class="min-width-42px text-center">
                            <template v-if="searchResults.length">{{ searchIndex+1 }} / {{ searchResults.length }}</template>
                            <template v-else>-</template>
                        </div>
                    </div>
                </div>
            </div>
            <button type="button" class="btn btn-outline-secondary" @click="stepSearch(-1)" :disabled="!searchResults.length">
                <i class="fa-solid fa-chevron-up"></i>
            </button>
            <button type="button" class="btn btn-outline-secondary" @click="stepSearch(1)" :disabled="!searchResults.length">
                <i class="fa-solid fa-chevron-down"></i>
            </button>
            <button type="button" class="btn btn-outline-secondary" @click="searchMode = false">
                <i class="fa-solid fa-xmark"></i>
            </button>
        </div>
        <div class="white-box box-compact p-3">
            <div class="row search-form form-horizontal">
                <div class="col-xxl-1-5 col-xl-4 col-lg-3 col-sm-3 col-12 order-1">
                    <div class="input-group">
                        <span class="input-group-prepend">
                            <button class="btn btn-secondary btn-outline" type="button" @click="step(-1)">
                                <i class="fa-solid fa-chevron-left"></i>
                            </button>
                        </span>
                        <FormDate id="trajet_filter_rendezVous" class="text-center" :value="rendezVousInput" @change="rendezVousInput = $event" :picker="true"></FormDate>
                        <span class="input-group-append">
                            <button class="btn btn-secondary btn-outline" type="button" @click="step(1)">
                                <i class="fa-solid fa-chevron-right"></i>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="col-xxl-3-5 mt-xxl-0 col-xl-6 col-lg-12 order-lg-4 order-md-4 col-12 mt-3 order-3">
                    <BtnGroupFilter :choices="filterCategorieChoices" :values="filter.categorie || []" @values="filter.categorie = $event"></BtnGroupFilter>
                </div>
                <div class="col-xxl-4 order-xxl-3 mt-xxl-0 col-xl-6 col-lg-12 order-lg-5 order-md-5 col-12 mt-3 order-4">
                    <BtnGroupFilter :choices="filterEtatChoices" :values="filter.etat || []" @values="filter.etat = $event"></BtnGroupFilter>
                </div>
                <div class="col-xxl-1-5 order-xxl-4 col-xl-5 col-lg-6 order-lg-2 mt-lg-0 order-sm-3 col-12 mt-3 order-2">
                    <div class="form-group">
                        <div v-html="form.structureCollection"></div>
                    </div>
                </div>
                <template v-if="showAdvancedFilters">
                    <div class="col-sm-6 col-xl-4 col-xxl-1-5 order-6 mt-3">
                        <FormSelect2Autocomplete :placeholder="Translator.trans('libelle.motif')" template="motif" v-model="filter.motif" route="regulation.trajet.filter.motif"></FormSelect2Autocomplete>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-2 order-6 mt-3">
                        <FormSelect2Autocomplete :placeholder="Translator.trans('libelle.patient')" template="patient" v-model="filter.patient" route="regulation.trajet.filter.patient" :route-params="{ids: patientIds}"></FormSelect2Autocomplete>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-2 order-6 mt-3">
                        <FormSelect2Autocomplete :placeholder="Translator.trans('libelle.equipage')" template="equipage" v-model="filter.equipage" route="regulation.trajet.filter.equipage"></FormSelect2Autocomplete>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-2 order-6 mt-3">
                        <FormSelect2Autocomplete :placeholder="Translator.trans('libelle.vehicule')" v-model="filter.vehicule" route="regulation.trajet.filter.vehicule"></FormSelect2Autocomplete>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-2 order-6 mt-3">
                        <FormSelect2Autocomplete :placeholder="Translator.trans('libelle.personnel')" v-model="filter.personnel" route="regulation.trajet.filter.personnel"></FormSelect2Autocomplete>
                    </div>
                    <div class="col-sm-6 col-xl-4 col-xxl-2-5 order-6 mt-3">
                        <BtnGroupFilter :choices="{serie: 'Avec série', contrat: 'Avec contrat', estGarde: 'Est garde'}" :values="filter.caracteristiques || []" @values="filter.caracteristiques = $event"></BtnGroupFilter>
                    </div>
                </template>
                <div class="col-xxl-0-5 order-xxl-5 col-xl-1 col-lg-1 order-lg-3 col-4 col-sm-3 mt-sm-0 order-sm-2 mt-3 order-7">
                    <button type="button" title="Recherche avancée" class="btn btn-secondary btn-block" @click="showAdvancedFilters = !showAdvancedFilters">
                        <i class="fa-solid" :class="'fa-magnifying-glass-'+(showAdvancedFilters ? 'minus' : 'plus')"></i>
                    </button>
                </div>
                <div class="col-xxl-0-5 order-xxl-5 col-xl-1 col-lg-1 order-lg-3 col-4 col-sm-3 mt-sm-0 order-sm-2 mt-3 order-7">
                    <button type="button" :title="Translator.trans('action.rechercher')" class="btn btn-primary btn-block" @click="searchMode = !searchMode">
                        <i class="fa-solid fa-magnifying-glass"></i>
                    </button>
                </div>
                <div class="col-xxl-0-5 order-xxl-5 col-xl-1 col-lg-1 order-lg-3 col-4 col-sm-3 mt-sm-0 order-sm-2 mt-3 order-7">
                    <b-dropdown :title="Translator.trans('libelle.actions')" variant="secondary" block no-caret right>
                        <template #button-content>
                            <i class="fa-solid fa-bars"></i>
                        </template>
                        <b-dropdown-item :link-class="{'disabled': ajaxLocked || !trajetCollectionPretEnvoiTla.length}" @click="envoyerTla(trajetCollectionPretEnvoiTla)">
                            <template v-if="trajetCollectionPretEnvoiTla.length === 1">
                                Envoyer le trajet prêt au TLA
                            </template>
                            <template v-else>
                                Envoyer les trajets prêts aux TLAs ({{ trajetCollectionPretEnvoiTla.length }})
                            </template>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-lg-1 d-flex pr-0">
                <Popover target="trajetsCount" trigger="hover" placement="bottom">
                    <pie-chart class="mb-4" v-if="allTrajetCollection.length" :data="trajetsCountByCategorieChartData" :colors="categoriesColor" :legend="false" width="150px" height="150px"></pie-chart>
                    <div class="text-center my-n2">
                        <b>Total :</b> {{ allTrajetCollection.length }} trajet{{ allTrajetCollection.length > 1 ? 's' : '' }}
                    </div>
                </Popover>
                <div class="white-box box-compact text-center p-0 d-flex flex-column flex-1 justify-content-center pt-1 line-height-1" id="trajetsCount">
                    <div class="regulation-counter-value">
                        {{ trajetCollection.length }}<small v-if="allTrajetCollection.length !== trajetCollection.length"> / {{ allTrajetCollection.length }}</small>
                    </div>
                    <div class="regulation-counter-title mt-1 text-muted">Trajet{{ allTrajetCollection.length > 1 ? 's' : '' }}</div>
                </div>
            </div>
            <div class="col-6 col-lg-1 d-flex pr-0">
                <Popover target="trajetsEstimation" trigger="hover" placement="bottom">
                    <pie-chart class="mb-4" v-if="estimationMontantTouteTaxeTotal" :data="trajetsEstimationByCategorieChartData" :colors="categoriesColor" :library="trajetsEstimationByCategorieChartConfig" :legend="false" width="150px" height="150px"></pie-chart>
                    <div class="text-center my-n2">
                        <b>Total :</b> {{ Euro(estimationMontantTouteTaxeTotal) }}
                    </div>
                </Popover>
                <div class="white-box box-compact text-center p-0 d-flex flex-column flex-1 justify-content-center pt-1 line-height-1" id="trajetsEstimation">
                    <div class="regulation-counter-value">{{ Euro(estimationMontantTouteTaxeFiltre) }}</div>
                    <div class="regulation-counter-title mt-1 text-muted">Estimation</div>
                </div>
            </div>
            <div class="d-none d-lg-block col-10">
                <div class="white-box box-compact py-0" style="height: 52px;">
                    <column-chart ref="chart" height="52px" :legend="false" :data="chartData" :colors="['#66c4ed', '#e4e7ea']" :dataset="{borderWidth: 0}" :library="chartOptions"></column-chart>
                </div>
            </div>
        </div>
        <div class="p-0 mb-n4 flex-1 d-flex flex-column height-0 position-relative">
            <div class="table-responsive m-0 b-table-sticky-header" style="max-height: none;padding-bottom: 55px;" :class="{'pointer-events-none': editInPlaceField !== null}">
                <table class="bg-white table b-table table-striped table-hover table-compact table-bordered" :class="{'dragging': ressourceDragging}">
                    <thead>
                    <tr>
                        <TableTh v-model:sort="sort" sort-column="etat" class="text-center width-32px" :title="Translator.trans('libelle.etat')">{{ Translator.trans('libelle.etatAbreviation') }}</TableTh>
                        <TableTh class="width-1px">{{ Translator.trans('libelle.type') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="motif" class="width-1px">{{ Translator.trans('libelle.motif') }}</TableTh>
                        <TableTh class="width-1px text-center" :title="Translator.trans('libelle.precisions')">{{ Translator.trans('libelle.precisionsAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="simultane" class="width-1px text-center" :title="Translator.trans('libelle.simultane')">{{ Translator.trans('libelle.simultaneAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="rendezVous" class="width-55px text-center" :title="Translator.trans('libelle.rendezVous')">{{ Translator.trans('libelle.rendezVousAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="patient">{{ Translator.trans('libelle.patient') }}</TableTh>
                        <TableTh class="width-1px text-nowrap" :title="Translator.trans('libelle.kilometre')">{{ Translator.trans('libelle.kilometreAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="villePriseEnCharge" :title="Translator.trans('libelle.villePriseEnCharge')">{{ Translator.trans('libelle.villePriseEnChargeAbreviation') }}</TableTh>
                        <TableTh :title="Translator.trans('libelle.adressePriseEnCharge')">{{ Translator.trans('libelle.adressePriseEnChargeAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="villeDestination" :title="Translator.trans('libelle.villeDestination')">{{ Translator.trans('libelle.villeDestinationAbreviation') }}</TableTh>
                        <TableTh :title="Translator.trans('libelle.adresseDestination')">{{ Translator.trans('libelle.adresseDestinationAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="equipage" class="width-1px text-nowrap" :title="Translator.trans('libelle.equipage')">{{ Translator.trans('libelle.equipageAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="vehicule" class="width-1px text-nowrap" :title="Translator.trans('libelle.vehicule')">{{ Translator.trans('libelle.vehiculeAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="personnel1" class="width-1px text-nowrap" :title="Translator.trans('libelle.personnel1')">{{ Translator.trans('libelle.personnel1Abreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="personnel2" class="width-1px text-nowrap" :title="Translator.trans('libelle.personnel2')">{{ Translator.trans('libelle.personnel2Abreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="topDebut"  class="width-1px text-nowrap" :title="Translator.trans('libelle.topDebut')">{{ Translator.trans('libelle.topDebutAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="topPriseEnChargeArrivee"  class="width-1px text-nowrap" :title="Translator.trans('libelle.topPriseEnChargeArrivee')">{{ Translator.trans('libelle.topPriseEnChargeArriveeAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="topDestinationArrivee"  class="width-1px text-nowrap" :title="Translator.trans('libelle.topDestinationArrivee')">{{ Translator.trans('libelle.topDestinationArriveeAbreviation') }}</TableTh>
                        <TableTh v-model:sort="sort" sort-column="topFin"  class="width-1px text-nowrap" :title="Translator.trans('libelle.topFin')">{{ Translator.trans('libelle.topFinAbreviation') }}</TableTh>
                        <th class="text-center width-1px">{{ Translator.trans('libelle.actions') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <TrajetRow :class="{'focus': index === focusTrajetIndex, 'selected': trajet.id === currentTrajetId, 'disabled': ressourceDragging && !canDropRessource(trajet), 'drag-source': ressourceDraggingTrajetSource === trajet}"
                        v-for="(trajet, index) in trajetCollection"
                        :key="trajet.id"
                        :ref="el => { trajetRows[trajet.id] = el }"
                        @click="selectTrajet(index)"
                        :trajet="trajet"
                        :open-target="openTarget"
                        :ressource-drop-saving="ressourceDropSaving"
                        :simultane="simultane"
                        :simultane-trajet-ids="simultaneTrajetIds"
                        :simultane-loading="simultaneLoading"
                        @simultane-save="saveSimultane"
                        @simultane-toggle="toggleSimultane(trajet, $event)"
                        @simultane-cancel="cancelSimultane"
                    ></TrajetRow>
                    <tr v-if="!trajetCollection.length">
                        <td colspan="42" class="text-muted">{{ Translator.trans('libelle.aucun-element') }}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="spinner-overlay above" v-if="isLoading" @click="isLoading = false">
                <div class="spinner-overlay-boundary">
                    <div class="spinner spinner-lg bg-theme"></div>
                </div>
            </div>
            <EditInPlace :field="editInPlaceField" :element="currentTrajetRow" :value="editInPlaceValue" :error="editInPlaceError" :loading="editInPlaceLoading" @input="onEditInPlaceUpdate($event)"></EditInPlace>
            <div class="d-flex justify-content-between p-3" style="position: absolute;bottom: 0;left: 0;right: 0;background: rgba(255, 255, 255, .9);backdrop-filter: blur(10px);border-left: solid 1px #e4e7ea;border-right: solid 1px #e4e7ea;">
                <div class="btn-group gap-2">
                    <a v-if="roles.ROLE_REGULATION_TRANSPORT_NEW" :href="Router.generate('shared.transport.new', {app: 'regulation'})" :target="modeOuvertureTransportRegulation == 1 ? '_blank' : null" title="Créer" class="btn btn-outline-success js-transport-new"><i class="fa-solid fa-plus"></i> Créer</a>
                    <button type="button" :title="Translator.trans('action.imprimer')" class="btn btn-secondary" @click="print($event)">
                        <i class="fa-solid fa-print"></i>
                    </button>
                    <button type="button" :title="Translator.trans('action.telecharger')" class="btn btn-secondary" @click="download($event)">
                        <i class="fa-solid fa-file-spreadsheet"></i>
                    </button>
                    <a :href="Router.generate('regulation.trajet.synoptique')" title="{{ 'action.ouvrir-synoptique'|trans }}" target="_blank" class="btn btn-secondary">
                        <i class="fa-solid fa-gauge"></i>
                    </a>
                    <b-btn variant="secondary" @click="refresh()" class="js-hide-ws">
                        <i class="fa-solid fa-refresh"></i> Rafraichir
                    </b-btn>
                </div>
                <div class="p-7">
                    <b>{{ trajetCollection.length }}</b><template v-if="allTrajetCollection.length !== trajetCollection.length"> / {{ allTrajetCollection.length }}</template> trajet{{ allTrajetCollection.length > 1 ? 's' : '' }}
                </div>
            </div>
        </div>
        <Teleport to="#regulationSidebar">
            <b-tabs fill no-fade content-class="m-0" nav-class="customtab right-side-panel-tabs" v-model="activeTab">
                <b-tab>
                    <template #title>
                        <i class="icon-directions"></i><span v-if="0 === activeTab" class="ml-2">Trajet</span>
                    </template>
                    <TabTrajet ref="trajetTab" :trajet="currentTrajet" :structures="structureCollection"  @map-select-vehicule="onMapSelectVehicule($event)"></TabTrajet>
                </b-tab>
                <b-tab>
                    <template #title>
                        <i id="messagerieIcon" class="icon-bubbles"></i><span v-if="1 === activeTab" class="ml-2">Messagerie</span><div class="notify" v-if="hasUnreadMessage"><span class="heartbit border-danger"></span><span class="point bg-danger"></span></div>
                    </template>
                    <TabMessagerie ref="messagerieTab"></TabMessagerie>
                </b-tab>
                <b-tab>
                    <template #title>
                        <i class="ti-link"></i><span v-if="2 === activeTab" class="ml-2">Ressources</span><div class="notify" v-if="hasNewRessourceDispo"><span class="heartbit border-danger"></span><span class="point bg-danger"></span></div>
                    </template>
                    <TabRessources ref="ressourcesTab" @ressource-dispo="onRessourceDispo" @ressource-click="onRessourceClick"></TabRessources>
                </b-tab>
                <b-tab v-if="roles.ROLE_REGULATION_TRAJET_SIDEBAR_SALARIES_VIEW">
                    <template #title>
                        <i class="icon-organization"></i><span v-if="3 === activeTab" class="ml-2">Salariés</span>
                    </template>
                    <TabSalaries></TabSalaries>
                </b-tab>
            </b-tabs>
        </Teleport>
    </div>
</template>

<script setup>
import {useRegulationStore} from "@/Vue/Stores/Regulation";
import {storeToRefs} from "pinia";
import {computed, createApp, nextTick, onMounted, provide, ref, watch} from "vue";
import Request from "@/App/Request";
import Translator from "@/App/Translator";
import Router from "@/App/Router";
import swal from "sweetalert2";
import Moment from "moment/moment";
import Fuse from "fuse.js";
import Euro from "@/Vue/Filters/Euro";
import TableTh from "@/Vue/Components/Table/Th"
import TabSalaries from "@/Vue/Components/Regulation/Tab/Salaries"
import TabRessources from "@/Vue/Components/Regulation/Tab/Ressources"
import TabMessagerie from "@/Vue/Components/Regulation/Tab/Messagerie"
import TabTrajet from "@/Vue/Components/Regulation/Tab/Trajet"
import EditInPlace from "@/Vue/Components/EditInPlace"
import TrajetRow from "@/Vue/Components/Regulation/Trajet/Row"
import Popover from "@/Vue/Components/Popover"
import FormDate from "@/Vue/Components/Form/Date"
import FormSelect2Autocomplete from "@/Vue/Components/Form/Select2Autocomplete"
import BtnGroupFilter from "@/Vue/Components/BtnGroupFilter"
import RessourceDetail from "@/Vue/Components/Regulation/RessourceDetail.vue";
import EnvoiPause from "@/Vue/Components/Regulation/EnvoiPause.vue";
import EnvoiFinService from "@/Vue/Components/Regulation/EnvoiFinService.vue";
import AnnulationTrajet from "@/Vue/Components/Regulation/AnnulationTrajet.vue";

const store = useRegulationStore();

const props = defineProps([
    'modeOuvertureTransport',
    'structuresRacine',
    'form',
    'roles',
    'rendezVous',
]);

const {
    refresh,
    step,
    print,
    download,
    canSendPause,
    canSendFinService,
    envoyerTla,
    setTrajet,
    updateTrajet,
    saveSimultane,
    editSimultane,
    toggleSimultane,
    cancelSimultane,
    getRessourcesAppareilCollection,
    categoriesColor
} = store;

const { rendezVousInput,
    structureCollection,
    structuresRacine,
    trajetCollection,
    currentTrajet,
    currentTrajetId,
    ajaxLocked,
    patientIds,
    trajetCollectionPretEnvoiTla,
    chartData,
    chartOptions,
    filter,
    isLoading,
    salaries,
    estimationMontantTouteTaxeFiltre,
    estimationMontantTouteTaxeTotal,
    trajetsEstimationByCategorieChartData,
    trajetsEstimationByCategorieChartConfig,
    allTrajetCollection,
    trajetIndexById,
    personnels,
    vehicules,
    equipages,
    showAdvancedFilters,
    rendezVous,
    simultane,
    simultaneTrajetIds,
    simultaneLoading,
    messagerie,
    hasUnreadMessage,
    sort,
    filterEtatChoices,
    trajetsCountByCategorieChartData,
    filterCategorieChoices,
} = storeToRefs(store)
rendezVousInput.value = props.rendezVous;

structuresRacine.value = props.structuresRacine;

onMounted(() => {
    // Date picker
    let $body = $('body');

    // TREE SELECT
    let treeSelectInputSelector = 'input[name="trajet_filter[structureCollection][]"]';
    $body.on('change', treeSelectInputSelector, () => {
        structureCollection.value = $.map($(treeSelectInputSelector + ':checked'), function(el){return el.value; });
    }).one('treeselect.init', () => {
        $(treeSelectInputSelector).filter(':checked').change();
    }).on('change', 'select#trajet_filter_structureCollection', (e) => {
        structureCollection.value = $(e.currentTarget).val();
    });

    $body.on('rightPanel.open', () => {
        App.Layout.updateShuffle();
    });

    $body.on('click', (e) => {
        if ($(e.target).parents('.edit-in-place').length === 0 && $(e.target).parents('.clockpicker-popover').length === 0) {
            editInPlaceField.value = null;
        }
    });

    // CONTEXT MENU
    let handleMenuLink = (url, ajax = false, post = null) => {
        return (k, i, e) => {
            if(ajax) {
                ajaxLocked.value = true;

                if (post) {
                    Request.postJson(url, post).then((data) => {
                        if (data.success) {
                            setTrajet(data.data.id, data.data);
                        }
                    }).finally(() => {
                        ajaxLocked.value = false;
                    });
                } else {
                    Request.getJson(url).then((data) => {
                        setTrajet(data.id, data);
                    }, (response) => {
                        // err
                    }).finally(() => {
                        ajaxLocked.value = false;
                    });
                }

            } else {
                const newTab = props.modeOuvertureTransport === 1 || (e && (e.which === 2 || (e.which === 1 && e.ctrlKey)));

                if (newTab) {
                    let win = window.open(url, '_blank');
                    if (win) win.focus();
                } else {
                    window.location.href = url;
                }
            }
        }
    };

    $.contextMenu({
        selector: '#app tbody tr',
        build: ($trigger, event) => {
            let index = $trigger.index();
            let trajet = trajetCollection.value[index];

            if(!trajet) {
                return false;
            }

            let fromBtn = $(event.target).is('.js-context-menu-btn');
            focusTrajetIndex.value = index;
            let items = {};

            // Voir / Modifier
            if(!fromBtn) {
                if(trajet.roles.ROLE_REGULATION_TRANSPORT_VIEW) {
                    items.view = {name: Translator.trans('action.voir'), icon: 'fa-solid fa-eye', callback: handleMenuLink(Router.generate('shared.transport.view', {app: 'regulation', id: trajet.transport.id, trajet: trajet.type}))};
                }
                if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                    items.edit = {name: Translator.trans('action.modifier'), icon: 'fa-solid fa-pencil', callback: handleMenuLink(Router.generate('shared.transport.edit', {app: 'regulation', id: trajet.transport.id}))};
                }
            }

            // Envoi TLA
            if (trajet.roles.ROLE_REGULATION_TRAJET_TLA_SEND) {
                if(!fromBtn) {
                    items.sep1 = '';
                }

                if (trajet.canRetrySendTla) {
                    items.renvoyerTla = {name: Translator.trans('action.renvoyer-tla'), icon: 'fa-solid fa-paper-plane', disabled: !trajet.canRetrySendTla || ajaxLocked.value || trajet.estEnvoyeTla, callback: () => envoyerTla([trajet])};
                } else {
                    items.envoyerTla = {name: Translator.trans('action.envoyer-tla'), icon: 'fa-solid fa-paper-plane', disabled: !trajet.canFirstSendTla || ajaxLocked.value || trajet.estEnvoyeTla, callback: () => envoyerTla([trajet])};
                }
            }

            items.envoyerMessage = getMessageItem([trajet._vehicule, trajet._personnel1, trajet._personnel2]);

            items.envoyerPause = {name: Translator.trans('action.envoyer-pause'), disabled: !canSendPause(trajet._personnel1, trajet._personnel2, trajet._vehicule), icon: 'fa-solid fa-pause', callback: () =>
                openEnvoiPauseSwal(trajet._personnel1, trajet._personnel2, trajet._vehicule, trajet._equipage)
            };
            items.envoyerFinService = {name: Translator.trans('action.envoyer-fin-service'), disabled: !canSendFinService(trajet._personnel1, trajet._personnel2, trajet._vehicule), icon: 'fa-solid fa-stop', callback: () =>
                openEnvoiFinServiceSwal(trajet._personnel1, trajet._personnel2, trajet._vehicule, trajet._equipage)
            };

            // Annule / Ne pas facture
            if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                items.sep2 = '';
                if (trajet.estAnnule) {
                    items.reactiver = {
                        name: Translator.trans('action.reactiver'),
                        icon: 'fa-regular fa-circle-check',
                        disabled: ajaxLocked.value,
                        callback: handleMenuLink(Router.generate('regulation.trajet.reactiver.ajax', {id: trajet.id}), true),
                    };

                } else {
                    items.annuler = {
                        name: Translator.trans('action.annuler'),
                        icon: 'fa-regular fa-circle-xmark',
                        disabled: ajaxLocked.value,
                    };

                    if (!items.annuler.disabled) {
                        const racinePartage = trajet._structure.racinePartage;
                        let motifs = [];
                        let motifRequis = false;

                        if (racinePartage) {
                            motifs = Object.values(racinePartage.motifsAnnulation);
                            motifRequis = racinePartage.motifAnnulationRequis;
                        }

                        if (motifs.length) {
                            items.annuler.items = [];
                            if (!motifRequis) {
                                items.annuler.items.push({
                                    name: 'Sans motif',
                                    callback: handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {}),
                                });
                                items.annuler.items.push('');
                            }

                            for (const motif of motifs) {
                                items.annuler.items.push({
                                    name: motif.libelle,
                                    callback: motif.modeCommentaire === 0 ? handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {motif: motif.id}) : () => {
                                        App.Utils.openVueSwal(AnnulationTrajet, {
                                            trajet,
                                            motif,
                                            onData: (data) => {
                                                setTrajet(data.id, data);
                                            }
                                        }, '600px');
                                    },
                                });
                            }
                        }
                        else if (!motifRequis) {
                            items.annuler.callback = handleMenuLink(Router.generate('regulation.trajet.annuler.ajax', {id: trajet.id}), true, {});
                        }
                    }
                }

                if(!trajet.etatFacture) {
                    items.sep2 = '';
                    items.nePasFacturer = {
                        name: trajet.nePasFacturer ? 'Autoriser facturation' : 'Ne pas facturer',
                        icon: trajet.nePasFacturer ? 'fa-solid fa-check' : 'fa-solid fa-xmark',
                        disabled: ajaxLocked.value,
                        callback: handleMenuLink(Router.generate(trajet.nePasFacturer ? 'regulation.trajet.facturer.ajax' : 'regulation.trajet.ne-pas-facturer.ajax', {id: trajet.id}), true)
                    };
                }
            }

            // Etats
            if(trajet.roles.ROLE_REGULATION_TRANSPORT_EDIT) {
                items.sep3 = '';

                items.etatPrecedent = {name: Translator.trans('action.etat-precedent'), icon: 'fa-solid fa-backward-step', disabled: ajaxLocked.value || !trajet.canPrecedent, callback: handleMenuLink(Router.generate('regulation.trajet.etat-precedent.ajax', {id: trajet.id}), true)};
                items.etatSuivant = {name: Translator.trans('action.etat-suivant'), icon: 'fa-solid fa-forward-step', disabled: ajaxLocked.value || !trajet.canSuivant, callback: handleMenuLink(Router.generate('regulation.trajet.etat-suivant.ajax', {id: trajet.id}), true)};
                items.terminerMission = {name: Translator.trans('action.terminer-mission'), icon: 'fa-solid fa-forward-fast', disabled: ajaxLocked.value || !trajet.canSuivant, callback: handleMenuLink(Router.generate('regulation.trajet.terminer.ajax', {id: trajet.id}), true)};
            }

            if(simultane.value) {
                items.sep4 = '';
                items.validerSimultane = {name: Translator.trans('action.valider-simultane'), icon: 'fa-solid fa-check', callback: () => saveSimultane(), disabled: simultaneLoading.value};
                items.annulerSimultane = {name: Translator.trans('action.annuler-simultane'), icon: 'fa-solid fa-xmark', callback: () => cancelSimultane(), disabled: simultaneLoading.value};
            }
            else if(trajet.simultane) {
                if(trajet.roles.ROLE_REGULATION_TRAJET_SIMULTANE_EDIT) {
                    items.sep4 = '';
                    items.simultane = {name: Translator.trans('action.modifier-simultane'), icon: 'fa-regular fa-square-pen', callback: () => editSimultane(trajet)};
                }
            } else {
                if(trajet.roles.ROLE_REGULATION_TRAJET_SIMULTANE_NEW) {
                    items.sep4 = '';
                    items.simultane = {name: Translator.trans('action.creer-simultane'), icon: 'fa-solid fa-plus', callback: () => editSimultane(trajet)};
                }
            }

            if(trajet.serie) {
                if(trajet.roles.ROLE_REGULATION_SERIE_EDIT) {
                    items.sep4 = '';
                    items.editSerie = {name: Translator.trans('action.modifier-serie'), icon: 'fa-regular fa-calendar-pen', callback: handleMenuLink(Router.generate('shared.serie.edit', {app: 'regulation', id: trajet.serie.id}))};
                }
            } else if(trajet.roles.ROLE_REGULATION_SERIE_NEW) {
                items.sep4 = '';
                items.newSerie = {name: Translator.trans('action.creer-serie'), icon: 'fa-regular fa-calendar-plus', callback: handleMenuLink(Router.generate('shared.serie.new', {app: 'regulation', base: trajet.transport.id}))};
            }

            // Dupliquer / Détacher
            if(trajet.roles.ROLE_REGULATION_TRANSPORT_NEW) {
                items.sep4 = '';
                items.duplicate = {name: Translator.trans('action.dupliquer'), icon:  'fa-regular fa-clone', callback: handleMenuLink(Router.generate('shared.transport.duplicate', {app: 'regulation', base: trajet.transport.id}))};
            }

            if(trajet.roles.ROLE_REGULATION_TRANSPORT_DETACH) {
                items.sep4 = '';
                items.detacherRetour = {name: Translator.trans('action.detacher-retour'), icon: 'fa-regular fa-code-fork', callback: () => {
                        swal({
                            title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                            type: 'warning',
                            text: Translator.trans('shared.transport.confirm.detach'),
                            showCancelButton: true,
                            confirmButtonClass: 'bg-primary',
                            confirmButtonText: Translator.trans('action.detacher-retour'),
                            cancelButtonText: Translator.trans('action.annuler')
                        }).then((result) => {
                            if (result.value) {
                                handleMenuLink(Router.generate('shared.transport.detach', {app: 'regulation', id: trajet.transport.id}))();
                            }
                        });
                    }};
            }

            return {items};
        },
        events: {
            hide: () => {
                focusTrajetIndex.value = null;
            }
        }
    });

    // KEYBOARD
    window.addEventListener('keydown', (e) => {
        if (!$('.swal2-show').length) {
            if(e.key === 'Escape') {
                if (editInPlaceField.value) {
                    editInPlaceField.value = null;
                }
                else if (searchMode.value) {
                    searchMode.value = false;
                } else {
                    selectTrajet(null);
                }
            }
            else if(e.key === ' ') {
                if($(document.activeElement).is(':input')) {
                    return;
                }

                if (currentTrajetElement.value) {
                    currentTrajetElement.value.querySelector('.js-context-menu-btn').click();
                }

                e.preventDefault();
            }
            else if(e.key === 'Enter') {
                if(editInPlaceField.value) {
                    if(editInPlaceFields[editInPlaceField.value].type !== 'autocomplete') {
                        let newIndex = editInPlaceIndex.value + 1;

                        if(newIndex < 0 || newIndex >= Object.keys(editInPlaceFields).length) {
                            newIndex = null;
                        }

                        submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
                    }
                }
                else if(searchMode.value) {
                    stepSearch(e.shiftKey ? -1 : 1);
                } else {
                    // if trajet sélectionné => open context menu
                }
            }
            else if(['ArrowUp', 'ArrowDown'].includes(e.key)) {
                if($(document.activeElement).is(':input')) {
                    return;
                }

                if(searchMode.value) {
                    stepSearch(e.shiftKey ? -1 : 1);
                } else {
                    let index = 0;

                    if (currentTrajetIndex.value !== null) {
                        index = currentTrajetIndex.value + ('ArrowUp' === e.key ? -1 : 1);
                    }

                    selectTrajet(index, true);
                }

                e.preventDefault();
            }
            else if(e.key === 'F3' || (e.key === 'f' && e.ctrlKey)) {
                searchMode.value = !searchMode.value;
                e.preventDefault();
            }
            else if(e.key === 'Tab') {
                if(!editInPlaceLoading.value) {
                    let newIndex = editInPlaceIndex.value === null ? (e.shiftKey ? Object.keys(editInPlaceFields).length - 1 : 0) : editInPlaceIndex.value + (e.shiftKey ? -1 : 1);

                    if(newIndex < 0 || newIndex >= Object.keys(editInPlaceFields).length) {
                        newIndex = null;
                    }

                    submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
                }

                e.preventDefault();
            }
            else if(e.key === 'F1') {
                window.location.href = Router.generate('shared.transport.new', {app: 'regulation'});
                e.preventDefault();
            }
            else if(e.key === 'F2') {
                if(currentTrajet.value) {
                    window.location.href = Router.generate('shared.transport.edit', {app: 'regulation', id: currentTrajet.value.transport.id});
                }
                e.preventDefault();
            }
        }
    });

    initLegacyJs();

    document.dispatchEvent(new Event('app:vuejs:mounted'));
});

const editInPlaceFields = {
    'equipage': {type: 'autocomplete', route: 'regulation.trajet.equipage.ajax', valueField: 'id'},
    'vehicule': {type: 'autocomplete', route: 'regulation.trajet.vehicule.ajax', valueField: 'id'},
    'personnel1': {type: 'autocomplete', route: 'regulation.trajet.personnel.ajax', valueField: 'id'},
    'personnel2': {type: 'autocomplete', route: 'regulation.trajet.personnel.ajax', valueField: 'id'},
    'topDebut': {type: 'top'},
    'topPriseEnChargeArrivee': {type: 'top'},
    'topDestinationArrivee': {type: 'top'},
    'topFin': {type: 'top'}
};

const trajetRows = ref({});

const trajetTab = ref();
const messagerieTab = ref();
const ressourcesTab = ref();

const searchInput = ref();
const searchMode = ref(false);
const searchQuery = ref('');

const searchIndex = ref(null);
const fuseSearch = ref(null);

const editInPlaceField = ref(null);
const editInPlaceValue = ref(null);
const editInPlaceError = ref(null);
const editInPlaceLoading = ref(false);

const focusTrajetIndex = ref(null);
const activeTab = ref(0);
const ressourceDraggingTrajetSource = ref(null);
const ressourceDragging = ref(false);
const ressourceDraggingBureaux = ref([]);
const hasNewRessourceDispo = ref(false);
const ressourceDropSaving = ref(null);


const openTarget = computed(() => {
    return props.modeOuvertureTransport === 1 ? '_blank' : null;
});

const currentTrajetRow = computed(() => {
    if (null !== currentTrajetId.value) {
        return trajetRows.value[currentTrajetId.value] ?? null;
    }

    return null;
});
const currentTrajetElement = computed(() => {
    if(null !== currentTrajetRow.value) {
        return currentTrajetRow.value.el;
    }

    return null;
});
const editInPlaceIndex = computed({
    get() {
        return null !== editInPlaceField.value ? Object.keys(editInPlaceFields).indexOf(editInPlaceField.value) : null;
    },
    set(value) {
        editInPlaceField.value = null !== value ? Object.keys(editInPlaceFields)[value] : null;
    },
});

const searchResults = computed(() => {
    if(!searchMode.value || !fuseSearch.value || searchQuery.value === '') {
        return [];
    }

    return fuseSearch.value.search(searchQuery.value);
});

const currentTrajetIndex = computed({
    get() {
        if (currentTrajetId.value === null) {
            return null;
        }

        return trajetIndexById.value[currentTrajetId.value];
    },
    set(value) {
        if(value === null) {
            currentTrajetId.value = null;
        } else {
            currentTrajetId.value = trajetCollection.value[value].id;
        }
    }
});

const getMessageCallback = (appareilCollection) => {
    return () => {
        activeTab.value = 1;
        nextTick(() => messagerieTab.value.setDestinataires(appareilCollection));
    };
};

const getMessageItem = (ressources) => {
    const appareilCollection = getRessourcesAppareilCollection(ressources);

    return {name: 'Envoyer un message', icon: 'fa-solid fa-comments', disabled: !appareilCollection.length, callback: appareilCollection.length ? getMessageCallback(appareilCollection) : undefined};
};

const openEnvoiPauseSwal = (personnel1, personnel2, vehicule, equipage) => {
    App.Utils.openVueSwal(EnvoiPause, {
        modeles: salaries.value.modeles.pause,
        lieux: salaries.value.lieux,
        personnel1, personnel2, vehicule, equipage
    });
};

const openEnvoiFinServiceSwal = (personnel1, personnel2, vehicule, equipage) => {
    App.Utils.openVueSwal(EnvoiFinService, {
        personnel1, personnel2, vehicule, equipage
    });
};

const cancelPause = (pause, pauseSecondaire) => {
    if(!pause) {
        return;
    }

    swal({
        title: App.Constants.LIBELLE_ANNULER_PAUSE_QUESTION,
        type: 'question',
        showCancelButton: true,
        confirmButtonClass: 'bg-info',
        confirmButtonText: Translator.trans('action.confirmer'),
        cancelButtonText: Translator.trans('action.fermer'),
    }).then((result) => {
        if (result.value) {
            Request.postJson(Router.generate('regulation.supprimer-pause.ajax', {
                pause: pause.id,
                pauseSecondaire: pauseSecondaire ? pauseSecondaire.id : null,
            }), {}).then((data) => {
                if (!data.success) {
                    swal({
                        title: data.title,
                        text: data.text,
                        type: 'warning',
                        confirmButtonClass: 'bg-info',
                        confirmButtonText: Translator.trans('action.fermer'),
                    }).then((result) => {});
                }
            });
        }
    });
};

const openRessourceDetailSwal = (ressource) => {
    const ressourceRef = ref(ressource)

    App.Utils.openVueSwal(RessourceDetail, {
        ressource: ressourceRef,
        currentTrajet,
        onChange: (newRessource) => {
            ressourceRef.value = newRessource;
        },
        onTrajetSelect: (id) => {
            selectTrajetById(id, true);
        },
    });
};

const onRessourceClick = (ressource) => {
    const ids = ressource._trajetCollection.map(trajet => trajet.id);

    if(ids.length) {
        if (!currentTrajetId.value || !ids.includes(currentTrajetId.value)) {
            const id = ressource._trajetInfo.minTrajet ? ressource._trajetInfo.minTrajet.id : ids[0];

            selectTrajetById(id, true);
        } else {
            const currentIndex = ids.indexOf(currentTrajetId.value);
            const newIndex = currentIndex === ids.length - 1 ? 0 : currentIndex + 1;
            selectTrajetById(ids[newIndex], true);
        }
    }
};

const onEditInPlaceUpdate = (value) => {
    editInPlaceValue.value = value;

    if(editInPlaceField.value && editInPlaceFields[editInPlaceField.value].type === 'autocomplete') {
        let newIndex = editInPlaceIndex.value + 1;
        let fieldsByIndex = Object.keys(editInPlaceFields);

        if(editInPlaceField.value === 'equipage' || null === value || !(newIndex in fieldsByIndex)  || editInPlaceFields[fieldsByIndex[newIndex]].type !== 'autocomplete') {
            newIndex = null;
        }

        submitEditInPlace().then(() => editInPlaceIndex.value = newIndex, () => {});
    }
};

const initLegacyJs = () => {
    let $body = $('body');

    // New transport btn
    let $newTransportLink = $('#newTransportLink');
    $newTransportLink.find('a').addClass('js-transport-new');
    const target = $newTransportLink.find('a').attr('target');

    let updateNewTransportDropdown = () => {
        let $dropdown = $('<ul class="hover-menu"></ul>');
        let $items = $('#trajet_filter_structureCollection input[data-action="input"]:checked');
        $newTransportLink.find('.hover-menu').remove();

        if(!$items.length) {
            $newTransportLink.removeClass('hover-menu-wrapper');
            return;
        }

        $newTransportLink.addClass('hover-menu-wrapper');

        $items.each((index, element) => {
            let label = $(element).parent().siblings('label').text();
            let url = Router.generate('shared.transport.new', {app: 'regulation', structure: $(element).val()});

            $dropdown.append('<li><a' + (target ? ' target="' + target + '"' : '') + ' href="' + url + '" class="js-transport-new">' + label + '</a></li>');
        });

        $newTransportLink.append($dropdown);

        updateRendezVous();
    };

    $body.on('change', '#trajet_filter_structureCollection input', updateNewTransportDropdown);

    updateNewTransportDropdown();
};

const submitEditInPlace = () => {
    return new Promise((resolve, reject) => {
        if(!editInPlaceField.value || !currentTrajet.value) {
            return resolve();
        }

        let field = editInPlaceFields[editInPlaceField.value];
        let oldValue = currentTrajet.value[editInPlaceField.value];
        let newValue = editInPlaceValue.value;

        if(field.valueField) {
            oldValue = oldValue ? oldValue[field.valueField] : null;
            newValue = newValue ? newValue[field.valueField] : null;
        }

        if(oldValue === newValue) {
            return resolve();
        }

        editInPlaceLoading.value = true;

        updateTrajet(currentTrajet.value, editInPlaceField.value, newValue).then(() => {
            editInPlaceError.value = null;
            resolve();
        }, (data) => {
            editInPlaceError.value = data;
            editInPlaceLoading.value = false;
            reject();
        });
    });
};

const onRessourceDispo = (ressource) => {
    if(activeTab.value !== 2) {
        hasNewRessourceDispo.value = true;
    }
};

const onMapSelectVehicule = (event) => {
    ressourceDropSaving.value = {trajet: event.trajet, field: 'vehicule'};
    updateTrajet(event.trajet, 'vehicule', event.id).finally(() => {
        ressourceDropSaving.value = null;
    });
};

const onRessourceDrop = (trajet, field, ressource) => {
    if(canDropRessource(trajet, field, ressource)) {
        selectTrajetById(trajet.id);
        ressourceDropSaving.value = {trajet, field};

        updateTrajet(trajet, field, ressource.id).finally(() => {
            ressourceDropSaving.value = null;
        });
    }
};

const onRessourceDragStart = (ressource, trajetSource = null) => {
    ressourceDragging.value = true;
    ressourceDraggingTrajetSource.value = trajetSource;
    ressourceDraggingBureaux.value = ressource.bureauCollection || [ressource.structure];
};

const onRessourceDragEnd = (ressource) => {
    ressourceDragging.value = false;
};

const onRessourceDragLeave = (ressource, valid, event) => {
    event.target.classList.remove('bg-warning');
};

const onRessourceDragEnter = (trajet, field, ressource, valid, event) => {
    if (canDropRessource(trajet, field, ressource)) {
        event.target.classList.add('bg-warning');
    }
};

const canDropRessource = (trajet, field, ressource) => {
    if (ressourceDraggingTrajetSource.value && ressourceDraggingTrajetSource.value === trajet) {
        return false;
    }

    if (ressource) {
        if (field && !field.startsWith(ressource._type)) {
            return false;
        }

        const bureaux = ressource.bureauCollection || [ressource.structure];
        return bureaux.includes(trajet.structure.id);
    }

    return ressourceDraggingBureaux.value.includes(trajet.structure.id);
};

const selectSearchResult = () => {
    if(searchIndex.value in searchResults.value) {
        nextTick(() => {
            selectTrajetById(searchResults.value[searchIndex.value].item.id, true, false);
        });
    }
};

const stepSearch = (direction) => {
    let newIndex = searchIndex.value + direction;
    let total = searchResults.value.length;

    if(total === 0 || newIndex >= total) {
        searchIndex.value = 0;
    }
    else if(newIndex < 0) {
        searchIndex.value = total - 1;
    }
    else {
        searchIndex.value = newIndex;
    }
};

watch(activeTab, () => {
    if (activeTab.value === 2) {
        hasNewRessourceDispo.value = false;
    }
    else if(activeTab.value === 1) {
        hasUnreadMessage.value = false;
        nextTick(() => {
            messagerieTab.value.refresh();
            messagerieTab.value.scrollBottom();
        });
    }
});

const selectTrajet = (index, scroll = false, smooth = true) => {
    if (null === index || index in trajetCollection.value) {
        currentTrajetIndex.value = index;

        if (null !== index && scroll && currentTrajetElement.value) {
            currentTrajetElement.value.scrollIntoView({
                behavior: smooth ? 'smooth' : 'instant',
                block: 'center',
            });
        }
    }
};

const selectTrajetById = (id, scroll = false, smooth = true) => {
    selectTrajet(trajetIndexById.value[id], scroll, smooth);
};

watch (hasUnreadMessage, () => {
    if(activeTab.value === 1) {
        hasUnreadMessage.value = false;
    }
});

watch(currentTrajet, () => {
    if (currentTrajet.value && editInPlaceField.value) {
        const value = currentTrajet.value[editInPlaceField.value];
        const type = editInPlaceFields[editInPlaceField.value].type;

        let newValue = value;

        if (null !== value) {
            if (type === 'top') {
                newValue = value.time;
            }
            else if (type === 'autocomplete') {
                newValue = currentTrajet.value['_'+editInPlaceField.value];
            }
        }
        editInPlaceValue.value = newValue;
    }
});

watch(editInPlaceField, () => {
    editInPlaceError.value = null;
    editInPlaceLoading.value = false;

    if(editInPlaceField.value && currentTrajet.value) {
        const value = currentTrajet.value[editInPlaceField.value];
        const type = editInPlaceFields[editInPlaceField.value].type;

        let newValue = value;

        if (null !== value) {
            if (type === 'top') {
                newValue = value.time;
            }
            else if (type === 'autocomplete') {
                newValue = currentTrajet.value['_'+editInPlaceField.value];
            }
        }
        editInPlaceValue.value = newValue;
    } else {
        editInPlaceValue.value = null;
    }
});

watch(trajetCollection, () => {
    fuseSearch.value = new Fuse(trajetCollection.value, {
        includeMatches: true,
        keys: ['rendezVous.time', 'transport.patient.text', 'adressePriseEnCharge', 'villePriseEnCharge', 'adresseDestination', 'villeDestination', 'transport.motif.abreviation', 'transport.note', 'transport.patient.assure.note', 'transport.patient.assure.notePublique', 'transport.patient.beneficiaire.note', 'transport.patient.beneficiaire.notePublique'],
        minMatchCharLength: 1,
        tokenize: true,
        threshold: 0,
    });
});

watch(searchMode, () => {
    if (searchMode.value) {
        searchInput.value.focus();
    } else {
        searchQuery.value = '';
        searchInput.value.blur();
    }
});

const updateRendezVous = () => {
    $('.js-transport-new').each((i,el) => {
        let newUrl = $(el).attr('href').replace(/\?.*/,'');

        if(rendezVousInput.value !== Moment().format('DD/MM/YYYY')) {
            newUrl += '?date='+ encodeURIComponent(rendezVousInput.value);
        }

        $(el).attr('href', newUrl);
    });

    document.title = rendezVousInput.value + ' - '+ document.title.substring(document.title.indexOf('Trajets - '));
}
watch(rendezVous, updateRendezVous);

watch(currentTrajetId, () => {
    editInPlaceField.value = null;
});

watch(searchResults, () => {
    if(!(searchIndex.value in searchResults.value)) {
        searchIndex.value = searchResults.value.length ? 0 : null;
    }
    selectSearchResult();
});

watch(searchIndex, () => {
    selectSearchResult();
});

provide('cancelPause', cancelPause);
provide('openRessourceDetailSwal', openRessourceDetailSwal);
provide('openEnvoiPauseSwal', openEnvoiPauseSwal);
provide('openEnvoiFinServiceSwal', openEnvoiFinServiceSwal);
provide('canDropRessource', canDropRessource);
provide('onRessourceDrop', onRessourceDrop);
provide('onRessourceDragStart', onRessourceDragStart);
provide('onRessourceDragEnd', onRessourceDragEnd);
provide('onRessourceDragEnter', onRessourceDragEnter);
provide('onRessourceDragLeave', onRessourceDragLeave);
provide('editInPlaceField', editInPlaceField);
provide('editInPlaceFields', editInPlaceFields);
provide('getMessageItem', getMessageItem);
provide('selectTrajetById', selectTrajetById);

</script>