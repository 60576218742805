'use strict';

import sha1 from 'js-sha1';
import Translator from "@/App/Translator";
import ladda from "ladda";

App.Shared.Serie = class {
    static initTabs() {
        $('#serieTabs a').on('click', function(e) {
            if (!$(e.target).hasClass('js-context-menu-btn')) {
                e.preventDefault();
                $(this).tab('show');
                $('#serieTabs a').removeClass('active');
                $(this).addClass('active');
            }
        });

        $.contextMenu({
            selector: '#serieTabs a',
            build: ($trigger, event) => {
                const items = {};

                items.jour = {
                    name: $trigger.find('.serie-day-title').text(),
                    disabled: true,
                }
                items.separator = '';

                const jour = $trigger.data('jour');
                const semaine = $trigger.data('semaine');

                const $pane = $('.tab-pane[data-jour="'+jour+'"][data-semaine="'+semaine+'"]');
                const $activePane = $('.tab-pane.active');

                const $activeAller = $activePane.find('.js-trajet-panel[data-type="aller"]');
                const $activeRetour = $activePane.find('.js-trajet-panel[data-type="retour"]');

                const $aller = $pane.find('.js-trajet-panel[data-type="aller"]');
                const $retour = $pane.find('.js-trajet-panel[data-type="retour"]');

                const hasAller = $aller.find('.js-trajet-panel-placeholder input').is(':checked');
                const hasRetour = $retour.find('.js-trajet-panel-placeholder input').is(':checked');

                if(jour !== $activePane.data('jour') || semaine !== $activePane.data('semaine')) {
                    items.copierAller = {
                        name: 'Recopier l\'aller',
                        disabled: !hasAller,
                        icon: 'fa-solid fa-arrow-right',
                        callback: () => App.Shared.Serie.recopier($aller, $activeAller),
                    };
                    items.copierRetour = {
                        name: 'Recopier le retour',
                        disabled: !hasRetour,
                        icon: 'fa-solid fa-arrow-left',
                        callback: () => App.Shared.Serie.recopier($retour, $activeRetour),
                    };
                    items.copierAllerRetour = {
                        name: 'Recopier l\'aller et le retour',
                        disabled: !hasAller || !hasRetour,
                        icon: 'fa-solid fa-arrows-left-right',
                        callback: () => {
                            App.Shared.Serie.recopier($aller, $activeAller);
                            App.Shared.Serie.recopier($retour, $activeRetour);
                        },
                    };

                    items.separator2 = '';
                }

                items.effacer = {
                    name: 'Effacer',
                    disabled: !hasAller && !hasRetour,
                    icon: 'fa-solid fa-trash-can',
                    callback: () => $pane.find('.js-trajet-panel-placeholder input').prop('checked', false).change(),
                };

                return {
                    items: items
                };
            },
        });
    }

    static recopier($source, $destination) {
        const $toggle = $destination.find('.js-trajet-panel-placeholder input');
        $toggle.prop('checked', true);

        $source.find('[data-field]').each((i, el) => {
            const $el = $(el);
            const field = $el.data('field');

            if ('rendezVousDate' === field) {
                return;
            }

            const $adresseContainer = $el.parents('[data-adresse-type]');

            let $destinationEl = $destination;

            if ($adresseContainer.length) {
                $destinationEl = $destinationEl.find('[data-adresse-type="'+$adresseContainer.data('adresseType')+'"]');
            }

            $destinationEl = $destinationEl.find('[data-field="'+field+'"]');

            if ($destinationEl.hasClass('js-select2-autocomplete')) {
                const $option = $el.find('option:selected');
                if ($option.length) {
                    const newOption = new Option($option.text(), $option.val(), true, true);
                    $destinationEl.html(newOption);
                    $destinationEl.trigger({
                        type: 'select2:select',
                    });
                }
                $destinationEl.data('custom', $el.data('custom'));
            } else if ($destinationEl.hasClass('tt-input')) {
                $destinationEl.typeahead('val', $el.val());
            } else {
                $destinationEl.val($el.val());
            }
        });

        $destination.find('[data-adresse-type="PriseEnCharge"] [data-field="precision"]').change();
        $destination.find('[data-adresse-type="Destination"] [data-field="precision"]').change();

        App.Shared.updateTopSuggestions($destination);

        $toggle.change();
    }

    static initTrajets() {
        App.Shared.initTrajetTarification();
        App.Shared.initTrajetTops();
        App.Shared.initDistancierBtn();
        App.Shared.initTrajetAdresseBtn();
        App.Shared.Serie.initTabs();

        // bloquer retour sans aller
        // fill retour avec aller

        $('#serie_semaine2_enabled').change((e) => {
            if(!$(e.currentTarget).is(':checked') && $('#serieTabs a.active').parent().parent().hasClass('js-serie-bihebdomadaire')) {
                $('#serieTabs a').first().click();
            }
        });

        $('.js-trajet-panel').each((index, element) => {
            let $panel = $(element);
            let $placeholder = $panel.find('.js-trajet-panel-placeholder');
            let $toggleInput = $placeholder.find('input');
            let $content = $panel.find('.js-trajet-panel-content');

            // Gestion du toggle
            $toggleInput.on('change', (e) => {
                let checked = $(e.currentTarget).prop('checked');

                $content.toggle(checked);
                $placeholder.toggle(!checked);

                if(checked) {
                    App.Shared.updateTrajetTarification($content);
                    $content.find('[data-required=true]').prop('required', true);
                } else {
                    App.Utils.clearAllInputs($content);
                    $content.find('[required]').prop('required', false);

                }
            });

            $content.find('.js-trajet-panel-close').click(() => {
                $toggleInput.prop('checked', false).change();
                return false;
            });

            // Gestion des champs requis
            let $requiredInputs = $content.find('[required="required"]').attr('data-required', true);

            if(!$toggleInput.prop('checked')) {
                $requiredInputs.removeAttr('required');
            }
        });

        $('body').on('change', () => {
            App.Shared.Serie.updateEtiquettes();
        });

        App.Shared.Serie.updateEtiquettes();
    }

    static updateEtiquettes() {
        let etiquettes = App.Shared.Serie.getEtiquettes();

        $('.serie-day-trajet').each((index, element) => {
            let $element = $(element);
            let jour = $element.closest('.serie-day').data('jour');
            let semaine = $element.closest('.serie-day').data('semaine');
            let type = $element.data('type');

            $element.find('.serie-day-trajet-top').each((index, top) => {
                let $top = $(top);
                let mode = $top.data('mode');
                let data = etiquettes.mapping[semaine][jour][type][mode];
                let values = data ? etiquettes.tags[data.tag] : {};

                $top.html(data ? ((data.top ? data.top : '-')+(data.tag ? (' <span title="'+ (values.complementAdresse ? values.complementAdresse + "\n" : '') + values.adresse + "\n" + values.codePostal + (values.ville ? ' ' + values.ville : '') +'">'+data.tag+'</span>') : '')):'');
            });

            $element.find('.serie-day-trajet-type').toggleClass('text-success', etiquettes.mapping[semaine][jour][type] !== false);
        });

        $('.js-trajet-adresse-presets').each((index, element) => {
            let $element = $(element);
            let jour = $element.closest('.tab-pane').data('jour');
            let semaine = $element.closest('.tab-pane').data('semaine');
            let type = $element.closest('.js-trajet-panel').data('type');
            let mode = $element.data('adresseType');

            $element.html('');

            for(let tag in etiquettes.tags) {
                let data = etiquettes.mapping[semaine][jour][type][mode];
                let values = etiquettes.tags[tag];

                let $button = $('<span class="js-trajet-adresse-btn font-weight-bold btn btn-sm p-0 px-2" data-source="tag"></span>');
                $button.text(tag);
                $button.attr('title', (values.complementAdresse ? values.complementAdresse + "\n" : '') + values.adresse + "\n" + values.codePostal + (values.ville ? ' ' + values.ville : ''));
                if(data && data.tag === tag) {
                    $button.addClass('text-theme');
                }

                $element.append($button);
            }
        });

        App.Shared.Serie.etiquettes = etiquettes;
    }

    static getEtiquettes() {
        let tagIndex = 'A'.charCodeAt(0);
        let tagsByHash = {};
        let tags = {};
        let mapping = {};

        $('.js-trajet-panel [data-adresse-type]').each((index, form) => {
            let $form = $(form);
            let jour = $form.closest('.tab-pane').data('jour');
            let semaine = $form.closest('.tab-pane').data('semaine');
            let type = $form.closest('.js-trajet-panel').data('type');
            let mode = $form.data('adresseType');
            let enabled = $form.closest('.js-trajet-panel').find('.panel-enable input').prop('checked');

            if(!(semaine in mapping)) {
                mapping[semaine] = {};
            }

            if(!(jour in mapping[semaine])) {
                mapping[semaine][jour] = {};
            }

            if(!(type in mapping[semaine][jour])) {
                mapping[semaine][jour][type] = enabled ? {} : false;
            }

            if(enabled) {
                let data = App.Shared.Serie.getCoordonneeGeographiqueData($form);

                mapping[semaine][jour][type][mode] = {
                    tag: null,
                    top: $form.closest('.form-body').find('[data-field="top'+mode+'Arrivee"]').val(),
                };

                if(null !== data) {
                    if(!(data.hash in tagsByHash)) {
                        let tag = String.fromCharCode(tagIndex++);
                        tagsByHash[data.hash] = tag;
                        tags[tag] = data.values;
                    }

                    mapping[semaine][jour][type][mode].tag = tagsByHash[data.hash];
                }
            }
        });

        return {
            mapping, tags
        };
    }

    static getCoordonneeGeographiqueData($form) {
        let hasValue = false;
        let hash = '';
        let values = {};

        for(let field of [
            'adresse',
            'complementAdresse',
            'ville',
            'codePostal',
            'codeInsee',
            'digicode',
            'etage',
            'precision',
            'latitudeDegre',
            'longitudeDegre',
        ]) {
            let value = $form.find('[data-field="'+field+'"]').val();

            if(value) {
                hasValue = true;
            }
            hash += value+'|';
            values[field] = value;
        }

        return hasValue ? {
            hash: sha1(hash),
            values
        } : null;
    }

    static initGeneratePopover(content) {
        let ajaxRequest = null;

        $('body').on('click', '#generateConfirmBtn', (e) => {
            let btnLadda = ladda.create(e.currentTarget);
            btnLadda.start();

            let $form = $('#generateForm');

            if(ajaxRequest) {
                ajaxRequest.abort();
                ajaxRequest = null;
            }

            ajaxRequest = $.ajax({
                url: $form.attr('action'),
                method: 'POST',
                data: $form.serialize(),
            }).done((data) => {
                if(data.html) {
                    $form.replaceWith(data.html);
                }
                if(data.form) {
                    App.Layout.enableCustomInputs();
                }
                else if(data.job) {
                    App.Job.Assistant.handleJob(data.job);
                }

                $('#generateBtn').popover('update');
            }).fail((request) => {

            }).always(() => {
                if(btnLadda) {
                    btnLadda.stop();
                }
            });
        }).on('click', function (e) {
            if (!$(e.target).is('#generateBtn') && $(e.target).parents('#generateBtn').length === 0 && $(e.target).parents('.air-datepicker-global-container').length === 0 && $(e.target).parent().length &&  $(e.target).parents('.popover.show').length === 0) {
                $('#generateBtn').popover('hide');
            }
        });

        $('#generateBtn').popover({
            placement: 'bottom',
            content,
            sanitize: false,
            html: true,
            title: Translator.trans('action.generer-serie')
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Layout.enableCustomInputs();
        });
    }

    static initCleanPopover(content) {
        let ajaxRequest = null;

        $('body').on('click', '#cleanConfirmBtn', (e) => {
            let btnLadda = ladda.create(e.currentTarget);
            btnLadda.start();

            let $form = $('#cleanForm');

            if(ajaxRequest) {
                ajaxRequest.abort();
                ajaxRequest = null;
            }

            ajaxRequest = $.ajax({
                url: $form.attr('action'),
                method: 'POST',
                data: $form.serialize(),
            }).done((data) => {
                if(data.html) {
                    $form.replaceWith(data.html);
                }
                if(data.form) {
                    App.Layout.enableCustomInputs();
                }

                $('#cleanFormBtn').popover('update');
            }).fail((request) => {

            }).always(() => {
                if(btnLadda) {
                    btnLadda.stop();
                }
            });
        }).on('click', function (e) {
            if (!$(e.target).is('#cleanBtn') && $(e.target).parents('#cleanBtn').length === 0 && $(e.target).parents('.air-datepicker-global-container').length === 0 && $(e.target).parent().length &&  $(e.target).parents('.popover.show').length === 0) {
                $('#cleanBtn').popover('hide');
            }
        });

        $('#cleanBtn').popover({
            placement: 'bottom',
            content,
            sanitize: false,
            html: true,
            title: 'Nettoyer les trajets futurs'
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Layout.enableCustomInputs();
        });
    }
};

App.Shared.Serie.View = class {
    constructor(params) {
        App.Shared.Serie.initTabs();
        App.Shared.initTrajetComplementToggle();
        App.Shared.Serie.initGeneratePopover(params.generateContent);
        App.Shared.Serie.initCleanPopover(params.cleanContent);
    }
};

App.Shared.Serie.Form = class {
    constructor(params) {
        App.Shared.Serie.initTrajets();
        App.Shared.initPatient();
        App.Shared.initAppelant(params.paths.ajaxAppelant);
        App.Shared.initPrescripteur(params.paths.ajaxPrescripteur, params.form.prescripteurLink, params.form.prescripteurForm);
        App.Shared.initTypeFacture(params.form.prescripteurForm);
        App.Shared.initTransportStructure(params.form.structureName, params.form.prescripteurForm, params.form.prescripteurLink);
        App.Shared.updateCategoriePreset();

        this._initAjax(params.paths.ajaxForm);
    }

    _initAjax(ajaxFormUrl) {
        this.formAjaxTimeout = null;
        this.formAjaxRequest = null;

        $('body').on('change', '#form', () => {
            if(this.formAjaxTimeout) {
                clearTimeout(this.formAjaxTimeout);
            }

            this.formAjaxTimeout = setTimeout(() => {
                this._updateAjax(ajaxFormUrl);
                this.formAjaxTimeout = null;
            }, 10);
        });
    }

    _updateAjax(ajaxFormUrl) {
        let $form = $('#form');
        $('body').addClass('cursor-progress');

        if(this.formAjaxRequest) {
            this.formAjaxRequest.abort();
            this.formAjaxRequest = null;
        }

        this.formAjaxRequest = $.ajax({
            url: ajaxFormUrl,
            method: 'POST',
            data: $form.serialize()
        }).always(function() {
            $('body').removeClass('cursor-progress');
        }).done((data) => {
            App.Shared.handleTrajetTarificationGrilleDetail($('.js-trajet-panel'), data.grilleDetail);
        }).fail(function(e) {
        });
    }
};
