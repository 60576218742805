import SefiPecCalculer from '@/Vue/Components/Sefi/Pec/Calculer'
import SefiFtValider from '@/Vue/Components/Sefi/Ft/Valider'
import SefiFtConsulter from '@/Vue/Components/Sefi/Ft/Consulter'
import SefiFtControlerRecevabilite from '@/Vue/Components/Sefi/Ft/ControlerRecevabilite'
import SefiFtCalculerMontantsPrestation from '@/Vue/Components/Sefi/Ft/CalculerMontantsPrestation'
import SefiBsRechercherEtatCivil from '@/Vue/Components/Sefi/Bs/RechercherEtatCivil'
import SefiBsListerBeneficiaires from '@/Vue/Components/Sefi/Bs/ListerBeneficiaires'
import SefiMetaPatient from '@/Vue/Components/Sefi/Meta/Patient'
import SefiPlAcquerirPrescription from '@/Vue/Components/Sefi/Pl/AcquerirPrescription'
import SefiPsRechercherPrescripteur from '@/Vue/Components/Sefi/Ps/RechercherPrescripteur'
import SefiPsRechercherDonneesTransporteur from '@/Vue/Components/Sefi/Ps/RechercherDonneesTransporteur'

App.Sefi = class {
    static openRequestSwal(service, action, data = null, response = null) {
        const component = {
            meta: {
                'patient': SefiMetaPatient,
            },
            pec: {
                'calculer': SefiPecCalculer,
            },
            ft: {
                'valider': SefiFtValider,
                'consulter': SefiFtConsulter,
                'controler-recevabilite': SefiFtControlerRecevabilite,
                'calculer-montants-prestation': SefiFtCalculerMontantsPrestation,
            },
            pl: {
                'acquerir-prescription': SefiPlAcquerirPrescription,
            },
            ps: {
                'rechercher-prescripteur': SefiPsRechercherPrescripteur,
                'rechercher-donnees-transporteur': SefiPsRechercherDonneesTransporteur,
            },
            bs: {
                'rechercher-etat-civil': SefiBsRechercherEtatCivil,
                'lister-beneficiaires': SefiBsListerBeneficiaires
            }
        }[service][action];

        const hasData = data !== null;
        const hasResponse = response !== null;

        let vueData = {}

        if(hasData) {
            vueData.data = data;
        }

        if(hasResponse) {
            vueData.response = response;
        }

        App.Utils.openVueSwal(component, vueData);
    }
}
