import swal from 'sweetalert2';
import Shuffle from 'shufflejs';
import Cookies from 'js-cookie';
import 'password-strength-meter';
import '../lib/select2';
import '../lib/select2-fr';
import 'corejs-typeahead'
import Bloodhound from 'bloodhound-js'
import AirDatepicker from 'air-datepicker'
import AirDatepickerLocaleFr from 'air-datepicker/locale/fr';
import 'clockpicker/dist/jquery-clockpicker';
import autosize from 'autosize';
import '../lib/jquery.tree-select.js';
import '../lib/jquery-scrolltofixed';
import 'jquery-contextmenu';
import 'jquery-contextmenu/dist/jquery.ui.position.min';
import 'jquery.animate-number';
import PerfectScrollbar from 'perfect-scrollbar';
import 'trumbowyg/dist/trumbowyg.min';
import 'trumbowyg/dist/plugins/history/trumbowyg.history.min';
import 'trumbowyg/dist/langs/fr.min';
import icons from 'trumbowyg/dist/ui/icons.svg';
import Inputmask from 'inputmask';
import hotkeys from 'hotkeys-js';
import bsCustomFileInput from 'bs-custom-file-input';
import ladda from 'ladda';
import Router from "@/App/Router";
import Clipboard from 'clipboard';
import Translator from "@/App/Translator";
import Capitalize from '../Vue/Filters/Capitalize';
import Moment from "moment";
import Euro from "@/Vue/Filters/Euro";
import {createApp, h, reactive} from "vue";
import Geocodage from "@/Vue/Components/Geocodage.vue";
import IndicateurGeocodage from '@/Vue/Components/IndicateurGeocodage.vue';
import GlobalFileUpload from "@/Vue/Components/GlobalFileUpload.vue";
import AmeliProSearch from "@/Vue/Components/AmeliProSearch.vue";

$.trumbowyg.svgPath = icons;

Moment.updateLocale('fr', {
    relativeTime: {
        future: 'dans %s',
        past: 'il y a %s',
        s: '%d sec',
        m: '1 min',
        mm: '%d min',
        h: '1 h',
        hh: '%d h',
        d: '1 j ',
        dd: '%d j',
        M: 'un mois',
        MM: '%d mois',
        y: 'un an',
        yy: '%d ans'
    }
});
App.Layout = class {
    static initApp(layout) {
        // Fixes for turbo
        this.treeSelectCache = null;

        if('base' === layout) {
            this.enableClock();
            this.enableSubmitBtn();
            this.enableDeleteBtn();
            this.enableConfirmBtn();
            this.enableEditBtn();
            this.enableCardBtnEmpty();
            this.enableEmbed();
            this.enableLayout();
            this.enableToggle();
            this.enableSelect2();
            this.enableTreeSelect();
            this.enablePasswordStrength();
            this.initCustomInputs();
            this.enableCustomInputs();
            this.enableMontantHelper();
            this.enableAutosize();
            this.enableBtnGroup();
            this.enableBtnReset();
            this.enableGeocodage();
            this.enableShuffle();
            this.enableTableSelectable();
            this.enableAdvancedSearch();
            this.enableFocusField();
            this.enableClearFields();
            this.enableEtatValidePopover();
            this.enableDatatables();
            this.enableVisibilityDelayed();
            this.enableTooltip();
            this.enablePerfectScrollbar();
            this.enableTrumbowyg();
            this.enableEmbedInline();
            this.enableSwal();
            this.enablePeriode();
            this.enableDateRange();
            this.enableContextMenu();
            this.enableHotkeys();
            this.enablePrint();
            this.enableRadioCycle();
            this.enableSticky();
            this.enableClipboard();
            this.enableDynamicFields();
            this.enableMontantFields();
            this.enableRemiseFields();
            this.enableColorPalette();
            this.enableSefiBtn();
            this.enableAmeliProBtn();
            this.enableGlobalFileUpload();
            this.enableRangeTag();
            this.enableIndicateurGeocodage();
        }
        else if ('auth' === layout) {
            this.enableApps();
            this.enableSelect2();
            this.enableTreeSelect();
            this.enablePasswordStrength();
            this.enableCustomInputs();
            this.enableVisibilityDelayed();
            this.enableTooltip();
            this.enableConfirmBtn();
        }
    }

    static enableApps() {
        $('.js-btn-app').click((e) => {
            const theme = e.currentTarget.dataset.theme;

            $('#wrapper').addClass('login-register-gradient-theme-'+theme);
        });
    }

    static openGlobalFileUploadPopup() {
        App.Utils.openVueSwal(GlobalFileUpload);
    }

    static enableGlobalFileUpload() {
        if(App.Constants.ROLE_PIECE_UPLOAD) {
            $(document)
                .on('dragover', (e) => {
                    const $dropzone = $('#dropzone');
                    const $target = $(e.target);

                    if ($target.is('input[type="file"]')) {
                        // hide splash
                    } else {
                        e.preventDefault();

                        if ($dropzone.length) {
                            $dropzone.addClass('dz-drag-hover');
                        } else {
                            // show splash
                        }
                    }
                })
                .on('drop', (e) => {
                    const $dropzone = $('#dropzone');
                    const $target = $(e.target);

                    if (!$target.is('input[type="file"]') && e.originalEvent.dataTransfer && e.originalEvent.dataTransfer.files.length) {
                        e.preventDefault();

                        if ($dropzone.length) {
                            $dropzone.removeClass('dz-drag-hover');
                            $dropzone[0].dropzone.handleFiles(e.originalEvent.dataTransfer.files);
                        } else {
                            document.getElementById('globalUploadInput').files = e.originalEvent.dataTransfer.files;
                            App.Layout.openGlobalFileUploadPopup();
                        }
                    }
                })
                .on('dragleave', (e) => {
                    const $dropzone = $('#dropzone');
                    if ($dropzone.length) {
                        $dropzone.removeClass('dz-drag-hover');
                    } else {
                        // hide splash
                    }
                })
                .on('click', '.js-upload-popup-btn', () => {
                    App.Layout.openGlobalFileUploadPopup();
                    return false;
                })
            ;
        }
    }

    static enableSefiBtn() {
        $('body').on('click', '.js-sefi-btn', (e) => {
            const $el = $(e.currentTarget);
            const service = $el.data('sefiService');
            const action = $el.data('sefiAction');
            const data = $el.data('sefiData');

            App.Sefi.openRequestSwal(service, action, data);

            return false;
        });
    }

    static enableAmeliProBtn() {
        $('body').on('click', '.js-amelipro-btn', (e) => {
            const $el = $(e.currentTarget);

            App.Utils.openVueSwal(AmeliProSearch, {
                source: $el.data('source')
            });

            return false;
        });
    }

    static enableRangeTag() {
        $('body').on('input', '.js-range-tag', (e) => {
            const $el = $(e.currentTarget);
            $el.parent().parent().find('.js-range-tag-value').html($el.val());
        });
    }

    static enableColorPalette() {
        $('body').on('change', '.js-palette-dropdown input', function() {
            const value = $(this).val();
            const $dropdownBtn = $(this).parents('.js-palette-dropdown').find('.js-palette-dropdown-btn');

            $dropdownBtn.find('.swatches__item__content').css({'background-color': value ? ('#'+value) : ''});
            $dropdownBtn.find('.swatches__item__strike').toggle(!value);
        });
    }

    static enableRemiseFields() {
        $('body')
            .on('click', '[data-toggle-remise-mode]', (event) => {
                const $el = $(event.currentTarget);
                const $group = $el.closest('.input-group');
                const toggleIndex = $group.find('[data-toggle-remise-index]').data('toggleRemiseIndex');
                const currentIndex = toggleIndex === 1 ? 2 : 1;

                $group.find('[data-toggle-remise-mode]').toggle();
                $group.find('.js-remise').hide();
                $group.find('[data-remise-index="'+currentIndex+'"]').val('').change();
                $group.find('[data-remise-index="'+currentIndex+'"][data-remise-mode="'+$el.data('toggleRemiseMode')+'"]').show();
            })
            .on('change', '.js-remise', (event) => {
                const $el = $(event.currentTarget);
                const $group = $el.closest('.input-group');
                const $toggleIndexBtn = $group.find('[data-toggle-remise-index]');
                const currentIndex = $toggleIndexBtn.data('toggleRemiseIndex') === 1 ? 2 : 1;
                const $montantField = $group.find('.js-remise[data-remise-mode="montant"][data-remise-index="'+currentIndex+'"]');
                const $pourcentField = $group.find('.js-remise[data-remise-mode="pourcent"][data-remise-index="'+currentIndex+'"]');

                $toggleIndexBtn.find('[data-remise-index="'+currentIndex+'"]').toggleClass('text-theme', $montantField.val() !== '' || $pourcentField.val() !== '');
            })
            .on('click', '[data-toggle-remise-index]', (event) => {
                const $el = $(event.currentTarget);
                const $group = $el.closest('.input-group');
                const toggleIndex = $el.data('toggleRemiseIndex');
                const $montantField = $group.find('.js-remise[data-remise-mode="montant"][data-remise-index="'+toggleIndex+'"]');
                const $pourcentField = $group.find('.js-remise[data-remise-mode="pourcent"][data-remise-index="'+toggleIndex+'"]');

                const mode = $pourcentField.val() !== '' || $montantField.val() === '' ? 'pourcent' : 'montant';

                $group.find('.js-remise').hide();
                $group.find('[data-remise-index="'+toggleIndex+'"][data-remise-mode="'+mode+'"]').show();
                $group.find('[data-toggle-remise-mode]').show();
                $group.find('[data-toggle-remise-mode="'+mode+'"]').hide();
                $el.find('[data-remise-index]').removeClass('font-weight-bold');
                $el.find('[data-remise-index="'+toggleIndex+'"]').addClass('font-weight-bold');
                $el.data('toggleRemiseIndex', toggleIndex === 1 ? 2 :1);
            })
        ;
    }

    static enableMontantFields() {
        $('body').on('click', '[data-toggle-montant]', (e) => {
            const $el = $(e.currentTarget);
            const $parent = $el.parents('.js-montant-ht-ttc');
            const target = $el.data('toggleMontant');

            $parent.find('[data-toggle-montant]').show();
            $parent.find('[data-toggle-montant="'+target+'"]').hide();

            const $currentField = $parent.find('[data-field]:visible');
            $parent.find('[data-field="'+target+'"]').val($currentField.val()).show();
            $currentField.hide().val('').change();
        });
    }

    static enableDynamicFields() {
        $('body').on('change', 'input[type="checkbox"], input[type="radio"], select', (e) => {
            const $el = $(e.currentTarget);
            let field = $el.data('syncField');

            if(!field) {
                if($el.is('select')) {
                    field = $el.data('syncField');
                } else if($el.parents('.btn-group')) {
                    field = $el.parents('.btn-group').data('syncField');
                }
            }

            if(field) {
                App.Utils.setField(field, $el.val(), $el.data('label'), $el.data('custom'));
            }
        });
    }

    static enableRadioCycle() {
        $('.btn-group-cycle .btn').click((e) => {
            let $label = $(e.currentTarget);

            let $next = $label.next();

            if(!$next.length) {
                $next = $label.prevAll().last();
            }

            $next.button('toggle');

            return false;
        });
    }

    static enablePrint() {
        $('.js-print-btn:not(.js-print-btn-active)').addClass('js-print-btn-active').each(function() {
            let $element = $(this);
            let url = $element.data('printUrl');
            let title = $element.data('printTitle');

            let printBtnLadda = null;

            if(!$element.hasClass('btn-table')) {
                printBtnLadda = ladda.create(this);
            }

            $element.click(function() {
                if(printBtnLadda) {
                    printBtnLadda.start();
                } else {
                    $element.addClass('disabled');
                }

                App.Utils.print(url, title).then(() => {
                    if(printBtnLadda) {
                        printBtnLadda.stop();
                    } else {
                        $element.removeClass('disabled');
                    }
                });
            });
        });
    }

    static enableContextMenu() {
        $('body').on('click', '.js-context-menu-btn', function() {
            let position = $(this).offset();
            $(this).contextMenu({y: position.top + $(this).outerHeight(), x: position.left + $(this).outerWidth()});
            return false;
        });
    }

    static enableHotkeys() {
        hotkeys('ctrl+alt+r', () => {
            window.location.href = Router.generate('reload-user');
        });
    }

    static enablePeriode() {
        $('body').on('change', '[data-periode-field]', function() {
            let moment = require('moment');

            let $parent = $(this).parent().parent();
            let $debut = $parent.find('[data-periode-field="debut"]');
            let $duree = $parent.find('[data-periode-field="duree"]');
            let $fin = $parent.find('[data-periode-field="fin"]');

            let debut = moment($debut.val(), 'HH:mm');
            let fin = moment($fin.val(), 'HH:mm');

            if($(this).is($duree) && debut.isValid()) {
                fin = debut.clone().add($duree.val(), 'minutes');
                $fin.val(fin.format('HH:mm'));
            }

            if(debut.isValid() && fin.isValid()) {
                $parent.find('.js-periode-sup').toggle(fin < debut);
                fin.add(fin < debut ? 1 : 0, 'day');

                $duree.val(Math.abs(fin.diff(debut, 'minutes')));
            }
        });
    }

    static enableDateRange() {
        const $body = $('body');

        const startInputSelector = 'input[data-input-range="start"]';
        const endInputSelector = 'input[data-input-range="end"]';
        const rangeButtonSelector = '.btn-range';

        $body.on('click', rangeButtonSelector, (event) => {
            const $group = $(event.currentTarget).parents('.input-group').last();
            const $start = $group.find(startInputSelector);
            const $end = $group.find(endInputSelector);

            $end.val($start.val())
            $end[0].dispatchEvent(new Event('input'))
        })
        .on('change', startInputSelector, (event) => {
            const $start = $(event.currentTarget);
            const $group = $start.parents('.input-group').last();
            const $end = $group.find(endInputSelector);

            const startDate = Moment($start.val(), App.Date.getFormat($start.data('format')));
            const endDate = Moment($end.val(), App.Date.getFormat($end.data('format')));

            if ($start.val() && !$end.val() || startDate.isAfter(endDate)) {
                $end.val($start.val());
                $end[0].dispatchEvent(new Event('input'))
            }

            $group.find(rangeButtonSelector).prop('disabled', !$start.val());
        });

        $('.btn-range').each((i, el) => {
            const $group = $(el).parents('.input-group').last();

            if (!$group.find(startInputSelector).val()) {
                $group.find(rangeButtonSelector).prop('disabled', true);
            }
        });
    }

    static enableSwal() {
        $('body').on('click', '.js-close-swal', function() {
            swal.clickCancel();
        });
    }

    static enablePerfectScrollbar()
    {
        $('.js-perfect-scrollbar:not(.ps)').each(function () {
            new PerfectScrollbar(this, {
                wheelPropagation: false,
            });
        });
    }

    static enableDatatables()
    {
        let pageLength = 15;

        $.fn.dataTable.ext.classes.sPageButton = 'page-link d-inline-flex px-3';
        $.fn.dataTable.ext.classes.sPageButtonActive = 'bg-theme border-theme text-white';
        $.fn.dataTable.ext.classes.sPaging = 'justify-content-center d-flex pagination ' + $.fn.dataTable.ext.classes.sPaging;

        $('.js-datatables').not('.js-datatables-active').each(function() {
            $(this).DataTable({
                pageLength: pageLength,
                paging: $(this).find('tbody tr').length > pageLength,
                language: {
                    "sSearch":         "Rechercher&nbsp;:",
                    "sLengthMenu":     "Afficher _MENU_ &eacute;l&eacute;ments",
                    "sInfo":           "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                    "sInfoEmpty":      "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                    "sInfoFiltered":   "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                    "sInfoPostFix":    "",
                    "sLoadingRecords": "Chargement en cours...",
                    "sZeroRecords":    "Aucun &eacute;l&eacute;ment",
                    "sEmptyTable":     "Aucun &eacute;l&eacute;ment",
                    "oPaginate": {
                        "sFirst":      "Premier",
                        "sPrevious":   "<i class=\"fa-solid fa-angle-left\"></i>",
                        "sNext":       "<i class=\"fa-solid fa-angle-right\"></i>",
                        "sLast":       "Dernier"
                    },
                    "oAria": {
                        "sSortAscending":  ": activer pour trier la colonne par ordre croissant",
                        "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                    },
                    "select": {
                        "rows": {
                            _: "%d lignes séléctionnées",
                            0: "Aucune ligne séléctionnée",
                            1: "1 ligne séléctionnée"
                        }
                    }
                },
                lengthChange: false,
                info: false,
                searching: false
            });
        }).addClass('js-datatables-active');
    }

    /**
     * Initialise le popover de l'état valide.
     */
    static enableEtatValidePopover()
    {
        let etatValideAjaxRequest = null;

        $('.js-etat-valide').popover({
            placement: 'bottom',
            trigger: 'hover',
            boundary: 'window',
            content: '<div class="py-5"><div class="spinner bg-theme my-0"></div></div>',
            html: true,
            sanitize: false,
        })
        .on('show.bs.popover', (e) => {
            let $el = $(e.currentTarget);

            let $tip = $($el.data('bs.popover').tip);
            $tip.css('min-width', '22%');
            let $content = $tip.find('.popover-body');
            $content.addClass('p-0');

            if (etatValideAjaxRequest) {
                etatValideAjaxRequest.abort();
                etatValideAjaxRequest = null;
            }

            etatValideAjaxRequest = $.ajax({
                dataType: 'html',
                url: $el.data('etatValideAjax'),
                method: 'GET',
                cache: false
            }).always(() => {
            }).done((data) => {
                let interval = setInterval(() => {
                    $el.popover('update');
                }, 10);
                $content.html_animate(data, () => {
                    clearInterval(interval);
                });

            }).fail((e) => {
            });
        });
    }

    /**
     * Active le layout principal
     */
    static enableLayout() {
        let body = $('body');

        let layout = {
            isVertical: function() {
                return body.hasClass('layout-vertical');
            },
            hasRightPanel: function() {
                return body.hasClass('layout-right-panel');
            },
            rightPanel: {
                isOpen: function() {
                    return body.hasClass('has-right-panel');
                },
                open: this.openRightSide,
                close: this.closeRightSide
            },
            hasLeftPanel: function() {
                return body.hasClass('layout-left-panel');
            },
            leftPanel: {
                isOpen: function() {
                    return body.hasClass('has-left-panel');
                },
                open: this.openLeftSide,
                close: this.closeLeftSide
            }
        };

        $('#side-menu').metisMenu();

        body
            .on('click', '.navbar-toggle', function() {
                $('.navbar-toggle i').toggleClass('ti-menu').addClass('ti-close');
            })
            .on('click', '#rightSideToggle', function () {
                if (layout.rightPanel.isOpen()) {
                    layout.rightPanel.close();
                } else {
                    layout.rightPanel.open();
                    body.trigger('rightPanel.open');
                }

                return false;
            })
            .on('click', '#leftSideToggle', function () {
                if (layout.leftPanel.isOpen()) {
                    layout.leftPanel.close();
                } else {
                    layout.leftPanel.open();
                    body.trigger('leftPanel.open');
                }

                return false;
            })
        ;

        if(body.hasClass('layout-vertical')) {
            let $openClose = $('.open-close');

            layout.sidebar = {
                isOpen: function() {
                    return !body.hasClass("content-wrapper");
                },
                open: function() {
                    body.removeClass("content-wrapper");
                    $openClose.prop('title', $openClose.data('title-close'));
                    $(".logo span").show();
                    window.dispatchEvent(new Event('sidebar'));
                },
                close: function() {
                    body.addClass("content-wrapper");
                    $openClose.prop('title', $openClose.data('title-open'));
                    $(".logo span").attr('style','display:none !important');
                    window.dispatchEvent(new Event('sidebar'));
                },
                toggle: function() {
                    if(this.isOpen()) {
                        this.close();
                    } else {
                        this.open();
                    }
                }
            };

            $openClose.on('click', function () {
                if (layout.sidebar.isOpen()) {
                    layout.sidebar.close();
                    Cookies.set('ambuerp_layout_sidebar', 'closed');
                } else {
                    layout.sidebar.open();
                    Cookies.set('ambuerp_layout_sidebar', 'open');
                }

                return false;
            });
        }

        let set = function () {
            let width = window.innerWidth > 0 ? window.innerWidth : window.screen.width;

            if (width < 768) {
                $('div.navbar-collapse').addClass('collapse');
            } else {
                $('div.navbar-collapse').removeClass('collapse');
            }

            if(layout.hasRightPanel()) {
                if (width < 1549) {
                    layout.rightPanel.close();
                } else if(Cookies.get('ambuerp_layout_right_panel') != 'closed') {
                    layout.rightPanel.open();
                }
            }

            if(layout.hasLeftPanel()) {
                if (width < 1549) {
                    layout.leftPanel.close();
                } else if(Cookies.get('ambuerp_layout_left_panel') != 'closed') {
                    layout.leftPanel.open();
                }
            }

            if (width < 1170) {
                if(layout.isVertical()) {
                    layout.sidebar.close();
                }
            } else {
                if(body.hasClass('layout-vertical')) {
                    if(Cookies.get('ambuerp_layout_sidebar') != 'closed') {
                        layout.sidebar.open();
                    } else {
                        layout.sidebar.close();
                    }
                }
            }
        };

        $(window).off();
        $(window).ready(set);
        $(window).on("resize", set);

        body.trigger("resize");

        $('.js-toggle-panel').change(function() {
            let $el = $(this).parent().parent().siblings('.panel-wrapper');

            if($(this).prop('checked')) {
                $el.collapse('show');
            } else {
                $el.collapse('hide');
            }
        });

        $('.js-table-checkbox tr').click(function(event) {
            if($(this).is($(event.target)) || $(event.target).parent().is($(this))) {
                let $checkbox = $(this).find('input[type="checkbox"]').first();
                $checkbox.prop('checked', !$checkbox.prop('checked'));
            }
        });
    }

    /**
     * Affiche les éléments masqués le temps du chargement de la page
     */
    static enableVisibilityDelayed() {
        $('.visibility-delayed').removeClass('visibility-delayed');
    }

    static enableAdvancedSearch() {
        $('.js-toggle-search:not(.js-toggle-search-active)').addClass('js-toggle-search-active').click(function () {
            $('.js-search-advanced').toggle();
            $(this).find('.fa-solid').toggleClass('fa-magnifying-glass-plus').toggleClass('fa-magnifying-glass-minus');
        });
    }

    static enableTableSelectable() {
        $('.js-table-selectable:not(.js-table-selectable-active)').addClass('js-table-selectable-active').each(function() {
            let $table = $(this);

            let prev = -1;
            $table.selectable({
                filter: 'tbody tr:not(.disabled)',
                cancel: 'a, .js-selectable-select, th, button',
                selecting: function(e, ui) {
                    $(ui.selecting).find('input').prop('checked', true).trigger('change');

                    let curr = $(ui.selecting.tagName, e.target).index(ui.selecting);
                    if(e.shiftKey && prev > -1) {
                        $(ui.selecting.tagName, e.target).slice(Math.min(prev, curr), 1 + Math.max(prev, curr)).find('input').prop('checked', true).trigger('change');
                        prev = -1;
                    } else {
                        prev = curr;
                    }
                },
                unselecting: function(e, ui) {
                    $(ui.unselecting).find('input').prop('checked', false).trigger('change');
                }
            });

            $table.find('.js-selectable-select, .js-selectable-select-all').click(function(e) {
                if(this === e.target) {
                    let $checkbox = $(this).find('input');
                    if(!$checkbox.prop('disabled')) {
                        $checkbox.prop('checked', !$checkbox.prop('checked')).trigger('change');
                    }
                }
            });

            $table.find('.js-selectable-select input:not([disabled])').change(function(e) {
                if($(this).is(':checked')) {
                    $(this).parent().parent().parent().addClass('ui-selected');
                } else {
                    $(this).parent().parent().parent().removeClass('ui-selected');
                }

                let totalCount = $(this).parents('tbody').find('.js-selectable-select input:not([disabled])').length;
                let count = $(this).parents('tbody').find('.js-selectable-select input:not([disabled]):checked').length;

                $table.find('.js-selectable-select-all input')
                    .prop('checked', count !== 0)
                    .prop('indeterminate', count !== 0 && count !== totalCount)
                    .data('indeterminate', count !== 0 && count !== totalCount)
                ;

                $table.trigger('selectable.table:update', {
                    count
                });
            }).change();

            $table.find('.js-selectable-select-all input').change((e) => {
                let $checkboxes = $table.find('.js-selectable-select input:not([disabled])');
                $checkboxes.prop('checked', $(e.currentTarget).is(':checked') || $(e.currentTarget).data('indeterminate')).trigger('change');
            });
        });
    }

    static enableClock() {
        let moment = require('moment');

        let updateClock = function() {
            let now = moment();
            $("#clock-time").html(now.format('HH:mm').replace(':', '<span>:</span>'));
            $("#clock-date").html(now.format('DD/MM/YYYY'));
        };

        $('#clock').css('display', 'block').on('click', () => { return false; });
        updateClock();
        setInterval(updateClock, 1000);
    }

    /**
     * Active le composant TreeSelect.
     */
    static enableTreeSelect() {
        $('.js-treeselect-input:not(.js-treeselect-input-active)').addClass('js-treeselect-input-active').each((index, element) => {
            $(element).treeSelect({
                type: $(element).data('treeselectType'),
            });
        });
    }

    /**
     * Active le composant PasswordStrength.
     * Permet d'indiquer la force d'un mot de passe.
     */
    static enablePasswordStrength() {
        $('.js-password-strength').not('.js-password-strength-active').addClass('js-password-strength-active').password({
            shortPass: '',
            badPass: '',
            goodPass: '',
            strongPass: '',
            enterPass: '',
            animate: false,
            minimumLength: 8
        });
    }

    /**
     * Active l'autosize sur les Textarea.
     */
    static enableAutosize() {
        autosize($('.js-autosize'));

        $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
            autosize.update($('.js-autosize'));
        });

        $('body')
            .on('focus ', '.js-autosize-focus', (e) => {
                autosize(e.currentTarget);
            })
            .on('focusout', '.js-autosize-focus', (e) => {
                autosize.destroy(e.currentTarget);
            })
        ;
    }

    static initInputmask() {
        Inputmask.extendAliases({
            'insee': {
                removeMaskOnSubmit: true,
                autoUnmask: true,
                regex: '[1-8] [0-9]{2} [0-9]{2} [0-9][0-9AB] [0-9]{3} [0-9]{3} ([0][1-9]|[1-8][0-9]|[9][0-7])',
            },
            'telephone': {
                mask: '((09|\+99 9) 99 99 99 99|X{*})',
                definitions: {
                    X: {
                        validator: '( |\\d)'
                    }
                },
                onKeyDown: function() {},
            },
            'custom-datetime': {
                alias: 'datetime',
                placeholder: '_',
                undoOnEscape: false,
                onKeyDown: function () {},
                onincomplete: (e) => {
                    let $el = $(e.target);
                    let format = e.target.inputmask.opts.inputFormat;
                    if(format === 'HH:MM') {
                        $el.val($el.val().replace(/_/g, '0')).change();
                    }
                    else if(format === 'dd/mm/yyyy') {
                        let shortYear = e.target.value.substring(6, 8);
                        if (!shortYear.includes('_') && e.target.value.substring(8, 10) === '__') {
                            $el.val($el.val().substring(0, 6)+(+shortYear > 40 ? '19' : '20') + shortYear).change();
                        }
                    }
                },
            },
            'immatriculation': {
                regex: '[a-zA-Z]{2}-[0-9]{3}-[a-zA-Z]{2}',
            },
        });

    }

    static initCustomInputs() {
        App.Layout.initInputmask();

        $(window).keyup((e) => {
            const code = (e.keyCode ? e.keyCode : e.which);
            const $el = $(e.target);

            if (code === 9 && $el.is('[data-inputmask="\'alias\': \'custom-datetime\'"]:focus') && $el[0].inputmask.isComplete()) {
                $el[0].setSelectionRange(0, 0);
            }

            if (isFinite(e.key) && $el.is('[data-inputmask="\'alias\': \'insee\'"]:focus') && $el.val().length === 13) {
                $el.val($el.val() + String(97 - ($el.val() % 97)).padStart(2, '0')).trigger('input');
                $el[0].setSelectionRange(19, 21);
            }
        });

        $('body')
            .on('input', '[data-inputmask="\'alias\': \'insee\'"]', function() {
                let value = this.value.replace('2A', '19').replace('2B', '18');
                let cle = 97 - value.substr(0, 13) % 97;

                if(value.length !== 13 && value.length && (value.length === 14 || value.substr(13, 2) != cle)) {
                    this.setCustomValidity('Numéro de sécurité sociale invalide.');
                } else {
                    this.setCustomValidity('');
                }
            })
            .on('change', '.js-age', function() {
                let moment = require('moment');
                let date = moment($(this).val(), App.Date.getFormat($(this).data('format')));
                let $el = $(this).next().children();

                if(date.isValid()) {
                    let age = moment().diff(date, 'years');
                    let html = age + ' an' + (age > 1 ? 's':'');
                    if(moment().format('DDMM') === date.format('DDMM')) {
                        html = "<i class=\"fa-solid fa-gift font-size-14px\"></i>&nbsp;" + html;
                    }

                    $el.html(html);
                } else {
                    $el.html('-');
                }
            })
            .on('input change', '.js-telephone', (e) => {
                this.doRappelButtonLogic(e.currentTarget);
            })
        ;
    }

    static doRappelButtonLogic(e) {
        const $rappelButton = $(e).next('.js-telephone-sms').find('input');
        let estMobile = $rappelButton.data('estMobile')

        if (App.Utils.isPhoneMobile($(e).val())) {
            if (!estMobile) {
                estMobile = true;
                if (['true', 1].includes(App.Shared.getStructureElement().data('activer-rappel-sms-defaut'))) {
                    $rappelButton.prop('checked', true).parent().addClass('active');
                }
                $rappelButton.parent().removeClass('disabled');
            }
        } else if (estMobile) {
            estMobile = false;
            $rappelButton.prop('checked', false).parent().removeClass('active').addClass('disabled');
        }

        $rappelButton.data('estMobile', estMobile);
    }

    static enableMontantHelper() {
        $('.js-montant-helper:not(.js-montant-helper-active)').addClass('js-montant-helper-active').popover({
            placement: 'top',
            fallbackPlacement: [],
            content:
                '<form><div class="d-flex">' +
                '    <div class="input-group">\n' +
                '        <input type="text" class="form-control" placeholder="Montant TTC">\n' +
                '        <div class="input-group-append">\n' +
                '            <span class="input-group-text">€</span>\n' +
                '        </div>\n' +
                '    </div>' +
                '    <button type="submit" tabindex="-1" title="Reporter le montant HT" class="btn btn-primary ml-3"><i class="fa-solid fa-arrow-down"></i></button>\n' +
                '</div></form>',
            sanitize: false,
            html: true,
            title: 'Aide à la saisie',
        }).on('show.bs.popover', function() {
            $($(this).data('bs.popover').tip).css('width', '225px');
        }).on('shown.bs.popover', function() {
            const $inputTtc = $($(this).data('bs.popover').tip).find('input');
            $inputTtc.focus();

            $($(this).data('bs.popover').tip).find('form').submit(() => {
                const tva = parseFloat($('#'+$(this).data('targetTva')).val());
                const $inputHt = $(this).parent().parent().find('input');
                const value = parseFloat($inputTtc.val().replace(',','.'))/(1+(tva/100));

                if(!Number.isNaN(value)) {
                    $inputHt.val(App.String.euro(value.toFixed(2), false)).change();
                }

                $(this).popover('hide');

                return false;
            });
        });

        $('body').on('click', function (e) {
            if (!$(e.target).is('.js-montant-helper') && $(e.target).parents('.js-montant-helper').length === 0 && $(e.target).parents('.popover.show').length === 0) {
                $('.js-montant-helper').popover('hide');
            }
        });
    }

    static enableCustomInputs() {
        bsCustomFileInput.init();

        $('[data-inputmask]:not([readonly]):not(.js-inputmask-active)').each(function() {
            Inputmask().mask(this);
        }).addClass('js-inputmask-active');

        let moment = require('moment-timezone');

        // DateTimePicker
        $('.js-datetimepicker:not(.js-datetimepicker-active,:read-only)').addClass('js-datetimepicker-active').each(function () {
            const format = $(this).data('format');
            const todayButton = $(this).data('todayButton') === undefined || $(this).data('todayButton');
            const inline = $(this).data('inline');
            const minDate = $(this).data('minDate') ? moment($(this).data('minDate'), 'DD/MM/YYYY').toDate() : undefined;
            const maxDate = $(this).data('maxDate') ? moment($(this).data('maxDate'), 'DD/MM/YYYY').toDate() : undefined;

            const datePicker = new AirDatepicker(this, {
                locale: AirDatepickerLocaleFr,
                toggleSelected: false,
                autoClose: true,
                keyboardNav: false,
                timepicker: !format || format.split(' ').length === 2,
                inline,
                minDate,
                maxDate,
                buttons: todayButton ? [
                    {
                        content: dp => dp.locale.today,
                        onClick: dp => dp.selectDate(new Date()),
                    }
                ] : false,
                onShow: (isAnimationComplete)=> {
                    if (!isAnimationComplete && datePicker.$el.value) {
                        datePicker.opts.autoClose = false;
                        const date = moment(datePicker.$el.value, App.Date.getFormat($(this).data('format'))).toDate();
                        datePicker.selectDate(date, {silent: true, updateTime: true});
                        datePicker.setViewDate(date);
                        datePicker.opts.autoClose = true;
                    }
                },
                onRenderCell: ({date, cellType})=> {
                    if ($(this).data('disabledDays') && cellType === 'day') {
                        const disabledDays = $(this).data('disabledDays').toString().split(',').map(Number);
                        return {disabled: disabledDays.includes(date.getDay())};
                    }
                },
                onSelect: ({date}) => {
                    if (date) {
                        $(this).trigger('change');
                        this.dispatchEvent(new Event('input'));
                    }
                }
            });

            $(this).parent().find('.js-datetimebutton').click(function () {
                let $input = $(this).closest('.input-group').find('input');
                let timeId = $input.data('timeField');
                let now = moment().tz($input.data('timezone'));

                if(timeId) {
                    $('#' + timeId).val(now.format('HH:mm')).change();
                }

                $input.val(now.format(App.Date.getFormat(format))).change();
                $input[0].dispatchEvent(new Event('input'));
            });
        });


        // ClockPicker
        $('.js-clockpicker:not([readonly]):not(.js-clockpicker-active)').addClass('js-clockpicker-active').each(function () {
            $(this).clockpicker({
                placement: 'bottom',
                align: 'left',
                autoclose: true,
                afterDone: () => {
                    $(this).change();
                }
            });
        });

        //         $(this).clockpicker('hide');
        //         $(this).clockpicker('show');
        //         if(e.unit) {
        //             $(this).clockpicker('toggleView', e.unit);
        //         }

        return this;
    }

    /**
     * Active le composant Select2.
     */
    static enableSelect2() {
        // Select2 simples
        $('.js-select2').not('.js-select2-active').addClass('js-select2-active').each(function () {
            let template = $(this).data('template');

            $(this).select2({
                minimumResultsForSearch: 8,
                language: App.Constants.LOCALE_SHORT,
                templateResult: App.Utils.getAutocompleteTemplate(template),
                placeholder: $(this).is('[multiple]') ? $(this).attr('placeholder') || $(this).data('placeholder') || '…' : null,
            }).on('select2:opening', (e) => {
                if ($(e.currentTarget).is(':disabled')) {
                    e.preventDefault();
                }
            });
        });

        if(!this.treeSelectCache) {
            // Mise en cache des tree select
            this.treeSelectCache = {};
            $('.js-treeselect-input[data-field]').each((index, el) => {
                let $el = $(el);

                if(!($el.data('field') in this.treeSelectCache)) {
                    this.treeSelectCache[$el.data('field')] = [];
                }

                this.treeSelectCache[$el.data('field')].push('input[name="' + $el.attr('name') + '"]:checked');
            });
        }

        let treeSelectCache = this.treeSelectCache;

        // Autocompléteurs
        $('.js-select2-autocomplete').not('.js-select2-autocomplete-active').addClass('js-select2-autocomplete-active').each(function () {
            let url = $(this).data('url');
            let template = $(this).data('template');

            let m;
            let regex = /__([\w]+)__/g;
            let guessedFields = [];

            while (m = regex.exec(url)) {
               let field = m[1];

                if('query' !== field) {
                    guessedFields.push(field);
                }
            }

            let fields = {};
            let keys = ($(this).data('autocompleteFields') || '').split(',').concat(guessedFields).filter((v, i, a) => v !== '' && a.indexOf(v) === i);

            for(let field of keys) {
                let $perimeter = $(this).closest('.js-select2-perimetre');

                const ignorePerimetreData = $perimeter.data('ignore-perimetre-fields');
                if (!$perimeter.length || (ignorePerimetreData && ignorePerimetreData.split(',').includes(field))) {
                    $perimeter = $(document);
                }

                const $field = $perimeter.find('[data-field="' + field + '"]');

                if ($field.hasClass('btn-group')) {
                    fields[field] = {selector: '#'+$field.attr('id')+' input:checked'};
                } else {
                    fields[field] = $field.data('value') ? $field.data('value') : $field;

                    if (field in treeSelectCache) {
                        if (1 === treeSelectCache[field].length) {
                            fields[field] = {selector: treeSelectCache[field][0]};
                        } else {
                            // On cherche le select dans le perimetre
                            for (let i in treeSelectCache[field]) {
                                if ($(this).closest('.js-select2-perimetre').find(treeSelectCache[field][i].replace('input', '').replace(':checked', '')).length) {
                                    fields[field] = {selector: treeSelectCache[field][i]};
                                }
                            }
                        }
                    }
                }
            }

            let newUrl;
            let currentUrl;
            let currentData = [];

            if (url) {
                $(this).select2({
                    ajax: {
                        url: function(params) {
                            if ($(this).is(':disabled')) {
                                return null;
                            }

                            newUrl = url;
                            let queryParams = {};
                            fields.query = params && params.term ? params.term : '*';
                            fields.field = $(this).data('field');

                            for(let field in fields) {
                                let placeholder = '__' + field + '__';
                                let value = (typeof fields[field] === 'object' ? (fields[field].selector ? $(fields[field].selector).map(function() {return $(this).val();}).get().join(',') : fields[field].val()) : fields[field]) || '';

                                if(newUrl.includes(placeholder)) {
                                    if(!value && (!url.includes('?') || url.indexOf('?') > url.indexOf(placeholder))) return false;
                                    newUrl = newUrl.replace(placeholder, encodeURIComponent(value));
                                } else {
                                    if(value) {
                                        queryParams[field] = value;
                                    }
                                }
                            }

                            newUrl = newUrl + (newUrl.indexOf('?') === -1 ? '?' : '&') + $.param(queryParams);

                            if(newUrl === currentUrl) {
                                return null;
                            }

                            return newUrl;
                        },
                        transport: function (params, success, failure) {
                            if(params.url === false) return success([]);
                            if(params.url === null) return success(currentData);

                            let $request = $.ajax(params);

                            $request.then(success);
                            $request.fail(failure);

                            return $request;
                        },
                        dataType: 'json',
                        delay: 250,
                        data: {},
                        cache: false,
                        processResults: function (data) {
                            currentData = data;
                            return {
                                results: data
                            };
                        },
                        success: function () {
                           currentUrl = newUrl;
                        }
                    },
                    language: App.Constants.LOCALE_SHORT,
                    allowClear: !$(this).is('[multiple]') && !$(this).prop('required'),
                    placeholder: $(this).attr('placeholder') || $(this).data('placeholder') || '…',
                    templateResult: App.Utils.getAutocompleteTemplate(template)
                }).on('select2:opening', (e) => {
                    if ($(e.currentTarget).is(':disabled')) {
                        e.preventDefault();
                    }
                }).on('select2:open', function(e) {
                    $('.select2-results__option:not(.loading-results)').remove();
                    if($(this).hasClass('input-sm')) {
                        $('.select2-container').last().addClass('select2-sm');
                    }
                }).on('select2:selecting', (e) => {
                    let $el = $(e.currentTarget);
                    let data = e.params.args.data;

                    if(data.data) {
                        $el.data('custom', data.data);
                    }

                    if(data.fields) {
                        for(let field in data.fields) {
                            let item = data.fields[field];
                            let willFlash = true;

                            if (field in treeSelectCache && treeSelectCache[field].length === 1) {
                                let $field = $(treeSelectCache[field][0].replace(':checked', '[value="'+item+'"]'));
                                $field.prop('checked', true).change();
                            } else {
                                let $field = $el.closest('.js-select2-perimetre').find('[data-field="' + field + '"]');

                                if($field.is('[type="checkbox"]')) {
                                    $field.prop('checked', item);
                                    if($field.parent().hasClass('btn-filter')) {
                                        $field.parent().addClass('active')
                                    }
                                } else if($field.hasClass('btn-group')) {
                                    let $input = $field.find('input[value="'+item+'"]').prop('checked', true);
                                    $input.parent().parent().find('.btn').removeClass('active');
                                    $input.parent().addClass('active');
                                } else {
                                    let oldVal = $field.val();
                                    if(item) {
                                        if(typeof item === 'string') {
                                            if ($field.hasClass('tt-input')) {
                                                $field.typeahead('val', item).trigger('input');
                                            } else {
                                                $field.val(item);
                                            }
                                        } else {
                                            if ($field.hasClass('tt-input')) {
                                                $field.typeahead('val', item.text);
                                                $field.trigger('typeahead:select', item);
                                            } else {
                                                let option = new Option(item.label, item.value, true, true);
                                                $field.append(option);
                                            }

                                            if(item.data) {
                                                $field.data('custom', item.data);
                                            }
                                        }
                                    } else {
                                        $field.val('');
                                    }

                                    willFlash = oldVal !== $field.val();
                                }

                                $field.trigger('change');

                                if(willFlash) {
                                    App.Utils.flashField($field);
                                }
                            }
                        }
                    }
                }).on('select2:unselecting', (e) => {
                    let $field = $(e.currentTarget);
                    $field.data('custom', {});
                    if ($field.data('clearFieldsUnselect')) {
                        let clearFields = $field.data('clearFieldsUnselect').split(',');

                        for (let field of clearFields) {
                            let $clearField = $field.closest('.js-select2-perimetre').find('[data-field="' + field + '"]');
                            if($clearField.val()) {
                                $clearField.val('').trigger('change');
                            }
                        }
                    }
                });
            }
        });

        // fix dégeux pour les placeholder des select2 multiples
        $('.js-select2-active[multiple], .js-select2-autocomplete-active[multiple]').each(function() {
            if(!$(this).val().length) {
                $(this).next().find('.select2-search__field').width('100%');
            }
        });

        $('[data-clear-fields-simple]').each(function() {
            let selector = '[name="' + $(this).attr('name') + '"]';

            $('body').on('change input', selector, (e) => {
                let clearFields = $(this).data('clearFieldsSimple').split(',');

                for(let field of clearFields) {
                    $(e.target).closest('.js-select2-perimetre').find('[data-field="' + field + '"]').val('');
                }
            });
        });

        $('[data-clear-fields-change]').each(function() {
            let selector = '[name="' + $(this).attr('name') + '"]';

            $('body').on('change', selector, (e) => {
                let clearFields = $(this).data('clearFieldsChange').split(',');

                for(let field of clearFields) {
                    let $field = $(e.target).closest('.js-select2-perimetre').find('[data-field="' + field + '"]');
                    if($field.val()) {
                        $field.val('').trigger('change');
                    }
                }
            });
        });

        // Fix for edit in place
        $('body').on('click', '.select2-container', function (e) {
            e.stopPropagation();
        });

        // Focus on tab
        $(document).on('focus', '.select2.select2-container', function (e) {
            if (e.originalEvent && $(this).find(".select2-selection--single").length > 0) {
                $(this).siblings('select').select2('open');
            }
        });

    }

    static removeEmbed($item) {
        let $container = $item.parent();
        this.getEmbedIndex($container);

        if($container.data('max') && $container.children().length <= $container.data('max')) {
            $('.js-embed-add[data-embed="' + $container.attr('id') + '"]').prop('disabled', false);
        }

        $item.remove();

        App.Layout.fixEmbed($container);

        $container.trigger('change');
    }

    static getEmbedIndex($container) {
        let index = $container.data('embedIndex');

        if(undefined === index) {
            let prefixId = $container.attr('id');

            index = 0;
            $('[id^="' + prefixId + '"][name]', $container).each(function () {
                let matches = $(this).attr('id').replace(prefixId, '').match(/_?(\d+)_/);
                if (matches.length > 0 && parseInt(matches[1]) > index) {
                    index = parseInt(matches[1]);
                }
            });

            $container.data('embedIndex', index);
        }

        return index;
    }

    static addEmbed($btn) {
        let $container = $('#' + $btn.data('embed'));
        let prototype = $btn.data('prototype') ? $btn.data('prototype') : $container.data('prototype');
        let prototypeName = ($btn.data('prototypeName') ? $btn.data('prototypeName') : $container.data('prototypeName')) || '__name__';

        const nextIndex = this.getEmbedIndex($container) + 1;
        $container.data('embedIndex', nextIndex);

        let $itemNew = $(prototype.replaceAll(prototypeName, nextIndex));

        $container.append($itemNew);

        App.Layout.enableSelect2();
        App.Layout.enableTreeSelect();
        App.Layout.enableCustomInputs();
        App.Layout.enableGeocodage();
        App.Layout.enableMontantHelper();
        App.Layout.enableColorPalette();
        App.Layout.enableTrumbowyg();
        App.Shared.initDistancierBtn();
        App.Layout.fixEmbed($container);
        App.Layout.fixStructureToggleField();

        if ($container.data('max') && $container.children().length >= $container.data('max')) {
            $btn.prop('disabled', true);
        }

        $container.trigger('change');
        $btn.trigger('embed.new', [$itemNew]);

        return $itemNew;
    }

    static fixStructureToggleField() {
        const structureElement = App.Shared.getStructureElement();
        if (structureElement.data('toggleField')) {
            structureElement.change();
        }
    }

    static fixEmbed($container) {
        let ordre = 1;
        $('[type="hidden"][id^="'+ $container.attr('id') + '"][id$="_ordre"]').each(function(){
            $(this).val(ordre);
            ordre++;
        });

        let $items = $container.children().not('.js-embed-placeholder');

        $('.js-embed-placeholder', $container).toggle(0 === $items.length);
    }

    /**
     * Activer les embed forms
     */
    static enableEmbed() {
        let $body = $('body');

        let updateUpDownStates = function ($embedContainer) {
            $embedContainer.find('.js-embed-up, .js-embed-down').prop('disabled', false);
            $embedContainer.find('.js-embed-up:first').prop('disabled', true);
            $embedContainer.find('.js-embed-down:last').prop('disabled', true);
        };

        // Boutons ajouter
        $body.on('click', '.js-embed-add', function(e) {
            App.Layout.addEmbed($(this));
            e.preventDefault();
            updateUpDownStates($('#' + $(this).data('embed')));
        });

        // Disable les boutons up/down au chargement si nécessaire
        $body.find('.js-embed-order').each(function() {
            let treated = []
            let containerId = $(this).data('embed');

            if (!treated.includes(containerId)) {
                updateUpDownStates($('#' + containerId));
                treated.push(containerId);
            }
        });

        // Boutons supprimer
        $body.on('click', '.js-embed-remove', function() {
            let $item = $(this).parent().parent().parent();
            let $container = $item.parent();

            if($(this).data('embedConfirm')) {
                swal({
                    title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'bg-danger',
                    confirmButtonText: Translator.trans('action.supprimer'),
                    cancelButtonText: Translator.trans('action.annuler')
                }).then((result) => {
                    if (result.value) {
                        App.Layout.removeEmbed($item);
                        updateUpDownStates($container);
                    }
                });
            } else {
                App.Layout.removeEmbed($item);
                updateUpDownStates($container);
            }
        });

        $body.on('click', '.js-embed-up', function() {
            let $item = $(this).parent().parent().parent().parent();
            let $previousItem = $item.prev();

            if ($previousItem.length) {
                let $orderInput = $('#' + $(this).parent().data('embed-order'));
                let $previousOrderInput = $('#' + $previousItem.find('.js-embed-order').data('embed-order'));

                $orderInput.val(parseInt($orderInput.val()) - 1);
                $previousOrderInput.val(parseInt($previousOrderInput.val()) + 1);
                $previousItem.insertAfter($item);

                updateUpDownStates($item.parent());
            }
        });

        $body.on('click', '.js-embed-down', function() {
            let $item = $(this).parent().parent().parent().parent();
            let $nextItem = $item.next();

            if ($nextItem.length) {
                let $orderInput = $('#' + $(this).parent().data('embed-order'));
                let $nextOrderInput = $('#' + $nextItem.find('.js-embed-order').data('embed-order'));

                $orderInput.val(parseInt($orderInput.val()) + 1);
                $nextOrderInput.val(parseInt($nextOrderInput.val()) - 1);
                $nextItem.insertBefore($item);

                updateUpDownStates($item.parent());
            }
        });
    }


    /**
     * Activer les embed forms inline
     */
    static enableEmbedInline() {
        $('.js-embed-inline').each(function() {
            $(this).parent().append('<div class="width-50px pt-2 text-right ml-auto"><button type="button" class="btn btn-success btn-sm js-embed-add" data-embed="' + $(this).attr('id') +  '"><i class="fa-solid fa-plus"></i></button></div>');
        }).removeClass('js-embed-inline');
    }

    /**
     * Active les input btn groups
     */
    static enableBtnGroup() {
        $('.js-btn-group-toggle')
            .click(function() {
                if($(this).data('toggleStatus')) {
                    $(this).parent().find('input').prop('checked', false);
                    $(this).siblings('label').removeClass('active');
                    $(this).data('toggleStatus', false);
                    $(this).find('i').attr('class', 'im-checkbox-unchecked');
                } else {
                    $(this).parent().find('input').prop('checked', true);
                    $(this).siblings('label').addClass('active');
                    $(this).data('toggleStatus', true);
                    $(this).find('i').attr('class', 'im-checkbox-checked');
                }
            })
            .each(function() {
                let $el = $(this);

                $el.siblings('label').change(function() {
                    let totalCount = $(this).parent().find('input').length;
                    let count = $(this).parent().find('input:checked').length;

                    if(count === 0) {
                        $el.data('toggleStatus', false);
                        $el.find('i').attr('class', 'im-checkbox-unchecked');
                    } else if(count === totalCount) {
                        $el.data('toggleStatus', true);
                        $el.find('i').attr('class', 'im-checkbox-checked');
                    } else {
                        $el.data('toggleStatus', false);
                        $el.find('i').attr('class', 'im-checkbox-partial');
                    }
                })
            ;
        });

        $('.js-btn-group-item').dblclick(function() {
            if(!$(this).hasClass('disabled')) {
                $(this).parent().find('label').removeClass('active').find('input').prop('checked', false);

                $(this).addClass('active').find('input').prop('checked', true).change();
            }
        });
    }

    static enableBtnReset() {
        $('.js-btn-reset').click(function() {
            $(this).parent().siblings('input').val('');
        });
    }

    static getIndicateurGeocodage(precision) {
        let color = 'text-lightgrey';
        let title = Translator.trans('libelle.precision.aucune');

        if(precision == 4) {
            color = 'text-success';
            title = Translator.trans('libelle.precision.manuelle');
        } else if(precision == 3) {
            color = 'text-success';
            title = Translator.trans('libelle.precision.bonne');
        } else if(precision == 2) {
            color = 'text-warning';
            title = Translator.trans('libelle.precision.moyenne');
        } else if(precision == 1) {
            color = 'text-danger';
            title = Translator.trans('libelle.precision.faible');
        } else if(precision == -1) {
            color = 'text-dark';
            title = Translator.trans('libelle.precision.insee');
        } else if(precision == -2) {
            color = 'text-primary';
            title = Translator.trans('libelle.precision.auto');
        }

        return {color, title};
    }

    static enableIndicateurGeocodage() {
        $('.js-indicateur-geocodage').each((i, el) => {
            const mode = el.dataset.mode;
            const initData = JSON.parse(el.dataset.data);

            if (mode === 'form') {
                const $els = Object.fromEntries(Object.entries(initData).map(([k, v]) => [k, $('#'+v)]));
                const getData = () => Object.fromEntries(Object.entries($els).map(([k, v]) => [k, v.val()]));

                const props = reactive({
                    data: getData(),
                    small: el.dataset.small,
                    organismes: el.dataset.organismes,
                    placement: el.dataset.placement,
                    onChanges: (data) => {
                        for (const field in data) {
                            const $target = $els[field];

                            if(typeof $target.typeahead('val') === 'undefined') {
                                $target.val(data[field]);
                            } else {
                                $target.typeahead('val', data[field]);
                            }

                            $target.change();

                            if (field === 'adresse') {
                                $target.data('autocompleted-value', data[field]);
                            } else if(field === 'codeInsee') {
                                this.triggerDistancier($target.attr('id'));
                            }
                        }
                    },
                    onTempsSurPlace: (data) => {
                        const $embed = $(el).parents('.js-trajet-embed');

                        if ($embed.length) {
                            console.log($embed, $(el).parents('[data-adresse-type]').data('adresseType'), data);
                            App.Shared.updateTempsSurPlace($embed, $(el).parents('[data-adresse-type]').data('adresseType'), data);
                        }
                    }
                })

                createApp({
                    render: () => h(Geocodage, props)
                }).mount(el);

                for(const field in $els) {
                    $els[field].on('input change', () => {
                        props.data = getData();
                    });
                }
            } else {
                createApp(IndicateurGeocodage, {value: initData.precision, small: el.dataset.small}).mount(el);
            }
        });
    }

    static triggerDistancier(input) {
        const $input = $('#'+input);
        const distancierTargetKilometre = $input.data('distancierTargetKilometre');
        const distancierTargetKilometreCalcule = $input.data('distancierTargetKilometreCalcule');

        const distancierBtn = $input.data('distancierBtn');
        const $distancierBtn = distancierBtn ? $('#'+distancierBtn) : null;

        if(distancierTargetKilometre) {
            let params = {structure: App.Shared.getStructure(), transport: true, full: true};

            for(const type of ['depart', 'arrivee']) {
                for(const field of ['Insee', 'Latitude', 'Longitude']) {
                    const fieldId = $input.data('distancier'+Capitalize(type)+field);
                    const value = $('#'+fieldId).val();

                    params[type+field] = value;
                }
            }

            const rendezVousDate = $input.data('distancierRendezVousDate') ? $('#'+$input.data('distancierRendezVousDate')).val() : null;
            const rendezVousTime = $input.data('distancierRendezVousTime') ? $('#'+$input.data('distancierRendezVousTime')).val() : null;
            const rendezVousModeDest = $input.data('distancierRendezVousMode') ? $('#'+$input.data('distancierRendezVousMode')+'_1').prop('checked') : false;

            if (rendezVousDate && rendezVousTime) {
                params[rendezVousModeDest ? 'arriveeDate' : 'departDate'] = rendezVousDate + ' '+rendezVousTime;
            }

            if(params.departInsee && params.arriveeInsee) {
                if ($distancierBtn) {
                    $distancierBtn.addClass('disabled');
                    $distancierBtn.find('.js-distancier-btn-loader').show();
                    $distancierBtn.find('.js-distancier-btn-icon').hide();
                }
                $.ajax({
                    url: Router.generate('distancier.ajax'),
                    method: 'POST',
                    data: {geocodage_distancier: params},
                    cache: false
                }).done((data) => {
                    if ($distancierBtn) {
                        $distancierBtn.removeClass('disabled');
                        $distancierBtn.find('.js-distancier-btn-loader').hide();
                        $distancierBtn.find('.js-distancier-btn-icon').show();
                    }

                    let sourceKmPeageName = null;
                    let sourceKmPeageData = null;

                    if(data.distancier && data.distancier.pecDest) {
                        sourceKmPeageName = 'le distancier';
                        sourceKmPeageData = data.distancier.pecDest;
                    } else if(data.via_michelin && data.via_michelin.pecDest) {
                        sourceKmPeageName = 'ViaMichelin';
                        sourceKmPeageData = data.via_michelin.pecDest;
                    } else if(data.loxane && data.loxane.pecDest) {
                        sourceKmPeageName = 'Loxane';
                        sourceKmPeageData = data.loxane.pecDest;
                    } else if(data.ptv && data.ptv.pecDest) {
                        sourceKmPeageName = 'PTV';
                        sourceKmPeageData = data.ptv.pecDest;
                    }

                    if (sourceKmPeageName) {
                        const $distancierTargetKilometre = $('#' + distancierTargetKilometre);
                        const $distancierTargetKilometreCalcule = $('#' + distancierTargetKilometreCalcule);
                        const distancierTargetPeageQuantite = $input.data('distancierTargetPeageQuantite');
                        const $distancierTargetPeageQuantite = distancierTargetPeageQuantite ? $('#' + distancierTargetPeageQuantite) : null;
                        const distancierTargetPeageMontant = $input.data('distancierTargetPeageMontant');
                        const $distancierTargetPeageMontant = distancierTargetPeageMontant ? $('#' + distancierTargetPeageMontant) : null;

                        $distancierTargetKilometre.val(sourceKmPeageData.kilometre).change();
                        $distancierTargetKilometreCalcule.val(sourceKmPeageData.kilometre).change();
                        $distancierTargetPeageQuantite.val(sourceKmPeageData.peageQuantite).change();
                        $distancierTargetPeageMontant.val(Euro(sourceKmPeageData.peageMontant, false)).change();

                        if ($distancierBtn) {
                            App.Utils.flashTooltip($distancierBtn, (sourceKmPeageData.peageQuantite !== null && $distancierTargetPeageQuantite ? 'Distance et péage renseignés' : 'Distance renseignée') + ' automatiquement' + (sourceKmPeageName ? ' depuis ' + sourceKmPeageName : ''));
                        }
                    }

                    let updateTops = false;

                    for (const field of ['debPec', 'pecDest', 'destFin'])
                    {
                        const distancierTargetDureeCalculee = $input.data('distancierTargetDuree'+field.charAt(0).toUpperCase()+field.slice(1));
                        const $distancierTargetDureeCalculee = distancierTargetDureeCalculee ? $('#'+distancierTargetDureeCalculee) : null;

                        if ($distancierTargetDureeCalculee) {
                            for (const sourceTemps of ['via_michelin', 'loxane', 'ptv']) {
                                if (data[sourceTemps] && data[sourceTemps][field]) {
                                    updateTops = true;
                                    $distancierTargetDureeCalculee.val(data[sourceTemps][field].duree);
                                    break;
                                }

                                $distancierTargetDureeCalculee.val(null);
                            }
                        }
                    }

                    App.Shared.updateTopSuggestions($input.parents('.js-trajet-embed'), updateTops);
                });
            }
        }
    }

    /**
     * Active les suggestions d'adresses
     */

    static getGeocodageSuggestion(type)
    {
        return (context, options) => {
            const {color, title} = App.Layout.getIndicateurGeocodage(context.precision)
            const hasCity = type !== 'municipality' && context.city;

            const postCodeHtml = context.postcode ? '<div class="ml-auto"><small>' + context.postcode + '</small></div>' : '';

            let html = '<div>';

            if (context._section) {
                html += '<div class="tt-subheader bg-light text-dark" style="font-size: 10px;font-weight: 300;">'+context._section+'</div>';
            }

            html += '<div class="d-flex align-items-center line-height-1-3">';
            html += '<div class="mr-2"><i class="im-location2 '+color+'" title="'+title+'"></i></div>';
            html += '<div class="flex-1 min-width-0">';

            if (hasCity) {
                html += '<div class="d-flex">';
                html += '<div><small>' + context.city + '</small></div>';
                html += postCodeHtml;
                html += '</div>';
            } else {
                html += '<div class="d-flex align-items-center">';
            }
            html += '<div class="text-ellipsis '+(hasCity ? 'mb-1' : 'py-1')+'"><b title="' + context.name + '">' + context.name + '</b></div>';

            if (!hasCity) {
                html += postCodeHtml;
                html += '</div>';
            }

            html += '</div>';
            html += '</div>';

            return html + '</div>';
        };
    }

    static enableGeocodage() {
        let _this = this;

        $('.js-geocodage').not('.js-geocodage-active').addClass('js-geocodage-active').each(function() {
            let $el = $(this);

            if($el.data('geocodageField') === 'value') {
                $el.data('autocompleted-value', $el.val());
            }

            let withOrganismes = $el.data('sourceOrganisme');

            let config =  {
                minLength: 2,
                highlight: true,
                hint: false,
                tabAutocomplete: false,
                classNames: {
                    wrapper: $el.data('absolute') ? 'twitter-typeahead-absolute' : 'twitter-typeahead'
                },
            };
            const suggestion = App.Layout.getGeocodageSuggestion($el.data('type'));

            const prepareRemote = (query, settings) => {
                settings.url = settings.url
                    .replace('_QUERY_', encodeURIComponent(query))
                    .replace('_STRUCTURE_', App.Shared.getStructure() ? App.Shared.getStructure() : '')
                ;

                if($el.data('geocodageField') === 'value') {
                    const postcode = $('#' + $el.data('target-postcode')).val() || '';
                    const citycode = $('#' + $el.data('target-citycode')).val() || '';

                    settings.url = settings.url+'&postcode='+postcode+'&citycode='+citycode;
                }

                return settings;
            };

            let adresseSource = {
                source: new Bloodhound({
                    datumTokenizer: Bloodhound.tokenizers.whitespace,
                    queryTokenizer: Bloodhound.tokenizers.whitespace,
                    remote: {
                        url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', $el.data('type') ?? ''),
                        prepare: prepareRemote,
                        cache: false
                    },
                }),
                limit: 400,
                templates: {
                    header: () => {
                        if(withOrganismes) {
                            return '<div class="tt-header bg-theme">Adresses</div>';
                        }
                    },
                    suggestion,
                    pending: function(context, options) {
                        return '<div class="tt-suggestion text-muted">Recherche en cours…</div>';
                    },
                    notFound: '<div></div>',
                },
                displayKey: $el.data('geocodageField')
            };

            if (withOrganismes) {
                const organismeSource = {
                    source: new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.whitespace,
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        remote: {
                            url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', 'organisme'),
                            prepare: prepareRemote,
                            cache: false
                        },
                    }),
                    limit: 400,
                    templates: {
                        header: '<div class="tt-header bg-theme">Organismes</div>',
                        suggestion,
                        notFound: '<div></div>',
                    },
                    displayKey: $el.data('geocodageField')
                };

                const annuaireSanteSource = {
                    source: new Bloodhound({
                        datumTokenizer: Bloodhound.tokenizers.whitespace,
                        queryTokenizer: Bloodhound.tokenizers.whitespace,
                        remote: {
                            url: App.Constants.GEOCODAGE_PATH.replace('_TYPE_', 'annuaire-sante'),
                            prepare: prepareRemote,
                            cache: false
                        },
                    }),
                    limit: 400,
                    templates: {
                        header: '<div class="tt-header bg-theme">Annuaire Santé</div>',
                        suggestion,
                        notFound: '<div></div>',
                    },
                    displayKey: $el.data('geocodageField')
                };

                $el.typeahead(config, organismeSource, annuaireSanteSource, adresseSource);
            } else {
                $el.typeahead(config, adresseSource);
            }
        }).on('typeahead:select', function (e, data) {
            if($(this).hasClass('js-autosize') || $(this).hasClass('js-autosize-focus')) {
                autosize.update($(this));
            }

            if($(this).data('geocodageField') === 'value') {
                $(this).data('autocompleted-value', data[$(this).data('geocodageField')]);
            }

            if (data.tempsSurPlace) {
                const $embed = $(this).parents('.js-trajet-embed');

                if ($embed.length) {
                    App.Shared.updateTempsSurPlace($embed, $(this).parents('[data-adresse-type]').data('adresseType'), data.tempsSurPlace);
                }
            }

            for(let key in data) {
                let targetId = $(this).data('target-' + key);

                if(targetId) {
                    let $target = $('#'+targetId);

                    if(typeof $target.typeahead('val') === 'undefined') {
                        $target.val(data[key]);
                    } else {
                        $target.typeahead('val', data[key]);
                    }

                    if(key === 'citycode') {
                        _this.triggerDistancier(targetId);
                    }
                }
            }

            $(this).change();
        }).on('input', function() {
            const precision = $('#'+$(this).data('target-precision')).val();
            const isAdresseField = $(this).data('geocodageField') === 'value';
            let reset = precision > 0;

            if(reset && isAdresseField && $(this).val().indexOf($(this).data('autocompleted-value')) !== -1) {
                reset = false;
            }

            if (reset) {
                if (isAdresseField) {
                    $(this).data('autocompleted-value', null);
                }

                let resetFields = ['latitude', 'longitude', 'citycode', 'precision', 'dureeDebPec', 'dureePecDest', 'dureeDestFin'];
                for(let field of resetFields) {
                    if($(this).data('target-' + field)) {
                        let targetId = $(this).data('target-' + field);
                        $('#' + targetId).val('');
                    }
                }

                const $embed = $(this).parents('.js-trajet-embed');
                App.Shared.updateTempsSurPlace($embed);
            }
        });
    }

    static enableDeleteBtn() {
        $('.js-delete-btn').not('.js-delete-btn-active').prop('disabled', false).addClass('js-delete-btn-active').click((e) => {
            swal({
                title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'bg-danger',
                confirmButtonText: Translator.trans('action.supprimer'),
                cancelButtonText: Translator.trans('action.annuler')
            }).then((result) => {
                if (result.value) {
                    $(e.currentTarget).closest('form').submit();
                }
            });
        });
    }

    static enableSubmitBtn() {
        $('body').on('submit', 'form', (e) => {
            if(!$(e.currentTarget).find('[type="submit"][formtarget="_blank"]').length) {
                setTimeout(() => {
                    $(e.currentTarget).find('[type="submit"]').prop('disabled', true).next('.dropdown-toggle').addClass('disabled').next('.dropdown-menu').find('.dropdown-item').addClass('disabled');
                });
            }
        });
    }

    static enableConfirmBtn() {
        $('body').on('click', '.js-confirm-btn', function() {
            let confirmButtonClass = $(this).data('confirmColor') ? 'bg-' + $(this).data('confirmColor') : 'bg-danger';
            let options = $(this).data('options') || {};
            let optionsRaw = $(this).data('options-raw');
            let optionsHtml = '';

            if(Object.keys(options).length || optionsRaw) {
                let $container = $('<div class="swal2-confirm-options"></div>');

                for(let key in options) {
                    $container.append('<div class="checkbox"><input type="checkbox" data-confirm-option="'+key+'" id="confirm_'+key+'"> <label for="confirm_'+key+'">' + options[key] + '</label></div>');
                }

                if (optionsRaw) {
                    $container.append(optionsRaw);
                }

                optionsHtml = $container[0].outerHTML;
            }

            swal({
                title: $(this).data('title') || App.Constants.LIBELLE_ETES_VOUS_SUR,
                type: $(this).data('type') || 'warning',
                html: ($(this).data('text') || '') + optionsHtml,
                showCancelButton: true,
                confirmButtonClass,
                confirmButtonText: $(this).data('action') || Translator.trans('action.confirmer'),
                cancelButtonText: Translator.trans('action.annuler')
            }).then((result) => {
                if (result.value) {
                    if ($(this).is('[type="submit"]')) {
                        $(this).prop('disabled', true);
                        App.Utils.submitForm($(this).closest('form'));
                    } else {
                        $(this).addClass('disabled');

                        const url = new URL($(this).attr('href'), window.location.origin);

                        $('[data-confirm-option]:visible').each(function(index, element) {
                            if (!$(element).is('[type="checkbox"]:not(:checked)')) {
                                url.searchParams.append($(element).data('confirmOption'), $(element).val());
                            }
                        });

                        window.location.href = url.href;
                    }
                }
            });

            App.Layout.enableCustomInputs();

            return false;
        });
    }

    static enableEditBtn() {
        $('body').on('click', '.js-edit-btn', function() {
            const postKey = $(this).data('edit-field');
            const url = $(this).data('edit-url');

            swal({
                title: $(this).data('edit-title'),
                input: $(this).data('edit-input-type'),
                inputValue: $(this).data('edit-value'),
                showCancelButton: true,
                confirmButtonClass: 'bg-success',
                confirmButtonText: Translator.trans('action.modifier'),
                cancelButtonText: Translator.trans('action.annuler'),
                onOpen: () => {
                    let $input = $(swal.getInput());
                    $input.removeClass(['swal2-input', 'swal2-textarea']).addClass(['form-control','js-autosize','js-edit-swal-input']);
                    App.Layout.enableAutosize();
                }
            }).then((result) => {
                if (undefined !== result.value) {
                    const editedValue = $('.js-edit-swal-input').val();
                    const form = $('<form></form>');
                    form.attr("method", "post");
                    form.attr("action", url);
                    const field = $('<input></input>');
                    field.attr("type", "hidden");
                    field.attr("name", postKey);
                    field.attr("value", editedValue);
                    form.append(field);
                    $(form).appendTo('body').submit();
                }
            });

            return false;
        });
    }

    static updateShuffle() {
        $('.js-shuffle-active').each(function() {
            $(this).data('shuffle').options.speed = 0;
            $(this).data('shuffle').update();
            $(this).data('shuffle').options.speed = 250;
        });
    }

    static enableShuffle() {
        Shuffle.options.delimeter = ',';

        $('.js-shuffle:visible').not('.js-shuffle-active').each(function() {
            let shuffle = new Shuffle($(this), {
              itemSelector: '.js-shuffle-item',
              speed: $(this).hasClass('js-shuffle-no-animation') ? 0 : 250
            });

            $(this).data('shuffle', shuffle).addClass('js-shuffle-active');
        });

        let init = true;

        let filter = function() {
            let target = $(this).data('target');

            $(target).each(function() {
                let shuffle = $(this).data('shuffle');

                if(shuffle) {
                    if(init) {
                        shuffle.options.speed = 0;
                    }

                    shuffle.filter((element, shuffle) => {
                        let text = $(element).data('text').toString().toUpperCase();
                        let groups = $(element).data('groups').split(',');

                        let res = true;

                        $('.js-shuffle-filter[data-target="' + target + '"]').each(function() {
                            let value = $(this).val();

                            if(value && value !== 'all') {
                                res = groups.includes(value);
                                return res;
                            }
                        });

                        if(!res) return res;

                        $('.js-shuffle-search[data-target="' + target + '"]').each(function() {
                            if($(this).val()) {
                                res = text.indexOf($(this).val().toUpperCase()) !== -1;
                                return res;
                            }
                        });

                        return res;
                    });

                    if(!$(this).find('.shuffle-item--visible').length) {
                        if(!$(this).find('.shuffle-message').length) {
                            $(this).append('<span class="text-muted mx-3 shuffle-message">' + Translator.trans('libelle.aucun-element') + '</span>');
                        }
                    } else {
                        $(this).find('.shuffle-message').remove();
                    }

                    if(init) {
                        shuffle.options.speed = 250;
                    }
                }
            });
        };

        $('body').on('change', '.js-shuffle-filter', filter);
        $('body').on('keyup', '.js-shuffle-search', filter);

        $('.js-shuffle-filter, .js-shuffle-search').each(filter);
        init = false;
    }

    static enableToggle() {
        let $body = $('body');

        $body.on('change', '[data-toggle]', function() {
            const checked = $(this).is(':checked');

            for(let selector of $(this).data('toggle').split(',')) {
                const inverted = selector.startsWith('!');
                if(inverted) {
                    selector = selector.substr(1);
                }

                const toggle = Boolean(inverted ^ checked);

                $(selector).toggle(toggle);

                if(!toggle && $(this).data('toggleClear')) {
                    App.Utils.clearAllInputsSelector(selector);
                }
            }
        });

        $body.on('change', 'input[data-action="input"], input[type="checkbox"], input[type="radio"], select', function() {
            const $el = $(this);

            if($el.is('select')) {
                const $perimetre = $el.closest('.js-select2-perimetre');

                const $option = $('option:selected', this);
                $el.find('[data-toggle-field]').each(function () {
                    if (!$(this).is($option)) {
                        $perimetre.find($(this).data('toggleField')).hide();
                    }
                });
                if ($option.data('toggleField')) {
                    const $toggleField = $perimetre.find($option.data('toggleField'));
                    $toggleField.show();
                    $body.resize(); // fix pour déclencher le recalcul de la taille des tree selects
                }
            }
            else if($el.parents('.tree-select').length) {
                $el.parents('.tree-select').find('input[data-action="input"]').each(function () {
                    if ($(this).data('toggleField') && !$(this).is(':checked')) {
                        $($(this).data('toggleField')).hide();
                    }
                });
                if ($el.is(':checked') && $el.data('toggleField')) {
                    $($el.data('toggleField')).show();
                    $body.resize(); // fix pour déclencher le recalcul de la taille des tree selects
                }
            }
            else if($el.is('input')) {
                const $perimetre = $el.closest('.js-select2-perimetre');
                $el.parents('.btn-group').find('input:not(:checked)').each(function () {
                    $perimetre.find($(this).data('toggleField')).hide();
                });
                if ($el.is(':checked')) {
                    const $toggleField = $perimetre.find($el.data('toggleField'));
                    $toggleField.show();
                    $body.resize(); // fix pour déclencher le recalcul de la taille des tree selects
                }
            }
        });
    }

    static enableTimeline() {
        $('body').on('click', '.js-timeline-event', function() {
            for(let i = 0; i < 2; i++) {
                let $timeline = $(this).closest('.js-timeline');
                let timelineId = $timeline.attr('id');
                let $timelineStyle = $timeline.find('.js-timeline-style');
                let timelineHeight = $timeline.height();

                let $detail = $timeline.find('.js-timeline-detail');
                $detail.removeClass('opacity-0');
                let detailHeight = $detail.outerHeight();
                $timeline.find('.tlt-events').css('min-height', detailHeight + 'px');
                if(timelineHeight < detailHeight) {
                    timelineHeight = detailHeight;
                }

                let position = $(this).position().top + 13 - detailHeight/2;
                let arrowPosition = 0;

                if(position < 0) {
                    position = 0;
                }

                if(position + detailHeight > timelineHeight) {
                    position = timelineHeight - detailHeight;
                }

                arrowPosition += $(this).position().top - position + 9;

                $timelineStyle.html('#' + timelineId + ' .tlt-detail::before {top: ' + arrowPosition + 'px;} #' + timelineId + ' .tlt-detail::after {top: ' + (arrowPosition + 1) + 'px;}');
                $detail.css('top', position);

                let event = $(this).data('event');

                if(event) {
                    $detail.show();
                    $detail.find('.tlt-detail-item').hide();
                    $detail.find('.tlt-detail-item[data-event="' + event + '"]').show();
                } else {
                    $detail.hide();
                }
            }

            return false;
        });

        $('.js-timeline-event-courant:visible').trigger('click');
        $('a[data-toggle="tab"]').on('shown.bs.tab', () => {
            $('.js-timeline-event-courant:visible').trigger('click');
        });
    }

    static focusField($field)
    {
        $field.closest('.collapse:not(.show)').addClass('show');
        $('.nav-link[href="#' + $field.closest('.tab-pane').attr('id') + '"]').tab('show');

        $field.focus();
    }

    static enableFocusField()
    {
        $('body').on('click', '[data-focus-field]', function() {
            let $field = $('#' + $(this).data('focusField'));
            App.Layout.focusField($field);
        });
    }

    static enableClearFields()
    {
        $('body').on('click', '[data-clear-fields]', function() {
            App.Utils.clearAllInputsSelector('#' + $(this).data('clearFields'));
            return false;
        });
    }

    static hasRightSide() {
        return $('body').hasClass('has-right-panel');
    }

    static openRightSide() {
        let $li = $('#rightSideToggle');
        let $a = $('a', $li);

        $('body').addClass('has-right-panel');
        $('i', $li).removeClass('ti-arrow-left').addClass('ti-arrow-right');
        $a.prop('title', $a.data('title-close'));

        Cookies.set('ambuerp_layout_right_panel', 'open');
    }

    static closeRightSide() {
        let $li = $('#rightSideToggle');
        let $a = $('a', $li);

        $('body').removeClass('has-right-panel');
        $('i', $li).removeClass('ti-arrow-right').addClass('ti-arrow-left');
        $a.prop('title', $a.data('title-open'));

        Cookies.set('ambuerp_layout_right_panel', 'closed');
    }

    static openLeftSide() {
        let $li = $('#leftSideToggle');
        let $a = $('a', $li);

        $("body").addClass('has-left-panel');
        $('i', $li).removeClass('ti-arrow-right').addClass('ti-arrow-left');
        $a.prop('title', $a.data('title-close'));

        Cookies.set('ambuerp_layout_left_panel', 'open');
    }

    static closeLeftSide() {
        let $li = $('#leftSideToggle');
        let $a = $('a', $li);

        $('body').removeClass('has-left-panel');
        $('i', $li).removeClass('ti-arrow-left').addClass('ti-arrow-right');
        $a.prop('title', $a.data('title-open'));

        Cookies.set('ambuerp_layout_left_panel', 'closed');
    }

    static animateNumbers($elements, number, decimals = 0) {
        $elements.each((index, element) => {
            const $element = $(element);
            $element.prop('number', parseFloat($element.text().replace(',', '.')));
            $element.animateNumber({ number: number, numberStep: function(now, tween) {
                $(tween.elem).prop('number', now).text(now.toFixed(decimals).toString().replace('.', ','));
            }});
        });
    }

    static enableTooltip() {
        $('.js-tooltip').tooltip();
    }

    static enableTrumbowyg() {
        $('.js-trumbowyg:not(.js-trumbowyg-active)').removeClass('form-control').trumbowyg({
            lang: 'fr',
            btns: [
                ['historyUndo', 'historyRedo'],
                ['strong', 'em', 'del'],
                ['foreColor', 'backColor'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
            ],
            resetCss: true,
            autogrow: true,
        }).addClass('js-trumbowyg-active');
    }

    static enableSticky() {
        let $navbars = $('.navbar-default');
        let $footer = $('.footer');

        let computeSticky = function() {
            $('.js-sticky:not(.js-sticky-enabled)').scrollToFixed({
                marginTop: function() {
                    return $navbars.toArray().reduce((acc, cur) => $(cur).css('position') === 'fixed' ? $(cur).outerHeight(true) + acc : acc, 0) +  parseInt($(this).attr('data-sticky-top-offset') ?? 0);
                },
                limit: function() {
                    if (!$(this).data('stickyContainer')) {
                        return $footer.length ? $footer.offset().top : null;
                    }

                    let $container = $($(this).data('sticky-container'));
                    return $container.innerHeight() + $container.offset().top;
                },
                zIndex: function() {
                    return $(this).data('zIndex') ? $(this).data('zIndex') : 900;
                }
            }).addClass('js-sticky-enabled');
        };

        computeSticky();

        let $stickyContainer = $('.js-sticky-container');

        if ($stickyContainer.length > 0) {
            //TODO : revoir la structure HTML avec un conteneur pour éviter ces tricks ?...

            // ~ Planning : gestion du changement de plage de dates
            (new MutationObserver(function () {
                computeSticky();
            })).observe($stickyContainer[0], {childList: true, subtree: true});

            // ~ Planning : gestion du scroll horizontal du planning
            document.addEventListener('scroll', (e) => {
                $('[data-sticky-container]:not(.js-sticky)').trigger('detach.ScrollToFixed');
                const $el = $(e.target);
                if ($el.is('.planning .overflow-x-scroll')) {
                    let leftPos = $el.offset().left;
                    let scroll = $el.scrollLeft();

                    $el.find('.js-sticky-enabled').each(function (index) {
                        if ('fixed' === $(this).css('position')) {
                            $(this).css('left', (leftPos - scroll + index * $(this).outerWidth(true)) + 'px');
                        }
                    });
                }
            }, true);
        }
    }

    static enableClipboard() {
        let clipboard = new Clipboard('.js-copy-button');

        $('.js-copy-tooltip').tooltip({
            show: {
                effect: "slideDown",
            },
            title: 'Copié !',
            trigger: 'manual'
        });

        clipboard.on('success', function(e) {
            e.clearSelection();
            let el = $(e.trigger).parent();
            el.tooltip("show");
            setTimeout(function () {
                el.tooltip('hide');
            }, 500);
        });

    }

    static enableCardBtnEmpty() {
        $('.js-card-btn-empty').on('click', (e) => {
            const $el = $(e.currentTarget);
            const route = $el.data('route');

            if (route) {
                const params = {};
                const routeParams = $el.data('routeParams') || {};
                for (const field in routeParams) {
                    let value = routeParams[field];
                    if(field.startsWith('$')) {
                        params[field.substring(1)] = $(value).val();
                    } else {
                        params[field] = value;
                    }
                }

                const win = window.open(Router.generate(route, params), '_blank');
                if(win) win.focus();

                e.preventDefault();
            }
        })
    }
};
