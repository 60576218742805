'use strict';

import swal from "sweetalert2";
import Translator from "@/App/Translator";

App.Facturation = class {
    static initLignes(containerId, sortable) {
        let $container = $('#' + containerId);
        let $placeholder = $('.js-embed-placeholder', $container);

        $('body').on('click', '.js-widget-remove', function () {
            swal({
                title: App.Constants.LIBELLE_ETES_VOUS_SUR,
                type: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'bg-danger',
                confirmButtonText: Translator.trans('action.supprimer'),
                cancelButtonText: Translator.trans('action.annuler')
            }).then((result) => {
                if (result.value) {
                    let $item = $(this).closest('.js-ligne');

                    $item.remove();

                    App.Layout.fixEmbed($container);

                    $('#lignes').change();
                }
            });
        }).on('click', '.js-toggle-content-btn', function () {
            let $btn = $(this);
            let $i = $btn.find('i');
            let $item = $btn.closest('.js-ligne');

            if ($btn.attr('title') === $btn.data('title-open')) {
                $btn.attr('title', $btn.data('title-close'));
                $i.removeClass($btn.data('icon-open')).addClass($btn.data('icon-close'));
            } else {
                $btn.attr('title', $btn.data('title-open'));
                $i.removeClass($btn.data('icon-close')).addClass($btn.data('icon-open'));
            }

            $('.js-toggle-content', $item).toggle();
        }).on('change', '.js-ligne-libelle', function() {
            let $input = $(this);
            let $item = $input.closest('.js-ligne');

            $('input[name$="[numero]"]', $item).val('');
        });

        if (typeof sortable !== 'undefined' && sortable) {
            $('.widget').draggable({
                helper: 'clone',
                cursor: 'move',
                appendTo: 'body',
                connectToSortable: '#' + containerId,
                start: function (event, ui) {
                    $(ui.helper).css('width', `${ $(event.target).width() }px`);
                },
                drag: function () {
                    $placeholder.toggle(false);
                },
                stop: function () {
                    $placeholder.toggle(0 === $container.children().not('.js-embed-placeholder').length)
                }
            }).click(function () {
                App.Layout.addEmbed($(this));
            });

            $container.sortable({
                connectWith: '.ligne-container',
                items: '.js-ligne',
                opacity: 0.8,
                revert: true,
                forceHelperSize: true,
                placeholder: 'widget-placeholder mb-4',
                forcePlaceholderSize: true,
                tolerance: 'pointer',
                cancel: 'input, textarea, button, select, option, .trumbowyg, .select2',
                start: function(e, ui){
                    ui.placeholder.height(ui.item.outerHeight() - 4);
                },
                update: function (event, ui) {
                    let $draggingElement = $(ui.item);

                    if ($draggingElement.hasClass('widget')) {
                        $draggingElement.replaceWith(App.Layout.addEmbed($draggingElement));
                    }

                    App.Layout.fixEmbed($container);

                    $container.change();
                }
            });
        }
    }
};