<template>
    <div class="panel panel-compact">
        <div class="panel-heading d-flex justify-content-between bg-light py-3 px-4">
            <div>Lots B2</div>
        </div>
        <div class="panel-wrapper">
            <div v-if="loading" class="p-4">
                <div class="p-2">
                    <b-spinner class="mr-2"></b-spinner> Chargement…
                </div>
            </div>
            <template v-else>
                <div v-if="lots.length">
                    <div v-for="(societeLots, index) in Object.values(lotsBySociete)">
                        <div v-if="structures.length" :class="{'border-top': index !== 0}" class="pt-3 px-4 text-theme font-weight-normal font-size-16px mb-n2">{{ societeLots.structure.libelle }}</div>
                        <div class="row px-4 pt-4">
                            <div v-for="lot in societeLots.lots" class="col-xxl-2 col-xl-3 col-lg-4 col-6">
                                <tableau-de-bord-widget-lot-box @click="openLot(lot.lot, lot.type)">
                                    <template v-slot:content>
                                        <tableau-de-bord-widget-lot-title :lot="lot.lot"></tableau-de-bord-widget-lot-title>
                                        <tableau-de-bord-widget-lot-item :lot="lot.lot"></tableau-de-bord-widget-lot-item>
                                    </template>
                                    <template v-slot:footer>
                                        <template v-if="lot.type === 'suggestion'">
                                            <a href="#" class="btn btn-sm btn-secondary">
                                                <i class="fa-regular fa-lightbulb mr-1"></i> {{ Translator.trans('libelle.suggestion') }}
                                            </a>
                                            <a href="#" class="btn btn-sm btn-success box-lot-footer-btn">
                                                <i class="fa-solid fa-plus mr-1"></i> {{ Translator.trans('action.creer-lot') }}
                                            </a>
                                        </template>
                                        <template v-else-if="lot.type === 'enAttente'">
                                            <a href="#" class="btn btn-sm btn-success">
                                                <i class="fa-solid fa-check mr-1"></i> {{ Translator.trans('libelle.pret') }}
                                            </a>
                                            <a href="#" class="btn btn-sm btn-primary box-lot-footer-btn" v-if="canSend">
                                                <i class="fa-solid fa-paper-plane mr-1"></i> {{ Translator.trans('action.envoyer') }}
                                            </a>
                                            <a href="#" class="btn btn-sm btn-success box-lot-footer-btn" v-else>
                                                <i class="fa-solid fa-check mr-1"></i> {{ Translator.trans('libelle.pret') }}
                                            </a>
                                        </template>
                                    </template>
                                </tableau-de-bord-widget-lot-box>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-muted p-4">Aucun élément</div>
            </template>
        </div>
    </div>
</template>

<script setup>
import {computed, onMounted, ref, toRefs, watch} from "vue";
import _ from "lodash";
import Router from "@/App/Router";
import Translator from "@/App/Translator";
import {useRequest} from "@/Vue/Composables/Request";
import Popup from '@/Vue/Components/TableauDeBord/Widget/Lot/Popup'

const {postJson, abort} = useRequest();

const emit = defineEmits(['refresh', 'error']);

const props = defineProps({
    structures: Array,
    tab: String,
});

const {structures, tab} = toRefs(props);

const loading = ref(false);
const enAttente = ref([]);
const suggestions = ref([]);
const roles = ref({});

const lots = computed(() => [...enAttente.value.map(lot => ({type: 'enAttente', lot})), ...suggestions.value.map(lot => ({type: 'suggestion', lot}))]);

const lotsBySociete = computed(() => {
    return _.orderBy(lots.value.reduce((res, item) => {
        const structure = item.lot.structure;

        if(!(structure.id in res)) {
            res[structure.id] = {structure, lots: []}
        }

        res[structure.id].lots.push(item);

        return res;
    }, {}), ['structure.libelleComplet']);
});

const canSend = computed(() => roles.value.ROLE_FACTURATION_B2_SEFI_LOT_SEND === true);

const openLot = (lot, type) => {
    App.Utils.openVueSwal(Popup, {
        lot, canSend, type,
        onRefresh: () => refresh(true),
    });
};

const refresh = (silent = false) => {
    if (!structures.value.length) {
        abort();
        enAttente.value = [];
        suggestions.value = [];
        roles.value = {};
        loading.value = false;
        emit('refresh');

        return;
    }

    loading.value = !silent;

    postJson(Router.generate('facturation.tableau-de-bord.lot'), {
        tableau_de_bord_filter: {
            structure: structures.value,
        }
    }).then((data) => {
        enAttente.value = data.enAttente;
        suggestions.value = data.suggestions;
        roles.value = data.roles;

        emit('refresh');
    }, (err) => emit('error', err)).finally(() => {
        loading.value = false;
    });
}

watch(structures, () => refresh());
onMounted(() => refresh());

</script>
