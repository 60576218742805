'use strict';

import PappersSearch from '@/Vue/Components/PappersSearch';

App.Referentiel.Structure = class {};

App.Referentiel.Structure.Form = class {
    constructor(params) {
        this.params = params;

        if(params.type && params.partageDonnees) {
            $('body').on('change', '[name="structure[type]"]', (event) => {
                let $type = $(event.currentTarget);

                if ($type.val() !== params.idGroupe) {
                    $('#partage-row').hide();
                    $('#' + params.partageDonnees).prop('checked', false);
                } else {
                    $('#partage-row').show().trigger('resize');
                }
            });
        }

        this._initJoursFeries();
        this._initRappelSmsTemplate();
        this._initCategoriePreset();
        this._initPappers();
    }

    _initPappers() {
        $('.js-pappers-btn').click(() => {
            const siret = $('#structure_siret').val();
            const libelle = $('#structure_libelle').val();

            App.Utils.openVueSwal(PappersSearch, {
                siret, libelle
            });
        });
    }

    _initCategoriePreset() {
        const onPresetChange = () => {
            let $notCheckedCheckboxCollection = $('#structure_categorieVehiculePresetCollection [type=checkbox]:not(:checked)');
            if ($('#structure_categorieVehiculePresetCollection [type=checkbox]:checked').length >= 4) {
                $notCheckedCheckboxCollection.prop('disabled', true);
                $notCheckedCheckboxCollection.parent().addClass('disabled');
            } else {
                $notCheckedCheckboxCollection.prop('disabled', false);
                $notCheckedCheckboxCollection.parent().removeClass('disabled');
            }
        };

        const onChange = () => {
            $('#structure_categorieVehiculePresetCollection [type=checkbox]').each((index, el) => {
                const $el = $(el);
                const $el2 = $('#structure_categorieVehiculeCollection [type=checkbox][value="'+$el.val()+'"]');
                const checked = $el2.is(':checked');

                $el.parents('.btn').toggle(checked);
                if (!checked && $el.is(':checked')) {
                    $el.prop('checked', false).parents('.btn').removeClass('active');
                }
            });
        };

        $('#structure_categorieVehiculeCollection [type=checkbox]').change(onChange);
        $('#structure_categorieVehiculePresetCollection [type=checkbox]').change(onPresetChange).change(onChange);

        onPresetChange();
    }

    _initJoursFeries() {
        $('.js-ferie-report').click(function() {
            let ferieCollection = [];
            $(this).parent().parent().next().find('input[name="structure[evenementCollection][]"]').each(function() {
                if ($(this).prop('checked')) {
                    ferieCollection.push($(this).data('code'));
                }
            })

            let jds =  $(this).parent().parent().next().next().find('select').val();

            $(this).parent().parent().parent().nextAll().each(function() {
                let $this = $(this);
                $($this.children()[1]).find('input').each(function() {
                    if (ferieCollection.includes($(this).data('code')) !==  $(this).prop('checked')) {
                        $(this).parent().button('toggle');
                    }

                })

                $($this.children()[2]).find('select').val(jds).change();
            })

            let $nextYearsElt = $($(this).parents('.js-ferie')[0]).find('.js-ferie-next-years');
            $nextYearsElt.removeClass('reported');
            setTimeout(function() {
                $nextYearsElt.addClass('reported');
                setTimeout(function() {
                    $nextYearsElt.removeClass('reported');
                }, 800)
            }, 1)
        });


        const gotoCallback = ($jsFerieElt, offset) => {
            $jsFerieElt.hide();

            for (let i = 0; i < Math.abs(offset); i++) {
                if (0 < offset) {
                    $jsFerieElt = $jsFerieElt.next();
                } else {
                    $jsFerieElt = $jsFerieElt.prev();
                }
            }

            if (offset > 0) {
                $jsFerieElt.removeClass('moved-left')
                $jsFerieElt.addClass('moved-right')
            } else {
                $jsFerieElt.removeClass('moved-right')
                $jsFerieElt.addClass('moved-left')
            }

            $jsFerieElt.show();
        }

        $('.js-ferie-goto').click(function () {
            let $parent = $($(this).parents('.js-ferie')[0]);
            let offset = parseInt($(this).data('offset'));
            gotoCallback($parent, offset)
        });

        $('.js-ferie-precedent').click(function() {
            let $parent = $($(this).parents('.js-ferie')[0]);
            gotoCallback($parent, -1);
        });
        $('.js-ferie-suivant').click(function() {
            let $parent = $($(this).parents('.js-ferie')[0]);
            gotoCallback($parent, 1);
        });
        $('.js-ferie-now').click(function() {
            $('.js-ferie').hide();
            $('#ferie-now').show();
        });
    }

    _initRappelSmsTemplate() {
        let $previewRappelSms = $('#previewRappelSms')
        let $structureNomEmetteurSms = $('#structure_nomEmetteurSms')
        let $structureSignatureSms = $('#structure_signatureSms')
        let $structureTelephoneContactSms = $('#structure_telephoneContactSms')
        let $structureInclureRetour = $('#structure_inclureRetourRappelSms')

        const previewRappelSmsUpdate = () => {
            const inclureRetour = $structureInclureRetour.find('option:selected').data('choice');

            let retour = '';
            if (inclureRetour === this.params.retourSansHoraire) {
                retour = ' avec retour le 27/01';
            } else if (inclureRetour === this.params.retourAvecHoraire) {
                retour = ' avec retour le 27/01 à 16h50';
            }

            $previewRappelSms.val('Rappel : RDV TAXI le 26/01 à 08h14'+retour+'.'+'\n'+$structureSignatureSms.val()+'\n'+$structureTelephoneContactSms.val());
        }

        $structureNomEmetteurSms.keyup(previewRappelSmsUpdate);
        $structureSignatureSms.keyup(previewRappelSmsUpdate);
        $structureTelephoneContactSms.keyup(previewRappelSmsUpdate);
        $structureNomEmetteurSms.focusout(previewRappelSmsUpdate);
        $structureSignatureSms.focusout(previewRappelSmsUpdate);
        $structureTelephoneContactSms.focusout(previewRappelSmsUpdate);
        $structureInclureRetour.change(previewRappelSmsUpdate);

        previewRappelSmsUpdate();
    }
};

App.Referentiel.Structure.View = class {
    constructor(params) {
        App.Referentiel.Sync.checkSync('structure', params.id ? [params.id] : []);
    }
};

App.Referentiel.Structure.Index = class {
    constructor(params) {
        App.Referentiel.Sync.checkSync('structure', params.ids);
    }
};
