<template>
    <Pin
        :variant="{active: 'indigo', start: 'green', end: 'red'}[type]"
        :lng-lat="[position.longitude, position.latitude]"
    >
        <template #content>
            <span class="tracking-tighter" v-if="'active' === type">
                {{ formatTime(position.horodatage.time) }}
            </span>
            <i v-else class="fa-solid fa-lg fa-flag-checkered" :class="{start: 'text-green-800', end: 'text-red-800'}[type]"></i>
        </template>
        <template #contentHover v-if="'active' !== type">
            <span class="tracking-tighter">
                {{ formatTime(position.horodatage.time) }}
            </span>
        </template>
    </Pin>
</template>

<script setup>
import Pin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";

const props = defineProps({
    type: String,
    position: Object,
});

const formatTime = (time) => {
    let parts = time.split(':');

    if (parts.length === 3) {
        parts = parts.slice(0, -1);
    }

    return parts.join(':');
};
</script>