<template>
    <div class="position-relative tailwind" @wheel.stop :style="style">
        <MiniMap v-if="hasMap">
            <slot></slot>
        </MiniMap>
        <div class="trajet-map-placeholder" :class="{'cursor-pointer': error}" :style="style" @click="onPlaceholderClick" v-else>
            <template v-if="data === null">
                <i class="fa-regular fa-exclamation-triangle fa-2x" title="Erreur lors du chargement de la carte" v-if="error"></i>
                <i class="fa-regular fa-map-location-dot fa-2x fa-fade" title="Chargement…" v-else></i>
            </template>
            <template v-else>
                <i class="fa-regular fa-map trajet-map-placeholder-icon"></i>
                <div class="trajet-map-placeholder-title">Cartographie indisponible</div>
                <div class="trajet-map-placeholder-message">Trajet non géocodé</div>
            </template>
        </div>
        <div class="trajet-map-overlay"></div>
    </div>
</template>

<script setup>
import MiniMap from "@/Vue/Components/MiniMap";
import {computed, toRefs} from "vue";
import Router from '@/App/Router';
import {useRequest} from "@/Vue/Composables/Request";

const {getJson} = useRequest();

const emit = defineEmits(['refresh']);
const props = defineProps(['height', 'hasMap', 'error', 'data']);

const {height, hasMap, error, data} = toRefs(props);

const style = computed(() => height.value ? {height: height.value} : {height: '100%', 'min-height': '200px'});

const refresh = () => {
    data.value = null;
    error.value = false;
    getJson(Router.generate('regulation.trajet.map', {id: id.value})).then((res) => {
        data.value = res;
    }, () => {
        error.value = true;
    });
};

const onPlaceholderClick = () => {
    if (error.value) {
        emit('refresh');
    }
};
</script>
