<template>
    <IconField iconPosition="left">
        <InputIcon class="z-20 fa-solid fa-search"></InputIcon>
        <AutoComplete ref="searchInput" :minLength="0" scrollHeight="500px" @item-select="onSelect" @hide="onHide" v-model="searchQueryInput" :suggestions="searchResultsGroupped" :loading="searchLoading" @complete="search"  :completeOnFocus="searchHistory.length > 0 || '' !== searchQuery" optionLabel="name" optionGroupLabel="key" optionGroupChildren="items"  placeholder="Rechercher..." :pt="{optiongroup: '!p-0', overlay: '!w-[280px]', pcInputText: {root: {class: {'!py-1.5 pl-8 !transition-all w-56 focus:w-80': true, 'w-80': searchQuery !== ''}}}, item: '!py-2 !pl-2', loader: 'z-10'}">
            <template #empty>
                Aucun résultat
            </template>
            <template #option="{option}">
                <div class="flex items-center">
                    <div class="mr-2">
                        <Badge :value="option.number" :severity="precisions[option.precision].severity" :title="precisions[option.precision].title" @mouseover="searchActiveResult = option" class="hover:shadow-lg transition"/>
                    </div>
                    <div class="flex-1">
                        <div class="flex text-xs justify-between leading-none">
                            <div>{{ option.city }}</div>
                            <div>{{ option.postcode }}</div>
                        </div>
                        <div class="text-sm font-semibold leading-none">{{ option.name }}</div>
                    </div>
                </div>
            </template>
            <template #optiongroup="{option}">
                <div class="flex items-center gap-x-2 w-full px-1" v-if="option.group">
                    <div class="w-[15px] h-[4px] rounded" :class="option.group.bgClass"></div>
                    <div class="font-normal" :class="option.group.textClass">{{ option.group.label }}</div>
                    <div class="flex-1 h-[4px] rounded" :class="option.group.bgClass"></div>
                </div>
                <div class="rounded-md bg-gray-100 dark:bg-surface-800 px-2 py-1 text-gray-500 text-xs mt-1" v-if="option.section">
                    {{ option.section }}
                </div>
            </template>
        </AutoComplete>
    </IconField>
    <Button outlined severity="secondary" icon="fa-solid fa-times" class="!py-1" @click="clearSearch" v-if="searchActive"/>
</template>
<script setup>
import AutoComplete from "primevue/autocomplete";
import InputIcon from "primevue/inputicon";
import IconField from "primevue/iconfield";
import {useGeolocalisationStore} from "@/Vue/Stores/RegulationV2/Geolocalisation";
import {storeToRefs} from "pinia";
import {onKeyStroke} from "@vueuse/core";
import Badge from "primevue/badge";
import Translator from "@/App/Translator";
import {computed, nextTick} from "vue";
import Button from "primevue/button";

const geolocalisationStore = useGeolocalisationStore();
const {searchQuery, searchResultsGroupped, searchLoading, searchInput, searchActiveResult, searchHistory, searchActive, searchPreviousCamera} = storeToRefs(geolocalisationStore);
const {search, clearSearch, precisions} = geolocalisationStore;

const onSelect = (event) => {
    searchActiveResult.value = event.value;
    nextTick(() => {
        searchPreviousCamera.value = null;
        blur();
    });
};

const onHide = () => {
    if (searchQuery.value === '' && !searchActiveResult.value) {
        clearSearch();
    }
};

const blur = () => setTimeout(() => {
    searchInput.value.$refs.focusInput.$el.blur()
});

const searchQueryInput = computed({
    get: () => searchQuery.value,
    set: (value) => {
        if (typeof value !== 'object') {
            searchQuery.value = value;
        }
    },
});

onKeyStroke('/', (e) => {
    e.preventDefault();
    searchInput.value.$refs.focusInput.$el.focus();
});
onKeyStroke('Escape', (e) => {
    console.log('ESCAPE FROM SEARCH');
    if (searchInput.value.focused) {
        clearSearch();
        blur();
        e.preventDefault();
    }
});
</script>