<template>
    <Pin :variant="item.estDisponible ? 'green' : 'red'" v-for="item in positions"
         :lng-lat="[item.longitude, item.latitude]"
         clickable @dblclick.stop="emit('select-vehicule', item.id)"
    >
        <template #content>
            <Text :style="{color: item.couleur}" :value="item.shortText" :title="item.longText"></Text>
        </template>
        <template #contentHover>
            <i class="fa-solid" :class="[item.classeIcone, item.classeCouleur]"></i>
        </template>
    </Pin>
    <template v-if="data">
        <template v-if="!structures">
            <PinSegment type="start" v-if="data.latitudeDegrePriseEnCharge && data.longitudeDegrePriseEnCharge && data.topPriseEnChargeArrivee" :position="{horodatage: data.topPriseEnChargeArrivee, latitude: data.latitudeDegrePriseEnCharge, longitude: data.longitudeDegrePriseEnCharge}"></PinSegment>
            <PinSegment type="end" v-if="data.latitudeDegreDestination && data.longitudeDegreDestination && data.topDestinationArrivee" :position="{horodatage: data.topDestinationArrivee, latitude: data.latitudeDegreDestination, longitude: data.longitudeDegreDestination}"></PinSegment>
        </template>
        <Custom placement="bottom-left">
            <ButtonGroup>
                <Button :key="appareil.id" v-for="appareil in data.appareils" @click="toggleAppareil(appareil)" :title="'Voir les positions de '+appareil.text" :active="appareil.id === activeAppareilId">
                    <i class="fa-regular" :class="{'fa-microchip': appareil.type.estGeoloc, 'fa-mobile': appareil.type.estTla}"></i>
                </Button>
            </ButtonGroup>
        </Custom>

        <Path v-if="data.routesGeoJSON" id="trajet" mode="ant" :ant-speed="50" autoFit :fitOptions="{maxZoom: 18, animate: false, padding: 25}" color="#3b82f6" :features="data.routesGeoJSON"></Path>
        <Positions v-if="positionsGeoJson" id="positions" :autoFit="!data.routesGeoJSON" :fitOptions="{maxZoom: 18, animate: false, padding: 25}" :data="positionsGeoJson"></Positions>
    </template>
</template>

<script setup>
import Pin from "@/Vue/Components/RegulationV2/Map/Base/Pin.vue";
import PinSegment from "@/Vue/Components/RegulationV2/Map/Layer/Segment/Pin.vue";
import Text from "@/Vue/Components/RegulationV2/Map/Base/Pin/Text.vue";
import {computed, inject, ref} from 'vue';
import Path from "@/Vue/Components/RegulationV2/Map/Base/Path.vue";
import Positions from "@/Vue/Components/RegulationV2/Map/Base/Positions.vue";
import ButtonGroup from "@/Vue/Components/RegulationV2/Map/Base/Control/Button/Group.vue";
import Custom from "@/Vue/Components/RegulationV2/Map/Base/Control/Custom.vue";
import Button from "@/Vue/Components/RegulationV2/Map/Base/Control/Button/Button.vue";

const emit = defineEmits(['select-vehicule']);

const steps = inject('steps');
const positions = inject('positions');
const structures = inject('structures');
const data = inject('data');

const activeAppareilId = ref(null);

const toggleAppareil = (appareil) => {
    activeAppareilId.value = appareil.id === activeAppareilId.value ? null : appareil.id;
};

const positionsGeoJson = computed(() => activeAppareilId.value ?({
    type: 'FeatureCollection',
    features: data.value.appareils[activeAppareilId.value].positionCollection.map((position, index) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [position.longitude, position.latitude],
        },
        properties: {
            index,
            angle: position.angle ?? undefined,
            icon: position.angle !== null ? 'arrowGray' : null,
        },
    })),
}) : null);
</script>