'use strict';

import Router from "@/App/Router";
import {createApp} from "vue";
import DistancierMap from "@/Vue/Components/Distancier/Map";

App.Referentiel.Distancier = class {
    static _initMap(paths) {
        createApp(DistancierMap, {paths}).mount('#distancier-map');
    }
};

App.Referentiel.Distancier.Index = class {
    constructor(params) {
        this._initSuggestions();

        App.Referentiel.Distancier._initMap(params.paths);
    }

    _initSuggestions() {
        $.get(Router.generate('referentiel.distancier.suggestions') + '?' + $('#searchForm').serialize()).done((data) => {
            if(data) {
                $('#suggestionsContent').html(data);
                $('#suggestions').show();
            }
        });

        $('body').on('click', '[data-suggestion]', (el) => {
            const suggestion = $(el.currentTarget).data('suggestion');

            for(let field in suggestion) {
                const $field = $('#distance_'+field);

                if ($field.hasClass('tt-input')) {
                    $field.typeahead('val', suggestion[field]);
                } else {
                    $field.val(suggestion[field]);
                }
            }
        });
    }
};

App.Referentiel.Distancier.View = class {
    constructor(params) {
        App.Referentiel.Distancier._initMap(params.paths);
    }
};