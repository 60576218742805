'use strict';

import ladda from "ladda";
import Router from "@/App/Router";
import ImmatriculationSearch from "@/Vue/Components/ImmatriculationSearch.vue";

App.Referentiel.Vehicule = class {};

App.Referentiel.Vehicule.Appareil = class {};

App.Referentiel.Vehicule.Appareil.Index = class  {
    constructor(params) {
        App.Referentiel.Sync.checkSync('appareil', params.ids);
    }
};

App.Referentiel.Vehicule.View = class {
    constructor(params) {
        App.Referentiel.Sync.checkSync('vehicule', params.id ? [params.id] : []);
        App.Referentiel.Appareil.initMiniMap();
    }
};

App.Referentiel.Vehicule.Index = class  {
    constructor(params) {
        App.Referentiel.Sync.checkSync('vehicule', params.ids || []);
        this.params = params;
        this.initAttestation();
    }

    initAttestation() {
        $('#attestationBtn').popover({
            placement: 'top',
            fallbackPlacement: [],
            content: this.params.attestationPopoverTemplate,
            html: true,
            sanitize: false,
            title: this.params.attestationPopoverTitle
        }).on('show.bs.popover', function () {
            $($(this).data('bs.popover').tip).css('min-width', '20%');
        }).on('shown.bs.popover', () => {
            App.Layout.enableTreeSelect();

            let $printBtn = $('#attestationPrintBtn');
            let $downloadBtn = $('#attestationDownloadBtn');
            let $attestationForm = $('#attestationForm');
            let attestationRoute = this.params.attestationRoute;

            let printBtnLadda = ladda.create($printBtn[0]);
            let downloadBtnLadda = ladda.create($downloadBtn[0]);

            let printUrl = Router.generate(attestationRoute, {format: 'html'});

            $printBtn.click(function (e) {
                const filename = $(this).data('filename');

                let url = printUrl + '?' + decodeURI($attestationForm.serialize());
                if(e.ctrlKey) {
                    let win = window.open(url, '_blank');
                    if(win) {
                        win.focus();
                        return;
                    }
                }

                if(e.shiftKey) {
                    window.location.href = url;
                    return;
                }

                printBtnLadda.start();
                $downloadBtn.prop('disabled', true);
                $printBtn.prop('disabled', true);

                App.Utils.print(url, filename).then(() => {
                    printBtnLadda.stop();
                    $downloadBtn.prop('disabled', false);
                });
            })

            $attestationForm.submit(() => {
                downloadBtnLadda.start();
                $printBtn.prop('disabled', true);

                App.Utils.handleDownload().then(() => {
                    downloadBtnLadda.stop();
                    $printBtn.prop('disabled', false);
                });
            })
        })
    }
};

App.Referentiel.Vehicule.Form = class {
    constructor () {
        this.initImmatriculationApi();
    }

    initImmatriculationApi() {
        const $btn = $('.js-immatriculation-btn');
        const $input = $('#vehicule_immatriculation');

        const checkInput = () => {
            $btn.prop('disabled', !$input[0].inputmask.isComplete());
        };

        checkInput();
        $input.on('input', () => checkInput());

        $btn.click(() => {
            App.Utils.openVueSwal(ImmatriculationSearch, {
                immatriculation: $input.val(),
                structureId: App.Shared.getStructure(),
            });
        });
    }
}
