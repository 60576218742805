<template>
    <Base :hasMap="hasMap" :data="trajet._loading && trajet.longitudeDegrePriseEnCharge && trajet.latitudeDegrePriseEnCharge && trajet.latitudeDegreDestination && trajet.longitudeDegreDestination ? null : trajet">
        <Pin type="start" v-if="trajet.latitudeDegrePriseEnCharge && trajet.longitudeDegrePriseEnCharge" :position="{horodatage: trajet.topPriseEnChargeDepartReel, latitude: trajet.latitudeDegrePriseEnCharge, longitude: trajet.longitudeDegrePriseEnCharge}"></Pin>
        <Pin type="end" v-if="trajet.latitudeDegreDestination && trajet.longitudeDegreDestination" :position="{horodatage: trajet.topDestinationArriveeReel, latitude: trajet.latitudeDegreDestination, longitude: trajet.longitudeDegreDestination}"></Pin>
        <Path v-if="trajet.routesGeoJSON" id="trajet" mode="ant" :ant-speed="50" autoFit :fitOptions="{maxZoom: 18, animate: false, padding: 50}" color="#3b82f6" :features="trajet.routesGeoJSON"></Path>
        <Positions v-if="positionsGeoJson" id="positions" :autoFit="!trajet.routesGeoJSON" :fitOptions="{maxZoom: 18, animate: false, padding: 50}" :data="positionsGeoJson"></Positions>
    </Base>
</template>

<script setup>
import {computed, toRefs} from "vue";
import Base from "@/Vue/Components/Trajet/Map/Base.vue";
import {useRequest} from "@/Vue/Composables/Request";
import Positions from "@/Vue/Components/RegulationV2/Map/Base/Positions.vue";
import Pin from "@/Vue/Components/RegulationV2/Map/Layer/Segment/Pin.vue";
import Path from "@/Vue/Components/RegulationV2/Map/Base/Path.vue";

const {getJson} = useRequest();
const props = defineProps(['trajet']);

const {trajet} = toRefs(props);

const hasMap = computed(() => trajet.value.routesGeoJSON !== null || trajet.value._positionCertifieCollection.length > 0);

const positionsGeoJson = computed(() => ({
    type: 'FeatureCollection',
    features: trajet.value._positionCertifieCollection.map((position, index) => ({
        type: 'Feature',
        geometry: {
            type: 'Point',
            coordinates: [position.longitude, position.latitude],
        },
        properties: {
            index,
            // todo icone certifie
        },
    })),
}));
</script>
